import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahBlacklist = () => {
  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [tglBlacklist, setTglBlacklist] = useState(new Date());

  const [noRegister, setNoRegister] = useState("");
  const [namaBlacklist, setNamaBlacklist] = useState("");
  const [alamatBlacklist, setAlamatBlacklist] = useState("");
  const [tlpBlacklist, setTlpBlacklist] = useState("");
  const [noKKBlacklist, setNoKKBlacklist] = useState("");
  const [noKTPBlacklist, setNoKTPBlacklist] = useState("");
  const [nopolBlacklist, setNopolBlacklist] = useState("");

  const [catatanBlacklist, setCatatanBlacklist] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [marketings, setMarketings] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [registers, setRegisters] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTermRegister, setSearchTermRegister] = useState("");
  const [openRegister, setOpenRegister] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermRegister(query);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const classes = useStyles();

  const handleClickOpenRegister = () => {
    setOpenRegister(true);
  };

  const handleCloseRegister = () => {
    setOpenRegister(false);
  };

  const marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
  }));

  const surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  useEffect(() => {
    if (user.tipeUser === "OWN") {
      getMarketingAllCabang();
      getSurveyorAll();
    } else {
      getMarketing();
      getSurveyor();
    }
  }, []);

  useEffect(() => {
    getRegister();
  }, [page, searchTermRegister]);

  const getMarketing = async () => {
    setLoading(true);
    const allMarketings = await axios.post(`${tempUrl}/marketings`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setMarketings(allMarketings.data);
    setLoading(false);
  };

  const getMarketingAllCabang = async () => {
    setLoading(true);
    const allMarketings = await axios.post(`${tempUrl}/marketingsAllCabang`, {
      id: user._id,
      token: user.token,
    });
    setMarketings(allMarketings.data);
    setLoading(false);
  };

  const getSurveyor = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const getSurveyorAll = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyorsAllCabang`, {
      id: user._id,
      token: user.token,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const getRegister = async () => {
    setLoading(true);
    const allRegisters = await axios.post(
      `${tempUrl}/registersForBlacklistPagination?search_query=${searchTermRegister}&page=${page}&limit=${limit}`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setQuery(searchTermRegister);
    setRegisters(allRegisters.data.registers);
    setPage(allRegisters.data.page);
    setPages(allRegisters.data.totalPage);
    setRows(allRegisters.data.totalRows);
    setLoading(false);
  };

  const saveBlacklist = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation =
      noRegister.length === 0 ||
      tglBlacklist.length === 0 ||
      alamatBlacklist.length === 0 ||
      noKKBlacklist.length === 0 ||
      noKTPBlacklist.length === 0 ||
      nopolBlacklist.length === 0 ||
      tlpBlacklist.length === 0 ||
      kodeMarketing.length === 0 ||
      kodeSurveyor.length === 0 ||
      namaBlacklist.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        let tempTglBlacklist =
          tglBlacklist.getFullYear() +
          "-" +
          (tglBlacklist.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          tglBlacklist.getDate().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
        const tempMarketing = await axios.post(`${tempUrl}/marketingByKode`, {
          kodeMarketing,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
          kodeSurveyor,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        await axios.post(`${tempUrl}/saveBlacklist`, {
          kodeMarketing: tempMarketing.data._id,
          kodeSurveyor: tempSurveyor.data._id,
          tglBlacklist: tempTglBlacklist,
          noRegister,
          alamatBlacklist,
          noKKBlacklist,
          noKTPBlacklist,
          nopolBlacklist,
          namaBlacklist,
          tlpBlacklist,
          catatanBlacklist,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate("/daftarBlacklist");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Penjualan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah Blacklist
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveBlacklist}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tanggal Blacklist <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={tglBlacklist}
                        onChange={(e) => setTglBlacklist(e)}
                        customInput={
                          <TextField
                            error={error && tglBlacklist === null && true}
                            helperText={
                              error &&
                              tglBlacklist === null &&
                              "Tanggal Blacklist harus diisi!"
                            }
                            sx={{ width: "100%" }}
                            size="small"
                          />
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Register <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noRegister}
                        required
                        placeholder="Pilih..."
                        onClick={() => handleClickOpenRegister()}
                      />
                      <Form.Control.Feedback type="invalid">
                        Kode Register harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaBlacklist}
                        required
                        onChange={(e) =>
                          setNamaBlacklist(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Nama harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={alamatBlacklist}
                        required
                        onChange={(e) =>
                          setAlamatBlacklist(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Alamat harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KK <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noKKBlacklist}
                        required
                        onChange={(e) =>
                          setNoKKBlacklist(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        No. KK harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KTP <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noKTPBlacklist}
                        required
                        onChange={(e) =>
                          setNoKTPBlacklist(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        No. KTP harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nopol <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={nopolBlacklist}
                        required
                        onChange={(e) =>
                          setNopolBlacklist(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Nopol harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={tlpBlacklist}
                        type="number"
                        required
                        onChange={(e) =>
                          setTlpBlacklist(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Telepon harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Marketing <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={marketingOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeMarketing.length === 0 && true}
                            helperText={
                              error &&
                              kodeMarketing.length === 0 &&
                              "Kode Marketing harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setKodeMarketing(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Surveyor <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={surveyorOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeSurveyor.length === 0 && true}
                            helperText={
                              error &&
                              kodeSurveyor.length === 0 &&
                              "Kode Surveyor harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setKodeSurveyor(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Catatan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={catatanBlacklist}
                        onChange={(e) =>
                          setCatatanBlacklist(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/daftarBlacklist")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openRegister}
        onClose={handleCloseRegister}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Register`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Register
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tanggal
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Customer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {registers.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNoRegister(user.noRegister);
                        setNamaBlacklist(user.namaRegister);
                        setAlamatBlacklist(user.almRegister);
                        setTlpBlacklist(user.tlpRegister);
                        setNoKTPBlacklist(user.noKtpRegister);
                        setNoKKBlacklist(user.noKKRegister);

                        handleCloseRegister();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noRegister}
                      </TableCell>
                      <TableCell>{user.tanggalRegister}</TableCell>
                      <TableCell>{user.namaRegister}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TambahBlacklist;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
