import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { formatDate, formatTime } from "../../../constants/helper";
import { Box, Typography, Button, Divider, TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const SpkHarusDibuat = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  let date = new Date();
  let [inputSampaiTgl, setInputSampaiTgl] = useState(date);
  let [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const { screenSize } = useStateContext();

  const columns = [
    { title: "No", field: "no" },
    { title: "No. KONTRAK", field: "noJual" },
    { title: "Customer", field: "namaRegister" },
    { title: "Surveyor", field: "surveyor" },
    { title: "J.Tempo", field: "tglJatuhTempoFormatted" },
  ];

  const downloadPdf = async () => {
    setLoading(true);
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);

    let tempPersenLoading = parseInt(persenLoading);
    let jualsMd1BelumST = await axios.post(`${tempUrl}/jualsMd1BelumST`, {
      sampaiTgl: inputSampaiTgl,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    tempPersenLoading += 50;
    setPersenLoading(tempPersenLoading.toString());

    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(10);
    doc.text(
      `Lap. SPK yang harus dibuat untuk tanggal: ${formatDate(inputSampaiTgl)}`,
      15,
      30
    );
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 33,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: jualsMd1BelumST.data,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });

    window.open(URL.createObjectURL(doc.output("blob")));
    setLoading(false);
    setPersenLoading("0");
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan SPK yang harus dibuat
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Col sm={6}>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextPassword"
          >
            <Form.Label column sm="3" style={textRight}>
              Per Tanggal :
            </Form.Label>
            <Col sm="9">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={inputSampaiTgl}
                onChange={(e) => setInputSampaiTgl(e)}
                customInput={
                  <TextField
                    error={error && inputSampaiTgl === null && true}
                    helperText={
                      error &&
                      inputSampaiTgl === null &&
                      "Sampai Tanggal harus diisi!"
                    }
                    sx={{ width: "100%" }}
                    size="small"
                  />
                }
              />
            </Col>
          </Form.Group>
        </Col>
      </Box>
      <Box sx={spacingTop}>
        <Button
          variant="contained"
          startIcon={<PrintIcon />}
          onClick={() => {
            if (inputSampaiTgl === null) {
              setError(true);
            } else downloadPdf();
          }}
        >
          CETAK
        </Button>
      </Box>
    </Box>
  );
};

export default SpkHarusDibuat;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "60vw"
  // }
};
