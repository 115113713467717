import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { ShowTableSuratPenarikan } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../components";
import { Colors } from "../../../constants/styles";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Chip,
  ButtonGroup,
  Button,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PrintIcon from "@mui/icons-material/Print";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import { Col, Row, Form } from "react-bootstrap";

const TampilSuratPenarikan = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [isStsExist, setIsStsExist] = useState(false);
  const [noSt, setNoSt] = useState("");
  const [tglSt, setTglSt] = useState("");
  const [tglStDate, setTglStDate] = useState("");
  const [noJual, setNoJual] = useState("");
  const [tanggalJual, setTanggalJual] = useState("");
  const [namaKolektor, setNamaKolektor] = useState("");
  const [kodeKolektor, setKodeKolektor] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");
  const [jmlBlnTelat, setJmlBlnTelat] = useState("");
  const [totalDenda, setTotalDenda] = useState("");
  const [biayaTarik, setBiayaTarik] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [tahun, setTahun] = useState("");
  const [isi, setIsi] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");

  // Delete it
  const [almRegister, setAlmRegister] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [nopol, setNopol] = useState("");
  const [merk, setMerk] = useState("");
  const [kodeTipe, setKodeTipe] = useState("");
  const [tglJatuhTempo, setTglJatuhTempo] = useState("");

  const [value, setValue] = useState("Semua");
  const [searchTerm, setSearchTerm] = useState("");
  const [stsData, setStsData] = useState([]);
  const [kodeKolektorInput, setKodeKolektorInput] = useState("");
  const [kolektors, setKolektors] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const handleChangeFilter = (event) => {
    setValue(event.target.value);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  let findMonth = (monthNumber) => {
    if (monthNumber === 1) {
      return "JANUARI";
    } else if (monthNumber === 2) {
      return "FEBRUARI";
    } else if (monthNumber === 3) {
      return "MARET";
    } else if (monthNumber === 4) {
      return "APRIL";
    } else if (monthNumber === 5) {
      return "MEI";
    } else if (monthNumber === 6) {
      return "JUNI";
    } else if (monthNumber === 7) {
      return "JULI";
    } else if (monthNumber === 8) {
      return "AGUSTUS";
    } else if (monthNumber === 9) {
      return "SEPTEMBER";
    } else if (monthNumber === 10) {
      return "OKTOBER";
    } else if (monthNumber === 11) {
      return "NOVEMBER";
    } else if (monthNumber === 12) {
      return "DESEMBER";
    }
  };

  const kolektorOptions = kolektors.map((kolektor) => ({
    label: `${kolektor.kodeKolektor} - ${kolektor.namaKolektor}`,
  }));

  useEffect(() => {
    id ? getStById() : setIsStsExist(false);
  }, [id]);

  useEffect(() => {
    getStsData();
    getKolektor();
  }, [value, page, searchTerm]);

  const getStsData = async () => {
    setLoading(true);
    try {
      // Filter Kolektor/Eksekutor
      const pickedKolektor = await axios.post(`${tempUrl}/kolektorByKode`, {
        kodeKolektorInput,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      let tempKodeKolektor;
      if (pickedKolektor.data) {
        tempKodeKolektor = pickedKolektor.data._id;
      }

      if (user.tipeUser === "EKS") {
        tempKodeKolektor = user.kodeKolektor;
      }

      let allSts;
      switch (value) {
        case "Sudah Bayar":
          allSts = await axios.post(
            `${tempUrl}/stsPaginationSudahBayar?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              kodeKolektor: tempKodeKolektor,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "Belum Bayar":
          allSts = await axios.post(
            `${tempUrl}/stsPaginationBelumBayar?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              kodeKolektor: tempKodeKolektor,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "PP":
          allSts = await axios.post(
            `${tempUrl}/stsPaginationSudahDitarik?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              kodeKolektor: tempKodeKolektor,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "Tarik/Titip":
          allSts = await axios.post(
            `${tempUrl}/stsPaginationTitip?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              kodeKolektor: tempKodeKolektor,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        default:
          allSts = await axios.post(
            `${tempUrl}/stsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              kodeKolektor: tempKodeKolektor,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
      }

      setQuery(searchTerm);
      setStsData(allSts.data.suratPenarikans);
      setPage(allSts.data.page);
      setPages(allSts.data.totalPage);
      setRows(allSts.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getKolektor = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/kolektors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKolektors(response.data);
    setLoading(false);
  };

  const getStById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/sts/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsStsExist(true);
      setNoSt(response.data.noSt);
      setTglSt(formatDate(response.data.tglSt));
      setTglStDate(response.data.tglSt);
      setNoJual(response.data.noJual);
      setNamaKolektor(response.data.kodeKolektor.namaKolektor);
      setKodeKolektor(
        `${response.data.kodeKolektor.kodeKolektor} - ${response.data.kodeKolektor.namaKolektor}`
      );
      setAngPerBulan(response.data.angPerBulan);
      setJmlBlnTelat(response.data.jmlBlnTelat);
      setTotalDenda(response.data.totalDenda);
      setBiayaTarik(response.data.biayaTarik);
      setTanggalJual(response.data.idJual.tanggalJual);
      setNamaRegister(response.data.idJual.namaRegister);
      setTahun(response.data.idJual.tahun);
      setNamaWarna(response.data.idJual.namaWarna);
      setNoRangka(response.data.idJual.noRangka);
      setNoMesin(response.data.idJual.noMesin);

      setAlmRegister(response.data.idJual.almRegister);
      setTlpRegister(response.data.idJual.tlpRegister);
      setNopol(response.data.idJual.nopol);

      var dt = new Date(response.data.idJual.tglJatuhTempo);
      let day = dt.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      let month = (dt.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      let sum = day + "-" + month + "-" + dt.getFullYear();

      setTglJatuhTempo(sum);

      const pickedTipe = await axios.post(`${tempUrl}/tipesByKode`, {
        kodeTipe: response.data.idJual.tipe,
        id: user._id,
        token: user.token,
      });

      setMerk(pickedTipe.data.merk);
      setIsi(pickedTipe.data.isi);
      setKodeTipe(response.data.idJual.tipe);
    }
  };

  const filterKolektor = async (value) => {
    let kodeKolektor = value.split(" ", 1)[0];
    setKodeKolektorInput(kodeKolektor);

    if (value.length === 0) {
      getStsData();
      getKolektor();
      setKodeKolektorInput("");
      return;
    }

    const pickedKolektor = await axios.post(`${tempUrl}/kolektorByKode`, {
      kodeKolektor,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    let tempKodeKolektor;
    if (pickedKolektor.data) {
      tempKodeKolektor = pickedKolektor.data._id;
    }

    if (pickedKolektor.data) {
      let allSts;
      switch (value) {
        case "Sudah Bayar":
          allSts = await axios.post(
            `${tempUrl}/stsPaginationSudahBayar?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              kodeKolektor: tempKodeKolektor,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "Belum Bayar":
          allSts = await axios.post(
            `${tempUrl}/stsPaginationBelumBayar?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              kodeKolektor: tempKodeKolektor,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "PP":
          allSts = await axios.post(
            `${tempUrl}/stsPaginationSudahDitarik?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              kodeKolektor: tempKodeKolektor,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "Tarik/Titip":
          allSts = await axios.post(
            `${tempUrl}/stsPaginationTitip?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              kodeKolektor: tempKodeKolektor,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        default:
          allSts = await axios.post(
            `${tempUrl}/stsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              kodeKolektor: tempKodeKolektor,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
      }

      setKodeKolektorInput(kodeKolektor);
      setQuery(searchTerm);
      setStsData(allSts.data.suratPenarikans);
      setPage(allSts.data.page);
      setPages(allSts.data.totalPage);
      setRows(allSts.data.totalRows);
    }
  };

  const deleteSt = async (id) => {
    try {
      setLoading(true);
      var date = new Date();
      var current_date =
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
      var current_time =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      // Find Jual
      const findJualByNoJual = await axios.post(`${tempUrl}/jualByNoJual`, {
        noJual,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      // Find Angsuran
      const angsuran = await axios.post(`${tempUrl}/angsuransByNoJual`, {
        noJual,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      // Find Angsuran Terakhir
      const findAngsuranTerakhir = await axios.post(
        `${tempUrl}/angsuransChild`,
        {
          noJual,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );

      // Make Update Jual Content
      let tempUpdateJual = {
        tglStTerakhir: "",
        kodeKolektor: "",
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      };

      // Make Update Angsuran Content
      let tempUpdateAngsuran = {
        angsuranKe: findAngsuranTerakhir.data._id - 1,
        biayaTarik: 0,
        suratPenarikan: null,

        _id: findAngsuranTerakhir.data._id,
        tglJatuhTempo: findAngsuranTerakhir.data.tglJatuhTempo,
        angModal: findAngsuranTerakhir.data.angModal,
        angBunga: findAngsuranTerakhir.data.angBunga,
        angPerBulan: findAngsuranTerakhir.data.angPerBulan,
        tglBayar: findAngsuranTerakhir.data.tglBayar,
        noKwitansi: findAngsuranTerakhir.data.noKwitansi,
        keterangan: findAngsuranTerakhir.data.keterangan,
        denda: findAngsuranTerakhir.data.denda,
        potongan: findAngsuranTerakhir.data.potongan,
        jemputan: 0,
        hutangDenda: findAngsuranTerakhir.data.hutangDenda,
        totalPiutang: findAngsuranTerakhir.data.totalPiutang,
        totalBayar: findAngsuranTerakhir.data.totalBayar,
        bayar: findAngsuranTerakhir.data.bayar,

        tglUpdate: current_date,
        jamUpdate: current_time,
        userUpdate: user.username,
        id: user._id,
        token: user.token,
      };
      // Delete St Transaction
      await axios.post(`${tempUrl}/deleteStTransaction`, {
        stData: {
          idSt: id,
          id: user._id,
          token: user.token,
        },
        findStData: {
          noJual,
          noAngsuran: findAngsuranTerakhir.data._id,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        },
        jualData: {
          idJual: findJualByNoJual.data._id,
          ...tempUpdateJual,
        },
        angsuranData: {
          idAngsuran: angsuran.data._id,
          ...tempUpdateAngsuran,
        },
        id: user._id,
        token: user.token,
      });
      setNoSt("");
      setTglSt("");
      setNoJual("");
      setKodeKolektor("");
      setAngPerBulan("");
      setJmlBlnTelat("");
      setTotalDenda("");
      setBiayaTarik("");
      setLoading(false);
      navigate("/suratPenarikan");
      getStsData();
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPdf = async () => {
    let tempY = 14; //-8-7-4
    let tempTglSt = new Date(tglStDate);
    let tempNameMonthTglSt = findMonth(tempTglSt.getMonth() + 1);
    let tempTanggalJual = new Date(tanggalJual);
    let tempNameMonthTanggalJual = findMonth(tempTanggalJual.getMonth() + 1);

    const doc = new jsPDF();
    doc.setFontSize(9);
    doc.text(`${namaKolektor}`, 154, tempY);
    doc.setFontSize(10);
    tempY += 29;
    doc.text(`${noSt}`, 38, tempY);
    doc.text(
      `${tempTglSt.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })} ${tempNameMonthTglSt} ${tempTglSt.getFullYear()}`,
      186,
      tempY,
      {
        align: "right",
      }
    );
    tempY += 48;
    doc.text(`${merk}`, 70, tempY); //+8+4+2
    doc.setFontSize(8);
    doc.text(`${kodeTipe}`, 93, tempY); //+6+2
    doc.setFontSize(10);
    doc.text(`${namaRegister}`, 162, tempY);
    tempY += 6;
    doc.text(`${tahun}`, 70, tempY);
    doc.text(`${isi ? isi : "0"}`, 93, tempY);
    doc.text(`${jmlBlnTelat} (BULAN)`, 162, tempY);
    tempY += 6;
    doc.text(`${namaWarna}`, 70, tempY);
    doc.text(`${angPerBulan.toLocaleString("en-US")}`, 186, tempY, {
      align: "right",
    });
    tempY += 7;
    doc.text(`${noRangka}`, 70, tempY);
    doc.text(`${totalDenda.toLocaleString("en-US")}`, 186, tempY, {
      align: "right",
    });
    tempY += 7;
    doc.text(`${noMesin}`, 70, tempY);
    doc.text(
      `${(angPerBulan + totalDenda).toLocaleString("en-US")}`,
      186,
      tempY,
      {
        align: "right",
      }
    );
    tempY += 7;
    doc.text(`${nopol}`, 70, tempY);
    tempY += 9;
    doc.text(
      `${(angPerBulan + totalDenda + biayaTarik).toLocaleString("en-US")}`,
      186,
      tempY,
      {
        align: "right",
      }
    );
    tempY += 8;
    doc.text(`${noJual}`, 78, tempY);
    doc.text(
      `${tempTanggalJual.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })} ${tempNameMonthTanggalJual} ${tempTanggalJual.getFullYear()}`,
      198,
      tempY,
      {
        align: "right",
      }
    );
    doc.setFontSize(12);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Surat Penarikan
      </Typography>
      {isStsExist && (
        <Box sx={downloadButtons}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={() => downloadPdf()}>
              CETAK
            </Button>
          </ButtonGroup>
        </Box>
      )}
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={isStsExist}
          addLink={`/suratPenarikan/tambahSuratPenarikan`}
          editLink={`/suratPenarikan/${id}/edit`}
          deleteUser={deleteSt}
          nameUser={noJual}
        />
      </Box>
      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Filter</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="Semua"
          value={value}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            value="Sudah Bayar"
            control={<Radio />}
            label="Sudah Bayar"
          />
          <FormControlLabel
            value="Belum Bayar"
            control={<Radio />}
            label="Belum Bayar"
          />
          <FormControlLabel value="PP" control={<Radio />} label="PP" />
          <FormControlLabel
            value="Tarik/Titip"
            control={<Radio />}
            label="Tarik/Titip"
          />
          <FormControlLabel value="Semua" control={<Radio />} label="Semua" />
        </RadioGroup>
      </FormControl>
      {user.tipeUser !== "EKS" && (
        <>
          <hr />
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kode Eksekutor :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kolektorOptions}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        placeholder="SEMUA EKSEKUTOR"
                        {...params}
                      />
                    )}
                    onInputChange={(e, value) => filterKolektor(value)}
                    inputValue={kodeKolektorInput}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      <hr />
      {isStsExist && (
        <>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Surat Penarikan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noSt} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Surat Penarikan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglSt} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Eksekutor :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeKolektor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Total :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={(biayaTarik + totalDenda).toLocaleString(
                          "en-US"
                        )}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Jml Bulan Telat :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={`${jmlBlnTelat} bulan`} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Angsuran / Bulan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={angPerBulan.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Denda Tunggakan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={totalDenda.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Biaya Tarik :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={biayaTarik.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={{ mt: 2 }}>
        <Chip
          label="Belum Bayar"
          sx={{
            backgroundColor: Colors.red400,
          }}
        />
        <Chip
          label="PP"
          sx={{
            backgroundColor: Colors.yellow400,
          }}
        />
        <Chip
          label="Tarik/Titip"
          sx={{
            backgroundColor: Colors.blue300,
          }}
        />
      </Box>
      <Box sx={tableContainer}>
        <ShowTableSuratPenarikan currentPosts={stsData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilSuratPenarikan;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
