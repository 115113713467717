import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Loader } from "../../../components";
import { Box, Typography, Divider, Button, Paper } from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import SaveIcon from "@mui/icons-material/Save";

const UbahStnkBpkb = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "13px",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [validated, setValidated] = useState(false);
  // Data Register/Pembeli
  const [noRegister, setNoRegister] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [almKantor, setAlmKantor] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [noKtpRegister, setNoKtpRegister] = useState("");
  const [noKKRegister, setNoKKRegister] = useState("");
  const [namaPjmRegister, setNamaPjmRegister] = useState("");
  const [noKtpPjmRegister, setNoKtpPjmRegister] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [almRefRegister, setAlmRefRegister] = useState("");
  const [tlpRefRegister, setTlpRefRegister] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeSurveyorAsli, setKodeSurveyorAsli] = useState("");
  const [kodePekerjaan, setKodePekerjaan] = useState("");
  const [ketPekerjaan, setKetPekerjaan] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodeLeasing, setKodeLeasing] = useState("");

  // Data Motor -> Dari Stok
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [nopol, setNopol] = useState("");
  const [tipe, setTipe] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaStnk, setNamaStnk] = useState("");
  const [noBpkb, setNoBpkb] = useState("");

  // Data Penjualan -> dari input
  const [hargaTunai, setHargaTunai] = useState("");
  const [uangMuka, setUangMuka] = useState("");
  const [sisaPiutang, setSisaPiutang] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");
  const [tenor, setTenor] = useState("");
  const [sisaBulan, setSisaBulan] = useState("");
  const [bunga, setBunga] = useState("");
  const [jumlahPiutang, setJumlahPiutang] = useState("");
  const [angModal, setAngModal] = useState("");
  const [angBunga, setAngBunga] = useState("");
  const [noJual, setNoJual] = useState("");
  const [noKwitansi, setNoKwitansi] = useState("");
  const [tglJual, setTglJual] = useState("");
  const [tglJualDate, setTglJualDate] = useState();
  const [jenisJual, setJenisJual] = useState("");
  const [leasing, setLeasing] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [tglAngFormatted, setTglAngFormatted] = useState("");
  const [tglAngAkhir, setTglAngAkhir] = useState("");
  const [tglAngAkhirFormatted, setTglAngAkhirFormatted] = useState("");

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    id && getJualById();
  }, [id]);

  const getJualById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/juals/${id}`, {
        id: user._id,
        token: user.token,
      });
      // Data Register/Pembeli
      setNoRegister(response.data.noRegister);
      setNamaRegister(response.data.namaRegister);
      setAlmRegister(response.data.almRegister);
      setAlmKantor(response.data.almKantor);
      setTlpRegister(response.data.tlpRegister);
      setNoKtpRegister(response.data.noKtpRegister);
      setNoKKRegister(response.data.noKKRegister);
      setNamaPjmRegister(response.data.namaPjmRegister);
      setNoKtpPjmRegister(response.data.noKtpPjmRegister);
      setNamaRefRegister(response.data.namaRefRegister);
      setAlmRefRegister(response.data.almRefRegister);
      setTlpRefRegister(response.data.tlpRefRegister);
      setKodeMarketing(response.data.kodeMarketing);
      setKodeSurveyor(response.data.kodeSurveyor);
      setKodeSurveyorAsli(response.data.kodeSurveyorAsli);
      setKodePekerjaan(response.data.kodePekerjaan);
      setKetPekerjaan(response.data.ketPekerjaan);
      setKodeKecamatan(response.data.kodeKecamatan);
      setKodeLeasing(response.data.kodeLeasing);
      setKodeCOA(response.data.COA);

      // Data Motor -> Dari Stok
      setNoRangka(response.data.noRangka);
      setNoMesin(response.data.noMesin);
      setNopol(response.data.nopol);
      setTipe(response.data.tipe);
      setNamaWarna(response.data.namaWarna);
      setTahun(response.data.tahun);
      setNamaStnk(response.data.namaStnk);
      setNoBpkb(response.data.noBpkb);

      // Data Penjualan -> dari input
      setHargaTunai(response.data.hargaTunai);
      setUangMuka(response.data.uangMuka);
      setSisaPiutang(response.data.sisaPiutang);
      setAngPerBulan(response.data.angPerBulan);
      setTenor(response.data.tenor);
      setSisaBulan(response.data.sisaBulan);
      setBunga(response.data.bunga);
      setJumlahPiutang(response.data.jumlahPiutang);
      setAngModal(response.data.angModal);
      setAngBunga(response.data.angBunga);
      setNoJual(response.data.noJual);
      setNoKwitansi(response.data.noKwitansi);
      setTglJual(formatDate(response.data.tanggalJual));
      setTglJualDate(new Date(response.data.tanggalJual));
      setJenisJual(response.data.jenisJual);
      setLeasing(response.data.leasing);
      setTglAng(response.data.tglAng);
      setTglAngFormatted(formatDate(response.data.tglAng));
      setTglAngAkhir(response.data.tglAngAkhir);
      setTglAngAkhirFormatted(formatDate(response.data.tglAngAkhir));
    }
    setLoading(false);
  };

  const updateJual = async (e) => {
    e.preventDefault();
    setLoading(true);
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    try {
      await axios.post(`${tempUrl}/updateJual/${id}`, {
        namaStnk,
        noBpkb,
        tglUpdate: current_date,
        jamUpdate: current_time,
        userUpdate: user.username,
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setLoading(false);
      navigate(`/jual/${id}`);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      console.log(error);
    }

    setValidated(true);
    setLoading(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <Typography color="#757575">Penjualan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Ubah Jual Stnk & Bpkb
      </Typography>
      <Divider sx={dividerStyle} />

      <Form noValidate validated={validated} onSubmit={updateJual}>
        <Paper sx={contentContainer} elevation={12}>
          <Box sx={contentContainer}>
            {/* Data Penjualan */}
            <Paper elevation={6} sx={mainContainer}>
              <Typography variant="h5" sx={titleStyle} color="primary">
                DATA PENJUALAN
              </Typography>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. Jual :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noJual} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. Kwitansi :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noKwitansi} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Tanggal Jual :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tglJual} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Jenis Jual :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={jenisJual} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
                <Box sx={[showDataWrapper, secondWrapper]}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          COA :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodeCOA.kodeCOA} - ${kodeCOA.namaCOA}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
              </Box>
            </Paper>

            {/* Data Customer */}
            <Paper elevation={6} sx={mainContainer}>
              <Typography variant="h5" sx={titleStyle} color="primary">
                DATA CUSTOMER
              </Typography>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Nama Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={namaRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Alamat Rumah :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={almRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Alamat Kantor :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={almKantor} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Telepon Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tlpRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. KTP Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noKtpRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. KK Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noKKRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Nama Penjamin Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={namaPjmRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          No. KTP Penjamin Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noKtpPjmRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
                <Box sx={[showDataWrapper, secondWrapper]}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Nama Ref. Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={namaRefRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Alamat Ref. Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={almRefRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Telepon Ref. Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tlpRefRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Marketing :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodeMarketing.kodeMarketing} - ${kodeMarketing.namaMarketing}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Surveyor :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodeSurveyor.kodeSurveyor} - ${kodeSurveyor.namaSurveyor}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Surveyor Asli :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodeSurveyorAsli.kodeSurveyor} - ${kodeSurveyorAsli.namaSurveyor}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Pekerjaan :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodePekerjaan.kodePekerjaan} - ${kodePekerjaan.namaPekerjaan}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label
                          column
                          sm="4"
                          style={textRight}
                        ></Form.Label>
                        <Col sm="8">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={ketPekerjaan}
                            disabled
                            readOnly
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Kecamatan :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodeKecamatan.kodeKecamatan} - ${kodeKecamatan.namaKecamatan}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Leasing :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodeLeasing.kodeLeasing} - ${kodeLeasing.namaLeasing}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
              </Box>
            </Paper>

            {/* Data Motor */}
            <Paper elevation={6} sx={mainContainer}>
              <Typography variant="h5" sx={titleStyle} color="primary">
                DATA MOTOR
              </Typography>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Nopol :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={nopol} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. Rangka :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noRangka} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. Mesin :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noMesin} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Nama Stnk :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={namaStnk}
                            onChange={(e) =>
                              setNamaStnk(e.target.value.toUpperCase())
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
                <Box sx={[showDataWrapper, secondWrapper]}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Tipe :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tipe} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Nama Warna :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={namaWarna} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Tahun Perakitan :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tahun} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. Bpkb :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={noBpkb}
                            onChange={(e) =>
                              setNoBpkb(e.target.value.toUpperCase())
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
              </Box>
            </Paper>

            {/* Data Rincian Harga (Input) */}
            <Paper elevation={6} sx={mainContainer}>
              <Typography variant="h5" sx={titleStyle} color="primary">
                RINCIAN HARGA
              </Typography>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Tanggal Angsuran I :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tglAngFormatted} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Harga Tunai :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            //type="number"
                            value={hargaTunai.toLocaleString("en-US")}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Uang Muka :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            //type="number"
                            value={uangMuka.toLocaleString("en-US")}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Sisa Piutang :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={sisaPiutang.toLocaleString("en-US")}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
                <Box sx={[showDataWrapper, secondWrapper]}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Tanggal Angsuran Akhir :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tglAngAkhirFormatted} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Angsuran/Bulan :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            //type="number"
                            value={angPerBulan.toLocaleString("en-US")}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Tenor :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="number" value={tenor} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Total Piutang :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={jumlahPiutang.toLocaleString("en-US")}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/jual/${id}`)}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Paper>
      </Form>
    </Box>
  );
};

export default UbahStnkBpkb;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw",
  // },
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
};
