import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Loader, ButtonModifier } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardActionArea,
  CardMedia,
  InputAdornment,
  Link,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PanoramaIcon from "@mui/icons-material/Panorama";
import Carousel from "react-elastic-carousel";

const TampilSurvey = () => {
  const { user, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "13px",
  };
  const [tglSurvey, setTglSurvey] = useState("");
  const [noRegister, setNoRegister] = useState("");

  const [namaSurvey, setNamaSurvey] = useState("");
  const [almSurvey, setAlmSurvey] = useState("");
  const [linkMapsSurvey, setLinkMapsSurvey] = useState("");
  const [linkMapsSurvey2, setLinkMapsSurvey2] = useState("");
  const [tlpSurvey, setTlpSurvey] = useState("");
  const [noKtpSurvey, setNoKtpSurvey] = useState("");
  const [almKtpSurvey, setAlmKtpSurvey] = useState("");
  const [noKKSurvey, setNoKKSurvey] = useState("");
  const [namaIbuKandungSurvey, setNamaIbuKandungSurvey] = useState("");
  const [namaPjmSurvey, setNamaPjmSurvey] = useState("");
  const [almPjmSurvey, setAlmPjmSurvey] = useState("");
  const [tlpPjmSurvey, setTlpPjmSurvey] = useState("");
  const [hubunganSurvey, setHubunganSurvey] = useState("");
  const [noKtpPjmSurvey, setNoKtpPjmSurvey] = useState("");
  const [namaRefSurvey, setNamaRefSurvey] = useState("");
  const [almRefSurvey, setAlmRefSurvey] = useState("");
  const [tlpRefSurvey, setTlpRefSurvey] = useState("");
  const [namaRef2Survey, setNamaRef2Survey] = useState("");
  const [almRef2Survey, setAlmRef2Survey] = useState("");
  const [tlpRef2Survey, setTlpRef2Survey] = useState("");
  const [namaRef3Survey, setNamaRef3Survey] = useState("");
  const [almRef3Survey, setAlmRef3Survey] = useState("");
  const [tlpRef3Survey, setTlpRef3Survey] = useState("");
  const [kecamatanId, setKecamatanId] = useState("");
  const [pekerjaanId, setPekerjaanId] = useState("");
  const [pekerjaanPenjaminId, setPekerjaanPenjaminId] = useState("");

  const [nopolSurvey, setNopolSurvey] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [statusSurvey, setStatusSurvey] = useState("");
  const [gambar, setGambar] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 5 },
  ];

  useEffect(() => {
    id && getSurveyById();
  }, [id]);

  const getSurveyById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/surveys/${id}`, {
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setTglSurvey(formatDate(response.data.tglSurvey));
      setNoRegister(response.data.noRegister);
      setNamaSurvey(response.data.namaSurvey);
      setAlmSurvey(response.data.almSurvey);
      setLinkMapsSurvey(response.data.linkMapsSurvey);
      setLinkMapsSurvey2(response.data.linkMapsSurvey2);
      setTlpSurvey(response.data.tlpSurvey);
      setNoKtpSurvey(response.data.noKtpSurvey);
      setAlmKtpSurvey(response.data.almKtpSurvey);
      setNoKKSurvey(response.data.noKKSurvey);
      setNamaIbuKandungSurvey(response.data.namaIbuKandungSurvey);
      setNamaPjmSurvey(response.data.namaPjmSurvey);
      setAlmPjmSurvey(response.data.almPjmSurvey);
      setTlpPjmSurvey(response.data.tlpPjmSurvey);
      setHubunganSurvey(response.data.hubunganSurvey);
      setNoKtpPjmSurvey(response.data.noKtpPjmSurvey);
      setNamaRefSurvey(response.data.namaRefSurvey);
      setAlmRefSurvey(response.data.almRefSurvey);
      setTlpRefSurvey(response.data.tlpRefSurvey);
      setNamaRef2Survey(response.data.namaRef2Survey);
      setAlmRef2Survey(response.data.almRef2Survey);
      setTlpRef2Survey(response.data.tlpRef2Survey);
      setNamaRef3Survey(response.data.namaRef3Survey);
      setAlmRef3Survey(response.data.almRef3Survey);
      setTlpRef3Survey(response.data.tlpRef3Survey);
      setPekerjaanId(
        `${response.data.pekerjaanId.kodePekerjaan} - ${response.data.pekerjaanId.namaPekerjaan}`
      );
      setPekerjaanPenjaminId(
        `${response.data.pekerjaanPenjaminId.kodePekerjaan} - ${response.data.pekerjaanPenjaminId.namaPekerjaan}`
      );
      setKecamatanId(
        `${response.data.kecamatanId.kodeKecamatan} - ${response.data.kecamatanId.namaKecamatan}`
      );

      setNopolSurvey(response.data.nopolSurvey);
      setKodeSurveyor(
        `${response.data.kodeSurveyor.kodeSurveyor} - ${response.data.kodeSurveyor.namaSurveyor}`
      );
      setStatusSurvey(response.data.statusSurvey);
      setGambar(response.data.gambar);
    }
  };

  const deleteSurvey = async () => {
    try {
      setLoading(true);
      await axios.post(`${tempUrl}/deleteSurvey/${id}`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setLoading(false);
      navigate("/daftarSurvey");
    } catch (error) {
      console.log(error);
    }
  };

  const batalSurvey = async () => {
    try {
      setLoading(true);
      await axios.post(`${tempUrl}/batalSurvey/${id}`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setLoading(false);
      getSurveyById();
    } catch (error) {
      console.log(error);
    }
  };

  const accSurvey = async () => {
    try {
      setLoading(true);
      await axios.post(`${tempUrl}/accSurvey/${id}`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setLoading(false);
      getSurveyById();
    } catch (error) {
      console.log(error);
    }
  };

  const rejectSurvey = async () => {
    try {
      setLoading(true);
      await axios.post(`${tempUrl}/rejectSurvey/${id}`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setLoading(false);
      getSurveyById();
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarSurvey")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box>
        <Typography color="#757575">Penjualan</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Survey
        </Typography>
        {user.tipeUser !== "SUR" && (
          <Box sx={buttonModifierContainer}>
            <ButtonGroup variant="contained" sx={buttonModifierContainer}>
              <Button
                color="warning"
                startIcon={screenSize >= 650 && <PanoramaIcon />}
                sx={{ textTransform: "none" }}
                onClick={() => {
                  navigate(`/daftarSurvey/survey/${id}/editFoto`);
                }}
              >
                {screenSize >= 650 ? "Ubah Foto" : <PanoramaIcon />}
              </Button>
              <Button
                color="primary"
                startIcon={screenSize >= 650 && <EditIcon />}
                sx={{ textTransform: "none" }}
                onClick={() => {
                  navigate(`/daftarSurvey/survey/${id}/edit`);
                }}
              >
                {screenSize >= 650 ? "Ubah" : <EditIcon />}
              </Button>
              <Button
                color="error"
                startIcon={screenSize >= 650 && <DeleteOutlineIcon />}
                sx={{ textTransform: "none" }}
                onClick={deleteSurvey}
              >
                {screenSize >= 650 ? "Hapus" : <DeleteOutlineIcon />}
              </Button>
            </ButtonGroup>
          </Box>
        )}
        <Box sx={buttonModifierContainer}>
          <ButtonGroup variant="contained">
            <Button
              color="primary"
              sx={{ bgcolor: "primary.light", textTransform: "none" }}
              onClick={batalSurvey}
            >
              Batal
            </Button>
            <Button
              color="success"
              sx={{ bgcolor: "success.light", textTransform: "none" }}
              onClick={accSurvey}
            >
              Acc
            </Button>
            <Button
              color="error"
              sx={{ bgcolor: "error.light", textTransform: "none" }}
              onClick={rejectSurvey}
            >
              Reject
            </Button>
          </ButtonGroup>
        </Box>
        {gambar.length !== 0 && (
          <Carousel breakPoints={breakPoints} sx={carouselStyle}>
            {gambar.length !== 0 &&
              gambar.map((img, i) => (
                <Card sx={carouselCard} elevation={10}>
                  <CardActionArea>
                    <CardHeader title={`Gambar`} subheader={`No. ${i + 1}`} />
                    <CardMedia
                      component="img"
                      height="100%"
                      src={img}
                      alt={noRegister}
                      sx={oldImageCardMedia}
                      onClick={() => {
                        window.open(img, "_blank", "noopener,noreferrer");
                      }}
                    />
                  </CardActionArea>
                </Card>
              ))}
          </Carousel>
        )}
        <Divider sx={dividerStyle} />
        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tanggal Survey :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nopol :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={nopolSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Surveyor :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={kodeSurveyor} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Status :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={statusSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Register :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noRegister} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Alamat Survey :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={almSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Alamat Survey :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={almSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightSmall}>
                    Link Maps Survey :
                  </Form.Label>
                  <Col sm="8">
                    <TextField
                      disabled
                      value={linkMapsSurvey}
                      defaultValue={linkMapsSurvey}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Link href={linkMapsSurvey}>Buka Link</Link>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightSmall}>
                    Link Maps Survey 2 :
                  </Form.Label>
                  <Col sm="8">
                    <TextField
                      disabled
                      value={linkMapsSurvey2}
                      defaultValue={linkMapsSurvey2}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Link href={linkMapsSurvey2}>Buka Link</Link>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Telepon Survey :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tlpSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. KTP :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noKtpSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Alamat KTP :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={almKtpSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. KK :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noKKSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Ibu Kandung :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaIbuKandungSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Pekerjaan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={pekerjaanId} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode Kecamatan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={kecamatanId} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Penjamin :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaPjmSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Alamat Penjamin :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={almPjmSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Telepon Penjamin :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tlpPjmSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Hubungan Penjamin :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={hubunganSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. KTP Penjamin :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noKtpPjmSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Pekerjaan Penjamin :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={pekerjaanPenjaminId} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Referensi :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaRefSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Alamat Referensi :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={almRefSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Telepon Referensi :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tlpRefSurvey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Referensi 2 :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaRef2Survey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Alamat Referensi 2 :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={almRef2Survey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Telepon Referensi 2 :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tlpRef2Survey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Referensi 3 :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaRef3Survey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Alamat Referensi 3 :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={almRef3Survey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Telepon Referensi 3 :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tlpRef3Survey} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TampilSurvey;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const carouselStyle = {
  display: "flex",
  height: "200px",
};

const carouselCard = {
  m: "auto",
  mt: 2,
  maxWidth: "500px",
  maxHeight: "500px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "primary",
};

const oldImageCardMedia = {
  display: "flex",
  maxHeight: "150px",
};
