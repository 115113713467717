import React, { useState, useContext } from "react";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";

const KonsumenAktifPerSurveyor = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  let date = new Date();
  const [filterJenisStruk, setFilterJenisStruk] = useState("");
  let [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const { screenSize } = useStateContext();

  const columns = [
    { title: "No.", field: "no" },
    { title: "Kode", field: "kodeSurveyor" },
    { title: "Nama Surveyor", field: "namaSurveyor" },
    { title: "Jumlah", field: "total" },
    { title: "Pemutihan", field: "totalPemutihan" },
    { title: "Total", field: "totalAll" },
    { title: "%", field: "persen" },
  ];

  const filterJenisStrukOption = [
    { label: "KM" },
    { label: "KU" },
    { label: "KL" },
  ];

  const downloadPdf = async () => {
    setLoading(true);

    let tempKodeSurveyor = "";

    if (user.tipeUser === "SUR") {
      tempKodeSurveyor = user.kodeSurveyor;
    }

    const response = await axios.post(
      `${tempUrl}/jualsForAngsuranAktifCabangLaporan`,
      {
        kodeSurveyor: tempKodeSurveyor,
        filterJenisStruk,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );

    let tempTotal = 0;
    let tempTotalPemutihan = 0;
    let tempTotalAll = 0;
    let tempHeight = 50;
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Rekap Konsumen Aktif - Per Surveyor`, 60, 30);
    doc.setFontSize(10);
    doc.text(`Cabang : ${user.cabang._id}`, 15, 45);
    doc.setFontSize(12);
    function alignCol(data) {
      var col = data.column.index;
      if (col >= 3 && col <= 6) {
        data.cell.styles.halign = "right";
      }
    }
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 50,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: response.data,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didDrawPage: (d) => {
        tempHeight = d.cursor.y;
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 30 },
        2: { cellWidth: 50 },
        3: { cellWidth: 20 },
        4: { cellWidth: 30 },
        5: { cellWidth: 20 },
        6: { cellWidth: 20 },
        // etc
      },
    });
    response.data.map((val) => {
      tempTotal += val.total;
      tempTotalPemutihan += val.totalPemutihan;
      tempTotalAll += val.totalAll;
    });
    doc.setDrawColor(0, 0, 0);
    doc.setFontSize(10);
    tempHeight += 2;
    doc.line(15, tempHeight, 195, tempHeight);
    tempHeight += 6;
    doc.text(`TOTAL : `, 15, tempHeight);
    doc.text(`${tempTotal}`, 122, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalPemutihan}`, 152, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalAll}`, 172, tempHeight, {
      align: "right",
    });
    doc.text(`100`, 192, tempHeight, {
      align: "right",
    });
    tempHeight += 4;
    doc.line(15, tempHeight, 195, tempHeight);
    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan Konsumen Aktif - Per Surveyor
      </Typography>
      <Divider sx={dividerStyle} />
      <Box>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Filter :
              </Form.Label>
              <Col sm="9">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={filterJenisStrukOption}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      error={error && filterJenisStruk.length === 0 && true}
                      helperText={
                        error &&
                        filterJenisStruk.length === 0 &&
                        "Filter harus diisi!"
                      }
                      {...params}
                    />
                  )}
                  onChange={(e, value) => {
                    if (value) {
                      setFilterJenisStruk(value.label);
                    } else {
                      setFilterJenisStruk("");
                    }
                  }}
                  value={filterJenisStruk}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <Button
          variant="contained"
          startIcon={<PrintIcon />}
          onClick={() => {
            downloadPdf();
          }}
        >
          CETAK
        </Button>
      </Box>
    </Box>
  );
};

export default KonsumenAktifPerSurveyor;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw"
  // }
};
