import React, { useState, useContext } from "react";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import jsPDF from "jspdf";
import PrintIcon from "@mui/icons-material/Print";

const LabaRugi = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [vertical] = useState("bottom");
  const [horizontal] = useState("center");
  const [persenLoading, setPersenLoading] = useState("0");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const postingLabaRugiPeriodeBerjalan = async (
    jumlah,
    doc,
    tempPersenLoading
  ) => {
    let countNeracaSaldo = 0;
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    var newDariTgl = new Date(user.periode.periodeAwal);
    var newSampaiTgl = new Date(user.periode.periodeAkhir);
    try {
      setLoading(true);
      let dariTgl =
        newDariTgl.getFullYear() +
        "-" +
        (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        newDariTgl.getDate().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
      let sampaiTgl =
        newSampaiTgl.getFullYear() +
        "-" +
        (newSampaiTgl.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        newSampaiTgl.getDate().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });

      // Jurnal Posting Laba Rugi Periode Berjalan
      await axios.post(`${tempUrl}/saveJurnalPostingLabaRugiPeriodeBerjalan`, {
        dariTgl,
        sampaiTgl,
        labaRugiPeriodeBerjalan: setting.labaRugiPeriodeBerjalan,
        accountPembalance: setting.accountPembalance,
        jumlah,
        periodeAwal: user.periode.periodeAwal,
        namaPeriode: user.periode.namaPeriode,
        tglInput: current_date,
        jamInput: current_time,
        userInput: user.username,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      tempPersenLoading += 20;
      setPersenLoading(tempPersenLoading.toString());

      // Update Periode Lama
      let tempDariTgl =
        newDariTgl.getFullYear() +
        "-" +
        (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        "01";
      dariTgl = tempDariTgl;

      var lastday = function (year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();
      };

      sampaiTgl =
        newDariTgl.getFullYear() +
        "-" +
        (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        lastday(newDariTgl.getFullYear(), newDariTgl.getMonth())

      const allNeracaSaldo = await axios.post(`${tempUrl}/neracaSaldos`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      tempPersenLoading += 20;
      setPersenLoading(tempPersenLoading.toString());

      // Delete Neraca Saldo
      for (let i = 0; i < allNeracaSaldo.data.length; i++) {
        var dateParts = allNeracaSaldo.data[i].bulan.split("-");
        let tempTglNeracaSaldo = new Date(
          `${+dateParts[2]}-
          ${dateParts[1].toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}-
          ${(+dateParts[0] + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`
        );
        let tempDariTgl = new Date(dariTgl);
        let tglBigger = tempTglNeracaSaldo >= tempDariTgl;
        if (tglBigger) {
          // Delete tglBigger Neraca Saldo
          await axios.post(
            `${tempUrl}/deleteNeracaSaldo/${allNeracaSaldo.data[i]._id}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          countNeracaSaldo++;
        }
      }
      tempPersenLoading += 20;
      setPersenLoading(tempPersenLoading.toString());

      // Save Neraca Saldo
      for (let i = 0; i < countNeracaSaldo; i++) {
        // Make Last Neraca Saldo
        await axios.post(`${tempUrl}/saveLastNeracaSaldoRebuild`, {
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        // Update Neraca Saldo Jurnal Posting
        await axios.post(`${tempUrl}/saveJurnalPostingNeracaSaldoRebuild`, {
          dariTgl,
          sampaiTgl,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });

        let tempDariTgl = new Date(dariTgl);
        let newDariTgl = new Date(
          tempDariTgl.setMonth(tempDariTgl.getMonth() + 1)
        );
        tempDariTgl =
          newDariTgl.getFullYear() +
          "-" +
          (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          "01";
        dariTgl = tempDariTgl;

        sampaiTgl =
          newDariTgl.getFullYear() +
          "-" +
          (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          lastday(newDariTgl.getFullYear(), newDariTgl.getMonth())
      }
      setPersenLoading("0");
      window.open(URL.createObjectURL(doc.output("blob")));
      setOpen(true);
      setLoading(false);
    } catch (e) {
      alert(e);
    }
  };

  const downloadPdf = async () => {
    setLoading(true);

    let tempPersenLoading = parseInt(persenLoading);
    let labaRugis = await axios.post(`${tempUrl}/labaRugisRebuild`, {
      groupPendapatan: setting.groupPendapatan,
      groupBiayaOperasional: setting.groupBiayaOperasional,
      groupPendapatanNonOperasional: setting.groupPendapatanNonOperasional,
      groupBiayaNonOperasional: setting.groupBiayaNonOperasional,
      dariTgl: user.periode.periodeAwal,
      sampaiTgl: user.periode.periodeAkhir,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    tempPersenLoading += 15;
    setPersenLoading(tempPersenLoading.toString());

    let isTotalPendapatan = true;
    let hal = 1;
    let tempSampaiTgl = user.periode.periodeAkhir;

    let labaRugiOperasional = 0;
    let tempY = 5;
    let tempYStart;
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF("p", "mm", [240, 300]);
    doc.setFontSize(9);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      10,
      290
    );
    doc.text(
      `${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`,
      10,
      tempY
    );
    doc.text(`Hal: ${hal}`, 220, 5);
    tempY += 5;
    doc.text(`${setting.lokasiPerusahaan}`, 10, tempY);
    tempY += 5;
    doc.text(`${setting.lokasiSP}`, 10, tempY);
    tempY += 5;
    doc.text(`${setting.kotaPerusahaan}`, 10, tempY);
    tempY += 10;
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.text(`LABA / RUGI`, 110, tempY);
    doc.setFontSize(9);
    tempY += 5;
    let dateParts = tempSampaiTgl.split("-");
    doc.text(`Per ${dateParts[2]} ${user.periode.namaPeriode}`, 105, tempY);
    doc.setFont(undefined, "normal");
    tempY += 5;
    tempYStart = 20;
    doc.line(10, tempY, 230, tempY);

    let keys = Object.keys(labaRugis.data);

    for (let j = 0; j < Object.keys(keys).length; j++) {
      if (tempY > 255) {
        doc.addPage();
        tempY = tempYStart;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }
      let tempTotalSaldo = 0;
      let tempSaldo;
      tempY += 8;
      doc.setFont(undefined, "bold");
      doc.text(`${labaRugis.data[keys[j]][0].namaGroupCOA} :`, 12, tempY);

      let groupByKodeSubGroupCOA = labaRugis.data[keys[j]].reduce(
        (group, labaRugi) => {
          const { namaSubGroupCOA } = labaRugi;
          group[namaSubGroupCOA] = group[namaSubGroupCOA] ?? [];
          group[namaSubGroupCOA].push(labaRugi);
          return group;
        },
        {}
      );

      let keysSubGroup = Object.keys(groupByKodeSubGroupCOA);

      for (let j = 0; j < Object.keys(keysSubGroup).length; j++) {
        if (tempY > 255) {
          doc.addPage();
          tempY = tempYStart;
          hal++;
          doc.text(`Hal: ${hal}`, 220, 5);
          doc.text(
            `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
            10,
            290
          );
        }
        tempSaldo = 0;
        tempY += 10;
        doc.setFont(undefined, "bold");
        doc.text(
          `${groupByKodeSubGroupCOA[keysSubGroup[j]][0].namaSubGroupCOA} :`,
          15,
          tempY
        );
        tempY += 2;

        doc.setFont(undefined, "normal");
        for (
          let k = 0;
          k < groupByKodeSubGroupCOA[keysSubGroup[j]].length;
          k++
        ) {
          if (tempY > 255) {
            doc.addPage();
            tempY = tempYStart;
            hal++;
            doc.text(`Hal: ${hal}`, 220, 5);
            doc.text(
              `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
              10,
              290
            );
          }
          tempY += 5;
          doc.text(
            `${groupByKodeSubGroupCOA[keysSubGroup[j]][k].namaCOA}`,
            20,
            tempY
          );
          if (groupByKodeSubGroupCOA[keysSubGroup[j]][k].jumlah > 0) {
            // Positive
            doc.text(
              `${groupByKodeSubGroupCOA[keysSubGroup[j]][
                k
              ].jumlah.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
              160,
              tempY,
              {
                align: "right",
              }
            );
          } else {
            // Negative
            doc.text(
              `(${groupByKodeSubGroupCOA[keysSubGroup[j]][
                k
              ].jumlah.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).slice(1)})`,
              160,
              tempY,
              {
                align: "right",
              }
            );
          }
          tempSaldo += groupByKodeSubGroupCOA[keysSubGroup[j]][k].jumlah;
          tempY += 2;
        }
        tempTotalSaldo += tempSaldo;
        tempY += 1;
        doc.line(130, tempY, 160, tempY);
        tempY += 4;
        doc.setFont(undefined, "bold");
        doc.text(
          `SUBTOTAL ${
            groupByKodeSubGroupCOA[keysSubGroup[j]][0].namaSubGroupCOA
          } :`,
          15,
          tempY
        );
        if (tempSaldo > 0) {
          // Postive
          doc.text(
            `${tempSaldo.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
            160,
            tempY,
            {
              align: "right",
            }
          );
        } else {
          // Negative
          doc.text(
            `(${tempSaldo
              .toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              .slice(1)})`,
            160,
            tempY,
            {
              align: "right",
            }
          );
        }
        doc.setFont(undefined, "normal");
      }
      tempY += 4;
      tempY += 5;
      doc.setFont(undefined, "bold");
      doc.text(`TOTAL ${labaRugis.data[keys[j]][0].namaGroupCOA} :`, 12, tempY);
      if (tempTotalSaldo > 0) {
        // Positive
        doc.text(
          `${tempTotalSaldo.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          225,
          tempY,
          {
            align: "right",
          }
        );
      } else {
        // Negative
        doc.text(
          `(${tempTotalSaldo
            .toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            .slice(1)})`,
          225,
          tempY,
          {
            align: "right",
          }
        );
      }
      if (
        labaRugis.data[keys[j]][0].kodeCOA.substring(0, 3) === "301" ||
        labaRugis.data[keys[j]][0].kodeCOA.substring(0, 3) === "303"
      ) {
        // Jumlahkan Laba Rugi Operasional
        labaRugiOperasional += tempTotalSaldo;
      } else if (
        labaRugis.data[keys[j]][0].kodeCOA.substring(0, 3) === "302" ||
        labaRugis.data[keys[j]][0].kodeCOA.substring(0, 3) === "304"
      ) {
        // Kurang Laba Rugi Operasional
        labaRugiOperasional -= tempTotalSaldo;
      }
      isTotalPendapatan = false;
      tempY += 1;
      doc.line(190, tempY, 225, tempY);
      doc.setFont(undefined, "normal");
    }
    tempY += 4;
    doc.line(10, tempY, 230, tempY);
    tempY += 8;
    doc.setFont(undefined, "bold");
    doc.text(`LABA RUGI OPERASIONAL :`, 12, tempY);
    if (labaRugiOperasional > 0) {
      // Positive
      doc.text(
        `${labaRugiOperasional.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        225,
        tempY,
        {
          align: "right",
        }
      );
    } else {
      // Negative
      doc.text(
        `(${labaRugiOperasional
          .toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .slice(1)})`,
        225,
        tempY,
        {
          align: "right",
        }
      );
    }
    tempY += 1;
    doc.line(190, tempY, 225, tempY);
    postingLabaRugiPeriodeBerjalan(
      labaRugiOperasional.toFixed(2),
      doc,
      tempPersenLoading
    );
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Accounting</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laba Rugi
      </Typography>
      <Typography sx={subTitleText}>
        Periode : {user.periode.namaPeriode}
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={spacingTop}>
        <Button
          variant="contained"
          startIcon={<PrintIcon />}
          onClick={() => downloadPdf()}
        >
          CETAK
        </Button>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Jurnal Laba Rugi Periode Berjalan berhasil diposting!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LabaRugi;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};
