import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { ShowTableTakeover } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../components";
import { Box, Typography, Divider, Pagination, Button } from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";

const TampilTakeover = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [isTakeoverExist, setIsTakeoverExist] = useState(false);
  const [noTakeover, setNoTakeover] = useState("");
  const [tanggalTakeover, setTanggalTakeover] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [noJual, setNoJual] = useState("");
  const [noRegisterDari, setNoRegisterDari] = useState("");
  const [noRegisterKe, setNoRegisterKe] = useState("");
  const [namaRegisterDari, setNamaRegisterDari] = useState("");
  const [namaRegisterKe, setNamaRegisterKe] = useState("");
  const [noKtpRegisterDari, setNoKtpRegisterDari] = useState("");
  const [noKtpRegisterKe, setNoKtpRegisterKe] = useState("");

  const [value, setValue] = useState("Semua");
  const [searchTerm, setSearchTerm] = useState("");
  const [takeoversData, setTakeoversData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id && getTakeoverById();
  }, [id]);

  useEffect(() => {
    getTakeoversData();
  }, [value, page, searchTerm]);

  const getTakeoversData = async () => {
    setLoading(true);
    try {
      let allTakeovers = await axios.post(
        `${tempUrl}/takeoversPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );

      setQuery(searchTerm);
      setTakeoversData(allTakeovers.data.takeovers);
      setPage(allTakeovers.data.page);
      setPages(allTakeovers.data.totalPage);
      setRows(allTakeovers.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getTakeoverById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/takeovers/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsTakeoverExist(true);
      setNoTakeover(response.data.noTakeover);
      setTanggalTakeover(formatDate(response.data.tanggalTakeover));
      setKeterangan(response.data.keterangan);
      setNoJual(response.data.noJual);
      setNoRegisterDari(response.data.noRegisterDari);
      setNoRegisterKe(response.data.noRegisterKe);
      setNamaRegisterDari(response.data.namaRegisterDari);
      setNamaRegisterKe(response.data.namaRegisterKe);
      setNoKtpRegisterDari(response.data.noKtpRegisterDari);
      setNoKtpRegisterKe(response.data.noKtpRegisterKe);
    }
  };

  const deleteTakeover = async (id) => {
    try {
      setLoading(true);
      var date = new Date();
      var current_date =
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
      var current_time =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

      const findJualByNoJual = await axios.post(`${tempUrl}/jualByNoJual`, {
        noJual: noJual,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      const findCustomerByNoKtp = await axios.post(
        `${tempUrl}/customerByNoKtp`,
        {
          noKtpRegister: noKtpRegisterDari,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      // Update Customer Jual
      await axios.post(`${tempUrl}/updateJual/${findJualByNoJual.data._id}`, {
        noRegister: findCustomerByNoKtp.data.noRegister,
        namaRegister: findCustomerByNoKtp.data.namaRegister,
        almRegister: findCustomerByNoKtp.data.almRegister,
        almKantor: findCustomerByNoKtp.data.almKantor,
        tlpRegister: findCustomerByNoKtp.data.tlpRegister,
        noKtpRegister: findCustomerByNoKtp.data.noKtpRegister,
        noKKRegister: findCustomerByNoKtp.data.noKKRegister,
        namaPjmRegister: findCustomerByNoKtp.data.namaPjmRegister,
        noKtpPjmRegister: findCustomerByNoKtp.data.noKtpPjmRegister,
        namaRefRegister: findCustomerByNoKtp.data.namaRefRegister,
        almRefRegister: findCustomerByNoKtp.data.almRefRegister,
        tlpRefRegister: findCustomerByNoKtp.data.tlpRefRegister,
        kodeMarketing: findCustomerByNoKtp.data.kodeMarketing,
        kodeSurveyor: findCustomerByNoKtp.data.kodeSurveyor,
        kodePekerjaan: findCustomerByNoKtp.data.kodePekerjaan,
        ketPekerjaan: findCustomerByNoKtp.data.ketPekerjaan,
        kodeKecamatan: findCustomerByNoKtp.data.kodeKecamatan,
        kodeLeasing: findCustomerByNoKtp.data.kodeLeasing,
        tglUpdate: current_date,
        jamUpdate: current_time,
        userUpdate: user.username,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      // Delete Point Kembali
      await axios.post(`${tempUrl}/deleteTakeover/${id}`, {
        id: user._id,
        token: user.token,
      });

      setIsTakeoverExist(false);
      setNoTakeover("");
      setTanggalTakeover("");
      setKeterangan("");
      setNoJual("");
      setNoRegisterDari("");
      setNoRegisterKe("");
      setNamaRegisterDari("");
      setNamaRegisterKe("");
      setNoKtpRegisterDari("");
      setNoKtpRegisterKe("");
      setLoading(false);
      navigate("/takeover");
      getTakeoversData();
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Jual</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Takeover
      </Typography>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={isTakeoverExist}
          addLink={`/takeover/tambahTakeover`}
          editLink={null}
          deleteUser={deleteTakeover}
          nameUser={noJual}
        />
      </Box>
      <hr />
      {isTakeoverExist && (
        <>
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA Takeover
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Takeover :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noTakeover} disabled readOnly />
                      <Form.Control.Feedback type="invalid">
                        No. Takeover harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Takeover :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tanggalTakeover} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Keterangan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={keterangan}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Register Dari :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRegisterDari} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Register Ke :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRegisterKe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Dari :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRegisterDari} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Ke :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRegisterKe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KTP Dari :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noKtpRegisterDari} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KTP Ke :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noKtpRegisterKe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableTakeover currentPosts={takeoversData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilTakeover;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};
