import React, { useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaBook,
  FaUserCog,
  FaSignOutAlt,
  FaFileInvoiceDollar,
  FaExchangeAlt,
} from "react-icons/fa";
import SettingsIcon from "@mui/icons-material/Settings";
import ClassIcon from "@mui/icons-material/Class";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MopedIcon from "@mui/icons-material/Moped";
import PublicIcon from "@mui/icons-material/Public";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import HailIcon from "@mui/icons-material/Hail";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import CarRentalIcon from "@mui/icons-material/CarRental";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ElevatorIcon from "@mui/icons-material/Elevator";
import InventoryIcon from "@mui/icons-material/Inventory";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SellIcon from "@mui/icons-material/Sell";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import PaymentIcon from "@mui/icons-material/Payment";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ConstructionIcon from "@mui/icons-material/Construction";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AddCardIcon from "@mui/icons-material/AddCard";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import PaymentsIcon from "@mui/icons-material/Payments";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ApprovalIcon from "@mui/icons-material/Approval";
import RateReviewIcon from "@mui/icons-material/RateReview";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import BalanceIcon from "@mui/icons-material/Balance";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import AddchartIcon from "@mui/icons-material/Addchart";
import SoapIcon from "@mui/icons-material/Soap";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import TodayIcon from "@mui/icons-material/Today";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SummarizeIcon from "@mui/icons-material/Summarize";

const Sidebar = ({
  image,
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}) => {
  const { user, dispatch, setting } = useContext(AuthContext);
  const navigate = useNavigate();

  const logoutButtonHandler = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  let isAbleApprovePemutihan = false;

  for (let i = 0; i < setting.userOwnerBisaApprovePemutihan.length; i++) {
    if (setting.userOwnerBisaApprovePemutihan[i].username === user.username) {
      isAbleApprovePemutihan =
        user.akses.approvePemutihan === true && user.tipeUser === "OWN";
    }
  }

  return (
    <ProSidebar
      // image={image ? sidebarBg : false}
      collapsed={collapsed}
      toggled={toggled}
      onToggle={handleToggleSidebar}
      breakPoint="md"
    >
      {/* HEADER */}
      <SidebarHeader>
        <Menu iconShape="circle">
          {collapsed ? (
            <MenuItem
              icon={<FaAngleDoubleRight />}
              onClick={handleCollapsedChange}
            ></MenuItem>
          ) : (
            <MenuItem
              suffix={<FaAngleDoubleLeft />}
              onClick={handleCollapsedChange}
            >
              <div
                style={{
                  padding: "9px",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  fontSize: 15,
                  letterSpacing: "1px",
                }}
              >
                {setting.namaProgram} {setting.namaSoftware}
              </div>
            </MenuItem>
          )}
        </Menu>
      </SidebarHeader>
      {/* CONTENT */}
      <SidebarContent>
        <Menu>
          <SubMenu
            title={"Dashboard"}
            icon={<QueryStatsIcon name="dashboard-icon" />}
          >
            {user.tipeUser === "OWN" && (
              <MenuItem icon={<AutoGraphIcon name="dashboard-owner-icon" />}>
                Dashboard Owner <NavLink to="/dashboardOwner" />
              </MenuItem>
            )}
            {user.tipeUser === "MGR" && (
              <MenuItem icon={<LeaderboardIcon name="dashboard-owner-icon" />}>
                Dashboard Manager <NavLink to="/dashboardCabang" />
              </MenuItem>
            )}
          </SubMenu>
          <SubMenu title={"Master"} icon={<ClassIcon name="master-icon" />}>
            {user.akses.motor === true && (
              <SubMenu title={"Motor"} icon={<MopedIcon name="motor-icon" />}>
                <MenuItem>
                  Tipe/Merk <NavLink to="/tipe" />
                </MenuItem>
                <MenuItem>
                  Warna <NavLink to="/warna" />
                </MenuItem>
              </SubMenu>
            )}
            {user.akses.area === true && (
              <SubMenu title={"Area"} icon={<PublicIcon name="area-icon" />}>
                <MenuItem>
                  Wilayah <NavLink to="/wilayah" />
                </MenuItem>
                <MenuItem>
                  Kecamatan <NavLink to="/kecamatan" />
                </MenuItem>
              </SubMenu>
            )}
            {user.akses.bukuBesar === true && (
              <SubMenu
                title={"Buku Besar"}
                icon={<MenuBookIcon name="buku-besar-icon" />}
              >
                <MenuItem>
                  Jenis COA <NavLink to="/jenisCOA" />
                </MenuItem>
                <MenuItem>
                  Group COA <NavLink to="/groupCOA" />
                </MenuItem>
                <MenuItem>
                  Sub Group COA <NavLink to="/subGroupCOA" />
                </MenuItem>
                <MenuItem>
                  COA <NavLink to="/coa" />
                </MenuItem>
              </SubMenu>
            )}
            {user.akses.dealer === true && (
              <MenuItem icon={<PersonPinIcon name="dealer-icon" />}>
                Dealer <NavLink to="/dealer" />
              </MenuItem>
            )}
            {user.akses.kolektor === true && (
              <MenuItem icon={<SensorOccupiedIcon name="dealer-icon" />}>
                Eksekutor <NavLink to="/kolektor" />
              </MenuItem>
            )}
            {user.akses.marketing === true && (
              <MenuItem icon={<AddBusinessIcon name="marketing-icon" />}>
                Marketing <NavLink to="/marketing" />
              </MenuItem>
            )}
            {user.akses.pekerjaan === true && (
              <MenuItem icon={<EngineeringIcon name="pekerjaan-icon" />}>
                Pekerjaan <NavLink to="/pekerjaan" />
              </MenuItem>
            )}
            {user.akses.surveyor === true && (
              <MenuItem icon={<HailIcon name="surveyor-icon" />}>
                Surveyor <NavLink to="/surveyor" />
              </MenuItem>
            )}
            {user.akses.leasing === true && (
              <MenuItem icon={<CarRentalIcon name="leasing-icon" />}>
                Leasing <NavLink to="/leasing" />
              </MenuItem>
            )}
            {user.akses.supplier === true && (
              <MenuItem icon={<AddReactionIcon name="supplier-icon" />}>
                Supplier <NavLink to="/supplier" />
              </MenuItem>
            )}
            {user.akses.cabang === true && (
              <MenuItem icon={<AddHomeWorkIcon name="cabang-icon" />}>
                Cabang <NavLink to="/cabang" />
              </MenuItem>
            )}
            {user.akses.masterCustomer === true && (
              <MenuItem
                icon={<CancelPresentationIcon name="masterCustomer-icon" />}
              >
                Master Customer <NavLink to="/masterCustomer" />
              </MenuItem>
            )}
            {user.akses.customerPerSurveyor === true && (
              <MenuItem
                icon={
                  <CancelPresentationIcon name="customerPerSurveyor-icon" />
                }
              >
                Customer Per Surveyor <NavLink to="/customerPerSurveyor" />
              </MenuItem>
            )}
            {user.akses.customer === true && (
              <MenuItem icon={<CancelPresentationIcon name="customer-icon" />}>
                Customer <NavLink to="/customer" />
              </MenuItem>
            )}
          </SubMenu>
          <SubMenu
            title={"Transaksi"}
            icon={<AddShoppingCartIcon name="transaksi-icon" />}
          >
            {user.akses.register === true && (
              <MenuItem icon={<CurrencyExchangeIcon name="penjualan-icon" />}>
                Register <NavLink to="/register" />
              </MenuItem>
            )}
            {user.akses.survey === true && (
              <MenuItem icon={<CurrencyExchangeIcon name="penjualan-icon" />}>
                Survey <NavLink to="/daftarSurvey" />
              </MenuItem>
            )}
            {user.akses.reject === true && (
              <MenuItem icon={<CancelPresentationIcon name="reject-icon" />}>
                Reject <NavLink to="/daftarReject" />
              </MenuItem>
            )}
            {user.akses.blacklist === true && (
              <MenuItem icon={<CancelPresentationIcon name="blacklist-icon" />}>
                Blacklist <NavLink to="/daftarBlacklist" />
              </MenuItem>
            )}
            {user.akses.beli === true && (
              <MenuItem icon={<ShoppingBagIcon name="beli-icon" />}>
                Beli <NavLink to="/daftarBeli" />
              </MenuItem>
            )}
            {user.akses.jual === true && (
              <MenuItem icon={<SellIcon name="register-icon" />}>
                Jual <NavLink to="/jual" />
              </MenuItem>
            )}
            {user.akses.takeover === true && (
              <MenuItem icon={<CompareArrowsIcon name="sp-icon" />}>
                Takeover <NavLink to="/takeover" />
              </MenuItem>
            )}
            <SubMenu
              title={"Pembayaran"}
              icon={<PublicIcon name="pembayaran-icon" />}
            >
              {user.akses.angsuran === true && (
                <MenuItem icon={<PaymentIcon name="profil-icon" />}>
                  Angsuran <NavLink to="/daftarAngsuran" />
                </MenuItem>
              )}
              {user.akses.percepatan === true && (
                <MenuItem icon={<PaymentIcon name="profil-icon" />}>
                  Entry Percepatan <NavLink to="/tambahPercepatan" />
                </MenuItem>
              )}
            </SubMenu>
            <SubMenu
              title={"Angsuran Beda Cabang"}
              icon={<PublicIcon name="angsuran-icon" />}
            >
              {user.akses.angsuranBedaCabang === true && (
                <MenuItem icon={<PaymentIcon name="profil-icon" />}>
                  Angsuran <NavLink to="/daftarAngsuranBedaCabang" />
                </MenuItem>
              )}
              {user.akses.percepatanBedaCabang === true && (
                <MenuItem icon={<PaymentIcon name="profil-icon" />}>
                  Entry Percepatan <NavLink to="/tambahPercepatanBedaCabang" />
                </MenuItem>
              )}
            </SubMenu>
            {user.akses.pemutihan === true && (
              <MenuItem icon={<CompareArrowsIcon name="sp-icon" />}>
                Pemutihan <NavLink to="/daftarPemutihan" />
              </MenuItem>
            )}
            {isAbleApprovePemutihan && (
              <MenuItem icon={<CompareArrowsIcon name="sp-icon" />}>
                Approve Pemutihan <NavLink to="/daftarApprovePemutihan" />
              </MenuItem>
            )}
          </SubMenu>
          <SubMenu
            title={"Penerbitan Surat"}
            icon={<CreditCardIcon name="penerbitan-surat-icon" />}
          >
            <SubMenu title={"Surat"} icon={<PublicIcon name="surat-icon" />}>
              {user.akses.sp === true && (
                <MenuItem icon={<HistoryEduIcon name="sp-icon" />}>
                  SP <NavLink to="/suratPemberitahuan" />
                </MenuItem>
              )}
              {user.akses.sp1 === true && (
                <MenuItem icon={<HistoryEduIcon name="sp1-icon" />}>
                  SP1 <NavLink to="/sp1" />
                </MenuItem>
              )}
              {user.akses.sp2 === true && (
                <MenuItem icon={<HistoryEduIcon name="sp2-icon" />}>
                  SP2 <NavLink to="/sp2" />
                </MenuItem>
              )}
              {user.akses.st === true && (
                <MenuItem icon={<CompareArrowsIcon name="sp-icon" />}>
                  Surat Tarik <NavLink to="/suratPenarikan" />
                </MenuItem>
              )}
              {user.akses.penarikan === true && (
                <MenuItem icon={<CompareArrowsIcon name="sp-icon" />}>
                  Pelunasan Program <NavLink to="/penarikan" />
                </MenuItem>
              )}
              {user.akses.titip === true && (
                <MenuItem icon={<CompareArrowsIcon name="sp-icon" />}>
                  Tarik/Titip <NavLink to="/titip" />
                </MenuItem>
              )}
              {user.akses.sweeping === true && (
                <MenuItem icon={<CompareArrowsIcon name="sp-icon" />}>
                  Sweeping <NavLink to="/sweeping" />
                </MenuItem>
              )}
              {user.akses.motorHilang === true && (
                <MenuItem icon={<CompareArrowsIcon name="sp-icon" />}>
                  Motor Hilang <NavLink to="/motorHilang" />
                </MenuItem>
              )}
              {user.akses.konsumenJatuhTempo === true && (
                <MenuItem icon={<CompareArrowsIcon name="sp-icon" />}>
                  Konsumen Jatuh Tempo <NavLink to="/konsumenJatuhTempo" />
                </MenuItem>
              )}
              {user.akses.spkHarusDibuat === true && (
                <MenuItem
                  icon={<PlaylistRemoveIcon name="spk-harus-dibuat-icon" />}
                >
                  SPK harus dibuat <NavLink to="/spkHarusDibuat" />
                </MenuItem>
              )}
            </SubMenu>
            <SubMenu
              title={"Tunggakan"}
              icon={<PublicIcon name="tunggakan-icon" />}
            >
              {user.akses.tunggakan === true && (
                <MenuItem icon={<PlaylistRemoveIcon name="tunggakan-icon" />}>
                  Tunggakan <NavLink to="/tunggakan" />
                </MenuItem>
              )}
              {user.akses.npl === true && (
                <MenuItem icon={<PlaylistRemoveIcon name="npl-icon" />}>
                  NPL <NavLink to="/npl" />
                </MenuItem>
              )}
            </SubMenu>
            <SubMenu title={"Poin"} icon={<PublicIcon name="poin-icon" />}>
              {user.akses.pointKembali === true && (
                <MenuItem icon={<CompareArrowsIcon name="sp-icon" />}>
                  Point Kembali <NavLink to="/pointKembali" />
                </MenuItem>
              )}
              {user.akses.point6Bulan === true && (
                <MenuItem icon={<SoapIcon name="laba-Rugi-Per-Unit-icon" />}>
                  Point 6 Bulan <NavLink to="/point6Bulan" />
                </MenuItem>
              )}
            </SubMenu>
            <SubMenu
              title={"Laporan"}
              icon={<PublicIcon name="laporan-icon" />}
            >
              {user.akses.biayaTarikPerSurveyor === true && (
                <MenuItem icon={<SoapIcon name="laba-Rugi-Per-Unit-icon" />}>
                  Biaya Tarik/Surveyor <NavLink to="/biayaTarikPerSurveyor" />
                </MenuItem>
              )}
              {user.akses.jemputanPerKolektor === true && (
                <MenuItem icon={<SoapIcon name="laba-Rugi-Per-Unit-icon" />}>
                  Jemputan/Kolektor <NavLink to="/jemputanPerKolektor" />
                </MenuItem>
              )}
              {user.akses.konsumenAktifPerSurveyor === true && (
                <MenuItem icon={<SoapIcon name="laba-Rugi-Per-Unit-icon" />}>
                  Kon. Aktif/Surveyor <NavLink to="/konsumenAktifPerSurveyor" />
                </MenuItem>
              )}
            </SubMenu>
          </SubMenu>
          <SubMenu
            title={"Finance"}
            icon={<MonetizationOnIcon name="Finance-icon" />}
          >
            {user.akses.kasMasuk === true && (
              <MenuItem icon={<AddCardIcon name="kas-masuk-icon" />}>
                Kas Masuk <NavLink to="/daftarKasMasuk" />
              </MenuItem>
            )}
            {user.akses.kasKeluar === true && (
              <MenuItem icon={<CardMembershipIcon name="kas-keluar-icon" />}>
                Kas Keluar <NavLink to="/daftarKasKeluar" />
              </MenuItem>
            )}
            {user.akses.bankMasuk === true && (
              <MenuItem icon={<FileOpenIcon name="bank-masuk-icon" />}>
                Bank Masuk <NavLink to="/daftarBankMasuk" />
              </MenuItem>
            )}
            {user.akses.bankKeluar === true && (
              <MenuItem
                icon={<IndeterminateCheckBoxIcon name="bank-keluar-icon" />}
              >
                Bank Keluar <NavLink to="/daftarBankKeluar" />
              </MenuItem>
            )}
          </SubMenu>
          <SubMenu
            title={"Laporan"}
            icon={<SummarizeIcon name="laporan-icon" />}
          >
            <SubMenu
              title={"Penerimaan"}
              icon={<PublicIcon name="penerimaan-icon" />}
            >
              <SubMenu
                title={"Penerimaan Kas"}
                icon={<PublicIcon name="penerimaan-kas-icon" />}
              >
                {user.akses.penerimaanKas === true && (
                  <MenuItem>
                    Sama Cabang <NavLink to="/penerimaanKas" />
                  </MenuItem>
                )}
                {user.akses.penerimaanKasBedaCabang === true && (
                  <MenuItem>
                    Beda Cabang
                    <NavLink to="/penerimaanKasBedaCabang" />
                  </MenuItem>
                )}
              </SubMenu>
              {user.akses.kasHarian === true && (
                <MenuItem icon={<TodayIcon name="kas-harian-icon" />}>
                  Kas Harian <NavLink to="/kasHarian" />
                </MenuItem>
              )}
            </SubMenu>
            {user.akses.penambahanPiutang === true && (
              <SubMenu
                title={"Penambahan Piutang"}
                icon={<PublicIcon name="penambahan-piutang-icon" />}
              >
                <MenuItem icon={<SoapIcon name="penambahan-piutang-icon" />}>
                  Rekap <NavLink to="/penambahanPiutang" />
                </MenuItem>
                <MenuItem
                  icon={<SoapIcon name="penambahan-piutang-rinci-icon" />}
                >
                  Rinci
                  <NavLink to="/penambahanPiutangRinci" />
                </MenuItem>
              </SubMenu>
            )}
            <SubMenu
              title={"Penjualan"}
              icon={<PublicIcon name="penjualan-icon" />}
            >
              {user.akses.lapPenjualan === true && (
                <SubMenu
                  title={"Lap. Penjualan"}
                  icon={<PointOfSaleIcon name="lap-penjualan-icon" />}
                >
                  <MenuItem
                    icon={<AddchartIcon name="penjualan-per-cabang-icon" />}
                  >
                    Per Cabang <NavLink to="/penjualanPerCabang" />
                  </MenuItem>
                  <MenuItem>
                    Per Tipe <NavLink to="/lapPenjualanPerTipe" />
                  </MenuItem>
                  <MenuItem>
                    Per Marketing <NavLink to="/lapPenjualanPerMarketing" />
                  </MenuItem>
                  <MenuItem>
                    Per Surveyor <NavLink to="/lapPenjualanPerSurveyor" />
                  </MenuItem>
                  <MenuItem>
                    Per Supplier <NavLink to="/lapPenjualanPerSupplier" />
                  </MenuItem>
                  <MenuItem>
                    Kredit&Tunai <NavLink to="/lapPenjualanKreditTunai" />
                  </MenuItem>
                  <MenuItem>
                    Kredit / Tenor <NavLink to="/lapPenjualanKreditPerTenor" />
                  </MenuItem>
                </SubMenu>
              )}
              {user.akses.labaRugiPerUnit === true && (
                <MenuItem icon={<SoapIcon name="laba-Rugi-Per-Unit-icon" />}>
                  L/R Per Unit <NavLink to="/labaRugiPerUnit" />
                </MenuItem>
              )}
            </SubMenu>
            {user.akses.totalPiutang === true && (
              <MenuItem icon={<RecentActorsIcon name="total-piutang-icon" />}>
                Total Piutang <NavLink to="/totalPiutang" />
              </MenuItem>
            )}
            {user.tipeUser === "OWN" && user.akses.rekapPenerimaan === true && (
              <MenuItem icon={<SoapIcon name="rekap-penerimaan-icon" />}>
                Rekap Penerimaan <NavLink to="/rekapPenerimaan" />
              </MenuItem>
            )}
          </SubMenu>
          <SubMenu
            title={"Data Unit"}
            icon={<InventoryIcon name="data-unit-icon" />}
          >
            {user.akses.daftarStok === true && (
              <MenuItem icon={<FormatListNumberedIcon name="profil-icon" />}>
                Daftar Stok <NavLink to="/daftarStok" />
              </MenuItem>
            )}
            <SubMenu
              title={"Biaya Perawatan Motor"}
              icon={<PublicIcon name="biaya-perawatan-motor-icon" />}
            >
              {user.akses.biayaPerawatan === true && (
                <MenuItem
                  icon={<RequestQuoteIcon name="biaya-perawatan-icon" />}
                >
                  Biaya Perawatan <NavLink to="/biayaPerawatan" />
                </MenuItem>
              )}
              {user.akses.rincianBiaya === true && (
                <MenuItem icon={<RequestQuoteIcon name="rincian-biaya-icon" />}>
                  Rincian Biaya <NavLink to="/rincianBiaya" />
                </MenuItem>
              )}
            </SubMenu>
          </SubMenu>
          <SubMenu
            title={"Accounting"}
            icon={<AccountBalanceWalletIcon name="accounting-icon" />}
          >
            {user.akses.posting === true && (
              <MenuItem icon={<PostAddIcon name="posting-icon" />}>
                Posting <NavLink to="/posting" />
              </MenuItem>
            )}
            {user.akses.unposting === true && (
              <MenuItem icon={<ApprovalIcon name="unposting-icon" />}>
                Unposting <NavLink to="/unposting" />
              </MenuItem>
            )}
            {user.akses.aktivitasBukuBesar === true && (
              <MenuItem
                icon={<RateReviewIcon name="aktivitasBukuBesar-icon" />}
              >
                Aktivitas Buku Besar <NavLink to="/aktivitasBukuBesar" />
              </MenuItem>
            )}
            {user.akses.neracaSaldo === true && (
              <MenuItem icon={<AutoStoriesIcon name="neracaSaldo-icon" />}>
                Neraca Saldo <NavLink to="/neracaSaldo" />
              </MenuItem>
            )}
            {user.akses.labaRugi === true && (
              <MenuItem icon={<MoneyOffIcon name="labaRugi-icon" />}>
                Laba Rugi <NavLink to="/labaRugi" />
              </MenuItem>
            )}
            {user.akses.neraca === true && (
              <MenuItem icon={<BalanceIcon name="neraca-icon" />}>
                Neraca <NavLink to="/neraca" />
              </MenuItem>
            )}
          </SubMenu>
          <SubMenu
            title={"Utility"}
            icon={<ManageAccountsIcon name="utility-icon" />}
          >
            {user.akses.profilUser === true && (
              <MenuItem icon={<PersonSearchIcon name="profil-icon" />}>
                Profil User <NavLink to="/profilUser" />
              </MenuItem>
            )}
            {user.akses.daftarUser === true &&
              user.tipeUser !== "ADM" &&
              user.tipeUser !== "FIN" && (
                <MenuItem icon={<ElevatorIcon name="daftar-icon" />}>
                  Daftar User <NavLink to="/daftarUser" />
                </MenuItem>
              )}
            {user.akses.tutupPeriode === true && user.tipeUser !== "ADM" && (
              <MenuItem icon={<DoorSlidingIcon name="tutupPeriode-icon" />}>
                Tutup Periode <NavLink to="/tutupPeriode" />
              </MenuItem>
            )}
            {user.akses.gantiPeriode === true && (
              <MenuItem icon={<ManageHistoryIcon name="gantiPeriode-icon" />}>
                Ganti Periode <NavLink to="/gantiPeriode" />
              </MenuItem>
            )}
            {user.akses.setting === true &&
              user.tipeUser !== "ADM" &&
              user.tipeUser !== "FIN" && (
                <MenuItem icon={<SettingsIcon name="setting-icon" />}>
                  Setting <NavLink to="/setting" />
                </MenuItem>
              )}
          </SubMenu>
        </Menu>
      </SidebarContent>
      {/* FOOTER */}
      <SidebarFooter style={{ textAlign: "center" }}>
        <p style={{ fontSize: "12px", marginTop: "10px" }}>{user.username}</p>
        <p style={{ fontSize: "12px", marginTop: "-10px" }}>
          Cabang : {user.cabang.namaCabang}
        </p>
        <div className="sidebar-btn-wrapper" style={{ paddingBottom: "10px" }}>
          <Link
            className="sidebar-btn"
            style={{ cursor: "pointer" }}
            to="/"
            onClick={logoutButtonHandler}
          >
            <span style={{ marginRight: "6px" }}>Logout</span>
            <FaSignOutAlt />
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;
