import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { ShowTableApprovePemutihanChild } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../components";
import {
  Box,
  Chip,
  Typography,
  Divider,
  Pagination,
  ButtonGroup,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import { Colors } from "../../../constants/styles";

const TampilApprovePemutihan = () => {
  const { setting, user } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [isPemutihansExist, setIsPemutihansExist] = useState(false);
  const [noBukti, setNoBukti] = useState("");
  const [tglPengajuan, setTglPengajuan] = useState("");
  const [jumlahChild, setJumlahChild] = useState("");
  const [totalSisaPiutang, setTotalSisaPiutang] = useState("");
  const [status, setStatus] = useState("");
  const [status2, setStatus2] = useState("");
  const [tglApprove, setTglApprove] = useState("");
  const [tglApprove2, setTglApprove2] = useState("");
  const [userInputApprove, setUserInputApprove] = useState("");
  const [userInputApprove2, setUserInputApprove2] = useState("");
  const [cabangInput, setCabangInput] = useState("");
  const [isPost, setIsPost] = useState("");
  const [isAbleApprove, setIsAbleApprove] = useState(false);

  const [value, setValue] = useState("Semua");
  const [searchTerm, setSearchTerm] = useState("");
  const [pemutihanChildData, setPemutihanChildData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  const handleChangeFilter = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    getPemutihanById();
  }, [id, value, page, searchTerm]);

  const getPemutihanById = async () => {
    if (id) {
      setLoading(true);
      const response = await axios.post(`${tempUrl}/pemutihans/${id}`, {
        id: user._id,
        token: user.token,
      });
      setNoBukti(response.data.noBukti);
      setTglPengajuan(formatDate(response.data.tglPengajuan));
      setJumlahChild(response.data.jumlahChild);
      setTotalSisaPiutang(response.data.totalSisaPiutang);
      setStatus(
        response.data.status == "APPROVE" ? "Approve" : "Belum Approve"
      );
      setStatus2(
        response.data.status2 == "APPROVE" ? "Approve" : "Belum Approve"
      );
      setTglApprove(formatDate(response.data.tglApprove));
      setTglApprove2(formatDate(response.data.tglApprove2));
      setUserInputApprove(response.data.userInputApprove);
      setUserInputApprove2(response.data.userInputApprove2);

      let tempIsAbleApprove = false;

      if (
        response.data.userInputApprove != user.username &&
        response.data.userInputApprove2 != user.username
      ) {
        tempIsAbleApprove = true;
      }

      setIsAbleApprove(tempIsAbleApprove);

      setCabangInput(response.data.cabang._id);
      setIsPost(response.data.isPost);

      let allPemutihansChild;
      switch (value) {
        case "Belum Approve":
          allPemutihansChild = await axios.post(
            `${tempUrl}/pemutihansChildPaginationBelumApprove?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              noBukti: response.data.noBukti,
              id: user._id,
              token: user.token,
              kodeCabang: response.data.cabang._id,
            }
          );
          break;
        case "Sudah Approve":
          allPemutihansChild = await axios.post(
            `${tempUrl}/pemutihansChildPaginationSudahApprove?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              noBukti: response.data.noBukti,
              id: user._id,
              token: user.token,
              kodeCabang: response.data.cabang._id,
            }
          );
          break;
        default:
          allPemutihansChild = await axios.post(
            `${tempUrl}/pemutihansChildByNoBuktiPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              noBukti: response.data.noBukti,
              id: user._id,
              token: user.token,
              kodeCabang: response.data.cabang._id,
            }
          );
          break;
      }
      setQuery(searchTerm);
      setPemutihanChildData(allPemutihansChild.data.pemutihansChild);
      setPage(allPemutihansChild.data.page);
      setPages(allPemutihansChild.data.totalPage);
      setRows(allPemutihansChild.data.totalRows);
      setLoading(false);
    }
  };

  const batalApprovePemutihanyNoBukti = async (id) => {
    try {
      setLoading(true);

      if (userInputApprove === user.username) {
        // Batal Approve
        await axios.post(`${tempUrl}/batalApprovePemutihanNoBukti1`, {
          noBukti,
          username: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: cabangInput,
        });
      } else if (userInputApprove2 === user.username) {
        // Batal Approve
        await axios.post(`${tempUrl}/batalApprovePemutihanNoBukti2`, {
          noBukti,
          username: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: cabangInput,
        });
      }

      setLoading(false);
      navigate(`/daftarApprovePemutihan`);
    } catch (error) {
      console.log(error);
    }
  };

  const approvePemutihanByNoBukti = async (id) => {
    try {
      setLoading(true);

      if (status === "Belum Approve") {
        // Delete Surat Penarikan
        await axios.post(`${tempUrl}/approvePemutihanByNoBukti1`, {
          noBukti,
          username: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: cabangInput,
        });
      } else if (status2 === "Belum Approve") {
        // Delete Surat Penarikan
        await axios.post(`${tempUrl}/approvePemutihanByNoBukti2`, {
          noBukti,
          username: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: cabangInput,
        });
      }

      setLoading(false);
      navigate(`/daftarApprovePemutihan`);
    } catch (error) {
      console.log(error);
    }
  };

  const deletePemutihan = async (id) => {
    try {
      setLoading(true);

      // Delete Surat Penarikan
      await axios.post(`${tempUrl}/deletePemutihan/${id}`, {
        noBukti,
        id: user._id,
        token: user.token,
        kodeCabang: cabangInput,
      });

      setNoBukti("");
      setTglPengajuan("");
      setJumlahChild("");
      setTotalSisaPiutang("");
      setStatus("");
      setStatus2("");
      setTglApprove("");
      setUserInputApprove("");
      setCabangInput("");
      setLoading(false);
      navigate("/daftarApprovePemutihan");
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarApprovePemutihan")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Pemutihan
      </Typography>
      <hr />
      <Box sx={buttonModifierContainer}>
        {isPost === false && (
          <ButtonGroup variant="contained">
            {isAbleApprove === true ? (
              <Button
                color="success"
                sx={{ bgcolor: "success.light", textTransform: "none" }}
                size="small"
                onClick={() => approvePemutihanByNoBukti(id)}
              >
                Approve
              </Button>
            ) : (
              <Button
                color="warning"
                sx={{ bgcolor: "warning.light", textTransform: "none" }}
                size="small"
                onClick={() => batalApprovePemutihanyNoBukti(id)}
              >
                Batalkan Approve
              </Button>
            )}
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "none" }}
              onClick={handleClickOpen}
            >
              Tolak
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <Box sx={showDataContainer}>
        <Box sx={showDataWrapper}>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  No. Bukti :
                </Form.Label>
                <Col sm="8">
                  <Form.Control value={noBukti} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Tgl. Pengajuan :
                </Form.Label>
                <Col sm="8">
                  <Form.Control value={tglPengajuan} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Jumlah :
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    value={jumlahChild.toLocaleString("en-US")}
                    disabled
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Total Sisa Piutang :
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    value={totalSisaPiutang.toLocaleString("en-US")}
                    disabled
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Status :
                </Form.Label>
                <Col sm="8">
                  <Form.Control value={status} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Tgl. Approve :
                </Form.Label>
                <Col sm="8">
                  <Form.Control value={tglApprove} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  User Approve :
                </Form.Label>
                <Col sm="8">
                  <Form.Control value={userInputApprove} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Box>
        <Box sx={[showDataWrapper, secondWrapper]}></Box>
      </Box>
      <hr />
      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Filter</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="Semua"
          value={value}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            value="Sudah Approve"
            control={<Radio />}
            label="Sudah Approve"
          />
          <FormControlLabel
            value="Belum Approve"
            control={<Radio />}
            label="Belum Approve"
          />
          <FormControlLabel value="Semua" control={<Radio />} label="Semua" />
        </RadioGroup>
      </FormControl>
      <Divider sx={dividerStyle} />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={{ mt: 2 }}>
        <Chip
          label="Sudah Approve"
          sx={{
            backgroundColor: Colors.green400,
          }}
        />
        <Chip
          label="Belum Approve"
          sx={{
            backgroundColor: Colors.red400,
          }}
        />
      </Box>
      <Box sx={tableContainer}>
        <ShowTableApprovePemutihanChild
          currentPosts={pemutihanChildData}
          idPemutihan={id}
        />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Yakin ingin menghapus data ${noBukti}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deletePemutihan(id)}>Ok</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TampilApprovePemutihan;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
