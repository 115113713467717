import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Colors } from "../../../constants/styles";
import {
  Box,
  Typography,
  TextField,
  ButtonGroup,
  Button,
  Divider,
  Autocomplete,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const LabaRugiPerUnit = () => {
  const tableRef = useRef(null);
  const { user, setting, dispatch } = useContext(AuthContext);
  let date = new Date();
  let [inputDariTgl, setInputDariTgl] = useState(date);
  let [inputSampaiTgl, setInputSampaiTgl] = useState(date);
  const [totalHargaTunai, setTotalHargaTunai] = useState(0);
  const [totalModal, setTotalModal] = useState(0);
  const [totalLabaRugi, setTotalLabaRugi] = useState(0);
  const [persenLoading, setPersenLoading] = useState("0");
  const [labaRugiPerUnit, setLabaRugiPerUnit] = useState([]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const columns = [
    { title: "No", field: "no" },
    { title: "No. Kontrak", field: "noJual" },
    { title: "Tanggal", field: "tanggalJualFormatted" },
    { title: "Tipe", field: "namaTipe" },
    { title: "No. Plat / Thn / Warna", field: "nopolTahunNamaWarna" },
    { title: "Harga Jual", field: "hargaTunaiDoc" },
    { title: "Modal", field: "modalDoc" },
    { title: "Laba/Rugi", field: "labaRugiDoc" },
  ];

  useEffect(() => {}, []);

  const downloadPdf = async () => {
    setLoading(true);
    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);

    let tempPersenLoading = parseInt(persenLoading);
    const response = await axios.post(`${tempUrl}/labaRugiPerUnit`, {
      dariTgl: inputDariTgl,
      sampaiTgl: inputSampaiTgl,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    tempPersenLoading += 50;
    setPersenLoading(tempPersenLoading.toString());

    let tempTotalHargaTunai = 0;
    let tempTotalModal = 0;
    let tempTotalLabaRugi = 0;
    let tempHeight = 50;
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    const doc = new jsPDF("l", "mm", [340, 210]);
    doc.setFontSize(12);
    let tempYStart = 10;
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Laporan Laba Rugi Per Unit`, 140, 30);
    doc.setFontSize(10);
    doc.text(`Dari Tanggal : ${dariTgl} s/d ${sampaiTgl}`, 15, 35);
    doc.text(`Cabang : ${user.cabang.namaCabang}`, 15, 40);
    doc.setFontSize(12);
    function alignCol(data) {
      var col = data.column.index;
      if (col >= 5 && col <= 8) {
        data.cell.styles.halign = "right";
      }
    }
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 50,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: response.data,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didDrawPage: (d) => {
        tempHeight = d.cursor.y;
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 35 },
        2: { cellWidth: 40 },
        3: { cellWidth: 50 },
        4: { cellWidth: 60 },
        5: { cellWidth: 40 },
        6: { cellWidth: 40 },
        7: { cellWidth: 40 },
        // etc
      },
    });
    response.data.map((val) => {
      tempTotalHargaTunai += val.hargaTunai;
      tempTotalModal += val.modal;
      tempTotalLabaRugi += val.labaRugi;
    });
    doc.setDrawColor(0, 0, 0);
    doc.setFontSize(10);
    tempHeight += 2;
    if (tempHeight > 151) {
      doc.addPage();
      tempHeight = tempYStart;
    }
    doc.line(15, tempHeight, 330, tempHeight);
    tempHeight += 6;
    doc.text(`TOTAL : `, 15, tempHeight);
    doc.text(
      `${parseInt(tempTotalHargaTunai).toLocaleString("en-US")}`,
      247,
      tempHeight,
      {
        align: "right",
      }
    );
    doc.text(
      `${parseInt(tempTotalModal).toLocaleString("en-US")}`,
      287,
      tempHeight,
      {
        align: "right",
      }
    );
    doc.text(
      `${parseInt(tempTotalLabaRugi).toLocaleString("en-US")}`,
      327,
      tempHeight,
      {
        align: "right",
      }
    );
    tempHeight += 4;
    doc.line(15, tempHeight, 330, tempHeight);
    tempHeight += 12;
    doc.text(`Mengetahui,`, 80, tempHeight);
    doc.text(`${setting.lokasiSP}, ${current_date}`, 270, tempHeight);
    doc.text(`Dibuat Oleh,`, 275, tempHeight + 6);
    tempHeight += 30;
    doc.line(60, tempHeight, 120, tempHeight);
    doc.line(255, tempHeight, 315, tempHeight);
    tempHeight += 6;
    doc.text(`${user.username}`, 80, tempHeight);
    doc.text(`SPV`, 280, tempHeight);
    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
    setPersenLoading("0");
  };

  const getLabaRugiPerUnit = async () => {
    setLoading(true);
    let tempTotalHargaTunai = 0;
    let tempTotalModal = 0;
    let tempTotalLabaRugi = 0;
    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);
    const response = await axios.post(`${tempUrl}/labaRugiPerUnit`, {
      dariTgl: inputDariTgl,
      sampaiTgl: inputSampaiTgl,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setLabaRugiPerUnit(response.data);
    response.data.map((val) => {
      tempTotalHargaTunai += val.hargaTunai;
      tempTotalModal += val.modal;
      tempTotalLabaRugi += val.labaRugi;
    });
    setTotalHargaTunai(tempTotalHargaTunai);
    setTotalModal(tempTotalModal);
    setTotalLabaRugi(tempTotalLabaRugi);
    setLoading(false);
    setTimeout(() => {
      onDownload();
    }, 2000);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Penambahan Piutang",
    sheet: "LabaRugiPerUnit",
  });

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan Laba Rugi / Unit
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputDariTgl}
                  onChange={(e) => setInputDariTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputDariTgl === null && true}
                      helperText={
                        error &&
                        inputDariTgl === null &&
                        "Dari Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Sampai Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputSampaiTgl}
                  onChange={(e) => setInputSampaiTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputSampaiTgl === null && true}
                      helperText={
                        error &&
                        inputSampaiTgl === null &&
                        "Sampai Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            variant="contained"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => {
              getLabaRugiPerUnit();
            }}
          >
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>
      <table ref={tableRef} style={{ visibility: "hidden" }}>
        <tbody>
          <tr>
            <th>Laporan Laba Rugi Per Unit</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>No</th>
            <th>No. Kontrak</th>
            <th>Tanggal</th>
            <th>Tipe</th>
            <th>No. Plat / Thn / Warna</th>
            <th>Harga Jual</th>
            <th>Modal</th>
            <th>Laba/Rugi</th>
          </tr>
          {labaRugiPerUnit.map((item, i) => (
            <tr>
              <th>{item.no}</th>
              <th>{item.noJual}</th>
              <th>{item.tanggalJualFormatted}</th>
              <th>{item.namaTipe}</th>
              <th>{item.nopolTahunNamaWarna}</th>
              <th>{item.hargaTunaiDoc}</th>
              <th>{item.modalDoc}</th>
              <th>{item.labaRugiDoc}</th>
            </tr>
          ))}
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>{totalHargaTunai}</th>
            <th>{totalModal}</th>
            <th>{totalLabaRugi}</th>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

export default LabaRugiPerUnit;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw"
  // }
};
