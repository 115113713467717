import { Button, Alert, Box } from "@mui/material";

export const Fallback = () => {
  return (
    <Box sx={{ width: "500px" }}>
      <Alert severity="error">Terjadi Kesalahan di Program!</Alert>
      <a href="/">
        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: "none", mt: 2 }}
        >
          Refresh
        </Button>
      </a>
    </Box>
  );
};
