import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { Colors } from "../../../constants/styles";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Loader, ScrollToTop } from "../../../components";
import {
  Paper,
  Box,
  TextField,
  Typography,
  Divider,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row, Form } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";

const UbahSweeping = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [noSweeping, setNoSweeping] = useState("");
  const [noJual, setNoJual] = useState("");
  const [tglSweeping, setTglSweeping] = useState(new Date());
  const [nopol, setNopol] = useState("");
  const [merk, setMerk] = useState("");
  const [tahun, setTahun] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [namaStnk, setNamaStnk] = useState("");
  const [alamatStnk, setAlamatStnk] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [tunggakan, setTunggakan] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getSweepingById();
  }, [id]);

  const getSweepingById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/sweepings/${id}`, {
        id: user._id,
        token: user.token,
      });
      setNoSweeping(response.data.noSweeping);
      setNoJual(response.data.noJual);
      setTglSweeping(new Date(response.data.tglSweeping));
      setNopol(response.data.nopol);
      setMerk(response.data.merk);
      setTahun(response.data.tahun);
      setNoRangka(response.data.noRangka);
      setNoMesin(response.data.noMesin);
      setNamaWarna(response.data.namaWarna);
      setNamaStnk(response.data.namaStnk);
      setAlamatStnk(response.data.alamatStnk);
      setMerk(response.data.merk);
      setNamaRegister(response.data.namaRegister);
      setAlmRegister(response.data.almRegister);
      setTunggakan(response.data.tunggakan);
      setKeterangan(response.data.keterangan);
      setKodeMarketing(
        `${response.data.idJual.kodeMarketing.kodeMarketing} - ${response.data.idJual.kodeMarketing.namaMarketing}`
      );
      setKodeSurveyor(
        `${response.data.idJual.kodeSurveyor.kodeSurveyor} - ${response.data.idJual.kodeSurveyor.namaSurveyor}`
      );
    }
  };

  const updateSweeping = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation = false;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/updateSweeping/${id}`, {
          namaStnk,
          alamatStnk,
          tglSweeping,
          keterangan,
          tglUpdate: current_date,
          jamUpdate: current_time,
          userUpdate: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate(`/sweeping/${id}`);
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Ubah Sweeping
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={updateSweeping}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Sweeping :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noSweeping} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Sweeping :
                    </Form.Label>
                    <Col sm="8">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={tglSweeping}
                        onChange={(e) => {
                          setTglSweeping(e);
                        }}
                        customInput={
                          <TextField
                            error={error && tglSweeping === null && true}
                            helperText={
                              error &&
                              tglSweeping === null &&
                              "Tgl. Surat Sweeping harus diisi!"
                            }
                            sx={{ width: "100%" }}
                            size="small"
                          />
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Merk :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={merk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tahun :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tahun} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Rangka :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRangka} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Mesin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noMesin} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Warna :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaWarna} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama STNK :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaStnk}
                        required
                        onChange={(e) =>
                          setNamaStnk(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat STNK :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={alamatStnk}
                        required
                        onChange={(e) =>
                          setAlamatStnk(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Pemohon :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Tagih :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tunggakan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={`${tunggakan} hari`} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Marketing :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeMarketing} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Surveyor :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeSurveyor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Keterangan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={keterangan}
                        onChange={(e) => {
                          setKeterangan(e.target.value.toUpperCase());
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/sweeping/${id}`)}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<EditIcon />} type="submit">
              Ubah
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={dividerStyle} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default UbahSweeping;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const alertBox = {
  width: "100%",
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};

const colorRed = {
  color: "red",
};
