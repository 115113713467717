import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, ButtonModifier } from "../../../components";
import { Box, TextField, Typography, Divider, Button } from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";

const TampilBlacklist = () => {
  const { user, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [tglBlacklist, setTglBlacklist] = useState("");
  const [noRegister, setNoRegister] = useState("");
  const [namaBlacklist, setNamaBlacklist] = useState("");
  const [alamatBlacklist, setAlamatBlacklist] = useState("");
  const [tlpBlacklist, setTlpBlacklist] = useState("");
  const [noKKBlacklist, setNoKKBlacklist] = useState("");
  const [noKTPBlacklist, setNoKTPBlacklist] = useState("");
  const [nopolBlacklist, setNopolBlacklist] = useState("");
  const [catatanBlacklist, setCatatanBlacklist] = useState("");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    id && getBlacklistById();
  }, [id]);

  const getBlacklistById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/blacklists/${id}`, {
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setTglBlacklist(formatDate(response.data.tglBlacklist));
      setNoRegister(response.data.noRegister);
      setNamaBlacklist(response.data.namaBlacklist);
      setAlamatBlacklist(response.data.alamatBlacklist);
      setTlpBlacklist(response.data.tlpBlacklist);
      setNoKKBlacklist(response.data.noKKBlacklist);
      setNoKTPBlacklist(response.data.noKTPBlacklist);
      setNopolBlacklist(response.data.nopolBlacklist);
      setCatatanBlacklist(response.data.catatanBlacklist);
    }
  };

  const deleteBlacklist = async (id) => {
    try {
      setLoading(true);
      await axios.post(`${tempUrl}/deleteBlacklist/${id}`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setLoading(false);
      navigate("/daftarBlacklist");
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarBlacklist")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box>
        <Typography color="#757575">Penjualan</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Blacklist
        </Typography>
        <Box sx={buttonModifierContainer}>
          <ButtonModifier
            id={id}
            kode={"test"}
            addLink={`/daftarBlacklist/blacklist/${id}/tambahBlacklistChild`}
            editLink={`/daftarBlacklist/blacklist/${id}/edit`}
            deleteUser={deleteBlacklist}
            nameUser={namaBlacklist}
          />
        </Box>
        <Divider sx={dividerStyle} />
        <Divider sx={{ marginBottom: 2 }} />
        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tanggal Blacklist :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tglBlacklist} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. Register :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noRegister} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nama :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={namaBlacklist} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Alamat Blacklist :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={alamatBlacklist} disabled />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. KK :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noKKBlacklist} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. KTP :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noKTPBlacklist} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nopol :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={nopolBlacklist.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Telepon :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tlpBlacklist} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Catatan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={catatanBlacklist}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TampilBlacklist;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
