export const Colors = {
  blue50: "#e3f2fd",
  blue100: "#bbdefb",
  blue300: "#64b5f6",
  blue400: "#42a5f5",
  blue500: "#2196f3",
  blue700: "#1976d2",
  blue800: "#1565c0",
  grey100: "#f5f5f5",
  grey200: "#eeeeee",
  grey300: "#e0e0e0",
  grey400: "#d1d1d1",
  grey500: "#9e9e9e",
  grey800: "#424242",
  yellow100: "#ffff99",
  yellow400: "#ffff66",
  yellow500: "#ffff00",
  red400: "#f79892",
  green100: "#99ff99",
  green400: "#66ff66",
  green500: "#33cc33",
  purple300: "#CF9FFF",
  pink300: "#EFBAB5",
  orange300: "#FCA574",
};
