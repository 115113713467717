import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PanoramaIcon from "@mui/icons-material/Panorama";
import ArticleIcon from "@mui/icons-material/Article";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";

const ButtonModifierJual = ({
  user,
  id,
  kode,
  isPost,
  addLink,
  editCustomerLink,
  editLink,
  editFoto,
  editLinkStnkBpkb,
  editLinkNopol,
  deleteUser,
  nameUser,
  tambahBaru,
  addTambahText,
  editable,
  screenSize,
}) => {
  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" sx={buttonModifierContainer}>
        <Button
          color="success"
          sx={{ bgcolor: "success.light", textTransform: "none" }}
          startIcon={<AddCircleOutlineIcon />}
          size="small"
          onClick={() => {
            navigate(addLink);
          }}
        >
          {screenSize >= 650
            ? `Tambah ${addTambahText && addTambahText}`
            : `${addTambahText && addTambahText}`}
        </Button>
        {tambahBaru && (
          <Button
            color="secondary"
            sx={{ bgcolor: "secondary.light", textTransform: "none" }}
            startIcon={<AddCircleOutlineIcon />}
            size="small"
            onClick={() => {
              navigate(tambahBaru);
            }}
          >
            {screenSize >= 650 ? "Tambah Baru" : "Baru"}
          </Button>
        )}
        {id && (
          <>
            {editCustomerLink && (
              <Button
                color="primary"
                startIcon={screenSize >= 650 && <EditIcon />}
                sx={{ textTransform: "none" }}
                onClick={() => {
                  navigate(editCustomerLink);
                }}
              >
                {screenSize >= 650 ? "Ubah Customer" : <EditIcon />}
              </Button>
            )}
            {user.tipeUser !== "ADM" &&
              editLink &&
              editable &&
              isPost === false && (
                <Button
                  color="primary"
                  startIcon={screenSize >= 650 && <EditIcon />}
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    navigate(editLink);
                  }}
                >
                  {screenSize >= 650 ? "Ubah" : <EditIcon />}
                </Button>
              )}
            {editFoto && (
              <Button
                color="warning"
                startIcon={screenSize >= 650 && <PanoramaIcon />}
                sx={{ textTransform: "none" }}
                onClick={() => {
                  navigate(editFoto);
                }}
              >
                {screenSize >= 650 ? "Ubah Foto" : <PanoramaIcon />}
              </Button>
            )}
            {editLinkStnkBpkb && (
              <Button
                color="secondary"
                startIcon={screenSize >= 650 && <ArticleIcon />}
                sx={{ textTransform: "none" }}
                onClick={() => {
                  navigate(editLinkStnkBpkb);
                }}
              >
                {screenSize >= 650 ? "Ubah Stnk & Bpkb" : <ArticleIcon />}
              </Button>
            )}
            {editLinkNopol && (
              <Button
                color="secondary"
                sx={{ bgcolor: "secondary.light", textTransform: "none" }}
                startIcon={screenSize >= 650 && <TwoWheelerIcon />}
                onClick={() => {
                  navigate(editLinkNopol);
                }}
              >
                {screenSize >= 650 ? "Ubah Nopol" : <TwoWheelerIcon />}
              </Button>
            )}
            {user.tipeUser !== "ADM" && (
              <Button
                color="error"
                startIcon={screenSize >= 650 && <DeleteOutlineIcon />}
                sx={{ textTransform: "none" }}
                onClick={handleClickOpen}
              >
                {screenSize >= 650 ? "Hapus" : <DeleteOutlineIcon />}
              </Button>
            )}
          </>
        )}
      </ButtonGroup>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Yakin ingin menghapus data ${nameUser}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deleteUser(id)}>Ok</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ButtonModifierJual;

const buttonModifierContainer = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
