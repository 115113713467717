import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahSurvey = () => {
  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [tglSurvey, setTglSurvey] = useState(new Date());

  const [noRegister, setNoRegister] = useState("");
  const [namaSurvey, setNamaSurvey] = useState("");
  const [almSurvey, setAlmSurvey] = useState("");
  const [linkMapsSurvey, setLinkMapsSurvey] = useState("");
  const [linkMapsSurvey2, setLinkMapsSurvey2] = useState("");
  const [tlpSurvey, setTlpSurvey] = useState("");
  const [noKtpSurvey, setNoKtpSurvey] = useState("");
  const [almKtpSurvey, setAlmKtpSurvey] = useState("");
  const [noKKSurvey, setNoKKSurvey] = useState("");
  const [namaIbuKandungSurvey, setNamaIbuKandungSurvey] = useState("");
  const [namaPjmSurvey, setNamaPjmSurvey] = useState("");
  const [almPjmSurvey, setAlmPjmSurvey] = useState("");
  const [tlpPjmSurvey, setTlpPjmSurvey] = useState("");
  const [hubunganSurvey, setHubunganSurvey] = useState("");
  const [noKtpPjmSurvey, setNoKtpPjmSurvey] = useState("");
  const [namaRefSurvey, setNamaRefSurvey] = useState("");
  const [almRefSurvey, setAlmRefSurvey] = useState("");
  const [tlpRefSurvey, setTlpRefSurvey] = useState("");
  const [namaRef2Survey, setNamaRef2Survey] = useState("");
  const [almRef2Survey, setAlmRef2Survey] = useState("");
  const [tlpRef2Survey, setTlpRef2Survey] = useState("");
  const [namaRef3Survey, setNamaRef3Survey] = useState("");
  const [almRef3Survey, setAlmRef3Survey] = useState("");
  const [tlpRef3Survey, setTlpRef3Survey] = useState("");
  const [kecamatanId, setKecamatanId] = useState("");
  const [pekerjaanId, setPekerjaanId] = useState("");
  const [pekerjaanPenjaminId, setPekerjaanPenjaminId] = useState("");

  const [nopolSurvey, setNopolSurvey] = useState("");

  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kecamatans, setKecamatans] = useState([]);
  const [pekerjaans, setPekerjaans] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [registers, setRegisters] = useState([]);
  const [stoks, setStoks] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTermRegister, setSearchTermRegister] = useState("");
  const [openRegister, setOpenRegister] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermRegister(query);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const classes = useStyles();

  const handleClickOpenRegister = () => {
    setOpenRegister(true);
  };

  const handleCloseRegister = () => {
    setOpenRegister(false);
  };

  const kecamatanOptions = kecamatans.map((kecamatan) => ({
    label: `${kecamatan.kodeKecamatan} - ${kecamatan.namaKecamatan}`,
  }));

  const pekerjaanOptions = pekerjaans.map((pekerjaan) => ({
    label: `${pekerjaan.kodePekerjaan} - ${pekerjaan.namaPekerjaan}`,
  }));

  const surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  useEffect(() => {
    getKecamatan();
    getPekerjaan();
    getSurveyor();
  }, []);

  useEffect(() => {
    getRegister();
  }, [page, searchTermRegister]);

  const getKecamatan = async () => {
    setLoading(true);
    const allKecamatans = await axios.post(`${tempUrl}/kecamatans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKecamatans(allKecamatans.data);
    setLoading(false);
  };

  const getPekerjaan = async () => {
    setLoading(true);
    const allPekerjaans = await axios.post(`${tempUrl}/pekerjaans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setPekerjaans(allPekerjaans.data);
    setLoading(false);
  };

  const getSurveyor = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const getRegister = async () => {
    setLoading(true);
    const allRegisters = await axios.post(
      `${tempUrl}/registersPagination?search_query=${searchTermRegister}&page=${page}&limit=${limit}`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setQuery(searchTermRegister);
    setRegisters(allRegisters.data.registers);
    setPage(allRegisters.data.page);
    setPages(allRegisters.data.totalPage);
    setRows(allRegisters.data.totalRows);
    setLoading(false);
  };

  const saveSurvey = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation =
      noRegister.length === 0 ||
      tglSurvey.length === 0 ||
      almSurvey.length === 0 ||
      noKKSurvey.length === 0 ||
      noKtpSurvey.length === 0 ||
      nopolSurvey.length === 0 ||
      tlpSurvey.length === 0 ||
      kodeSurveyor.length === 0 ||
      namaSurvey.length === 0 ||
      almKtpSurvey.length === 0 ||
      pekerjaanId.length === 0 ||
      kecamatanId.length === 0 ||
      pekerjaanPenjaminId.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        let tempTglSurvey =
          tglSurvey.getFullYear() +
          "-" +
          (tglSurvey.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          tglSurvey.getDate().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
        const findKecamatan = await axios.post(`${tempUrl}/kecamatanByKode`, {
          kodeKecamatan: kecamatanId.split(" ", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const findPekerjaan = await axios.post(`${tempUrl}/pekerjaanByKode`, {
          kodePekerjaan: pekerjaanId.split(" ", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const findPekerjaanPenjamin = await axios.post(
          `${tempUrl}/pekerjaanByKode`,
          {
            kodePekerjaan: pekerjaanPenjaminId.split(" ", 1)[0],
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
          kodeSurveyor,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        await axios.post(`${tempUrl}/saveSurvey`, {
          kodeSurveyor: tempSurveyor.data._id,
          tglSurvey: tempTglSurvey,
          noRegister,
          namaSurvey,
          almSurvey,
          linkMapsSurvey,
          linkMapsSurvey2,
          tlpSurvey,
          noKtpSurvey,
          almKtpSurvey,
          noKKSurvey,
          namaIbuKandungSurvey,
          namaPjmSurvey,
          almPjmSurvey,
          tlpPjmSurvey,
          hubunganSurvey,
          noKtpPjmSurvey,
          namaRefSurvey,
          almRefSurvey,
          tlpRefSurvey,
          namaRef2Survey,
          almRef2Survey,
          tlpRef2Survey,
          namaRef3Survey,
          almRef3Survey,
          tlpRef3Survey,
          kecamatanId: findKecamatan.data._id,
          pekerjaanId: findPekerjaan.data._id,
          pekerjaanPenjaminId: findPekerjaanPenjamin.data._id,
          nopolSurvey,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate("/daftarSurvey");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Penjualan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah Survey
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveSurvey}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tanggal Survey <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={tglSurvey}
                        onChange={(e) => setTglSurvey(e)}
                        customInput={
                          <TextField
                            error={error && tglSurvey === null && true}
                            helperText={
                              error &&
                              tglSurvey === null &&
                              "Tanggal Survey harus diisi!"
                            }
                            sx={{ width: "100%" }}
                            size="small"
                          />
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nopol <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={nopolSurvey}
                        required
                        onChange={(e) =>
                          setNopolSurvey(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Nopol harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Surveyor <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={surveyorOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeSurveyor.length === 0 && true}
                            helperText={
                              error &&
                              kodeSurveyor.length === 0 &&
                              "Kode Surveyor harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setKodeSurveyor(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Register <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noRegister}
                        required
                        placeholder="Pilih..."
                        onClick={() => handleClickOpenRegister()}
                      />
                      <Form.Control.Feedback type="invalid">
                        Kode Register harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Survey <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaSurvey}
                        required
                        onChange={(e) =>
                          setNamaSurvey(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Nama harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Survey <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={almSurvey}
                        required
                        onChange={(e) =>
                          setAlmSurvey(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Alamat harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Link Maps Survey :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={linkMapsSurvey}
                        onChange={(e) => setLinkMapsSurvey(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Link Maps Survey 2 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={linkMapsSurvey2}
                        onChange={(e) => setLinkMapsSurvey2(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon Survey <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={tlpSurvey}
                        type="number"
                        required
                        onChange={(e) =>
                          setTlpSurvey(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Telepon harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KTP <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noKtpSurvey}
                        required
                        onChange={(e) =>
                          setNoKtpSurvey(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        No. KTP harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat KTP <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={almKtpSurvey}
                        required
                        onChange={(e) =>
                          setAlmKtpSurvey(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Alamat KTP harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KK <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noKKSurvey}
                        required
                        onChange={(e) =>
                          setNoKKSurvey(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        No. KK harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Ibu Kandung :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaIbuKandungSurvey}
                        onChange={(e) =>
                          setNamaIbuKandungSurvey(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Pekerjaan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={pekerjaanOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && pekerjaanId.length === 0 && true}
                            helperText={
                              error &&
                              pekerjaanId.length === 0 &&
                              "Kode Pekerjaan harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        value={{ label: pekerjaanId }}
                        onInputChange={(e, value) => setPekerjaanId(value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Kecamatan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={kecamatanOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kecamatanId.length === 0 && true}
                            helperText={
                              error &&
                              kecamatanId.length === 0 &&
                              "Kode Kecamatan harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        value={{ label: kecamatanId }}
                        onInputChange={(e, value) => setKecamatanId(value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaPjmSurvey}
                        onChange={(e) =>
                          setNamaPjmSurvey(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={almPjmSurvey}
                        onChange={(e) =>
                          setAlmPjmSurvey(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="number"
                        value={tlpPjmSurvey}
                        onChange={(e) =>
                          setTlpPjmSurvey(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Hubungan Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={hubunganSurvey}
                        onChange={(e) =>
                          setHubunganSurvey(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KTP Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noKtpPjmSurvey}
                        onChange={(e) =>
                          setNoKtpPjmSurvey(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Pekerjaan Penjamin <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={pekerjaanOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={
                              error && pekerjaanPenjaminId.length === 0 && true
                            }
                            helperText={
                              error &&
                              pekerjaanPenjaminId.length === 0 &&
                              "Pekerjaan Penjamin harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        value={{ label: pekerjaanPenjaminId }}
                        onInputChange={(e, value) =>
                          setPekerjaanPenjaminId(value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Referensi :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaRefSurvey}
                        onChange={(e) =>
                          setNamaRefSurvey(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Referensi :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={almRefSurvey}
                        onChange={(e) =>
                          setAlmRefSurvey(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon Referensi :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={tlpRefSurvey}
                        onChange={(e) =>
                          setTlpRefSurvey(e.target.value.toUpperCase())
                        }
                        type="number"
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Referensi 2 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaRef2Survey}
                        onChange={(e) =>
                          setNamaRef2Survey(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Referensi 2 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={almRef2Survey}
                        onChange={(e) =>
                          setAlmRef2Survey(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon Referensi 2 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={tlpRef2Survey}
                        onChange={(e) =>
                          setTlpRef2Survey(e.target.value.toUpperCase())
                        }
                        type="number"
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Referensi 3 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaRef3Survey}
                        onChange={(e) =>
                          setNamaRef3Survey(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Referensi 3 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={almRef3Survey}
                        onChange={(e) =>
                          setAlmRef3Survey(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon Referensi 3 :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={tlpRef3Survey}
                        onChange={(e) =>
                          setTlpRef3Survey(e.target.value.toUpperCase())
                        }
                        type="number"
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/daftarSurvey")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openRegister}
        onClose={handleCloseRegister}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Register`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Register
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tanggal
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Customer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {registers.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNoRegister(user.noRegister);
                        setNamaSurvey(user.namaRegister);
                        setAlmSurvey(user.almRegister);
                        setLinkMapsSurvey(user.linkMapsRegister);
                        setTlpSurvey(user.tlpRegister);
                        setNoKtpSurvey(user.noKtpRegister);
                        setAlmKtpSurvey(user.almKtpRegister);
                        setNoKKSurvey(user.noKKRegister);
                        setNamaIbuKandungSurvey(user.namaIbuKandungRegister);
                        setNamaPjmSurvey(user.namaPjmRegister);
                        setAlmPjmSurvey(user.almPjmRegister);
                        setTlpPjmSurvey(user.tlpPjmRegister);
                        setHubunganSurvey(user.hubunganRegister);
                        setNoKtpPjmSurvey(user.noKtpPjmRegister);
                        setNamaRefSurvey(user.namaRefRegister);
                        setAlmRefSurvey(user.almRefRegister);
                        setTlpRefSurvey(user.tlpRefRegister);
                        setPekerjaanId(
                          `${user.pekerjaanId.kodePekerjaan} - ${user.pekerjaanId.namaPekerjaan}`
                        );
                        setPekerjaanPenjaminId(
                          `${user.pekerjaanPenjaminId.kodePekerjaan} - ${user.pekerjaanPenjaminId.namaPekerjaan}`
                        );
                        setKecamatanId(
                          `${user.kecamatanId.kodeKecamatan} - ${user.kecamatanId.namaKecamatan}`
                        );

                        handleCloseRegister();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noRegister}
                      </TableCell>
                      <TableCell>{user.tanggalRegister}</TableCell>
                      <TableCell>{user.namaRegister}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TambahSurvey;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
