import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { formatDate, formatTime } from "../../../constants/helper";
import {
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  Autocomplete,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const RincianBiaya = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  let date = new Date();
  let [inputSampaiTgl, setInputSampaiTgl] = useState(date);
  const [nopol, setNopol] = useState("");
  let [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const { screenSize } = useStateContext();
  const [nopols, setNopols] = useState([]);

  const columns = [
    { title: "No", field: "no" },
    { title: "Tanggal", field: "tanggal" },
    { title: "No. Bukti", field: "noBukti" },
    { title: "Keterangan", field: "keterangan" },
    { title: "Jumlah", field: "jumlahDoc" },
  ];

  const nopolOptions = nopols.map((nopol) => ({
    label: `${nopol.nopol}`,
  }));

  useEffect(() => {
    getNopol();
  }, []);

  const getNopol = async () => {
    setLoading(true);
    const allNopols = await axios.post(
      `${tempUrl}/daftarStoksNopolForRincianBiaya`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setNopols(allNopols.data);
    setLoading(false);
  };

  const downloadPdf = async () => {
    setLoading(true);
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);

    let tempPersenLoading = parseInt(persenLoading);
    let getDaftarStoksByNopol = await axios.post(
      `${tempUrl}/daftarStoksByNopol`,
      {
        nopol,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );

    let getRincianBiaya = await axios.post(`${tempUrl}/rincianBiaya`, {
      nopol,
      sampaiTgl: inputSampaiTgl,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    tempPersenLoading += 50;
    setPersenLoading(tempPersenLoading.toString());

    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text("RINCIAN BIAYA - PER MOTOR", 80, 10);
    doc.setFontSize(10);

    let tempTotal = 0;
    let tempYStart = 20;
    let tempHeight = 20;
    doc.text("No. Plat", 15, tempHeight);
    doc.text(`: ${nopol}`, 50, tempHeight);
    tempHeight += 5;
    doc.text("Tipe", 15, tempHeight);
    doc.text(`: ${getDaftarStoksByNopol.data.tipe}`, 50, tempHeight);
    tempHeight += 5;
    doc.text("Warna", 15, tempHeight);
    doc.text(`: ${getDaftarStoksByNopol.data.namaWarna}`, 50, tempHeight);
    tempHeight += 5;
    doc.text("Rangka / Mesin", 15, tempHeight);
    doc.text(
      `: ${getDaftarStoksByNopol.data.noRangka} / ${getDaftarStoksByNopol.data.noMesin}`,
      50,
      tempHeight
    );
    tempHeight += 10;
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    function alignCol(data) {
      var col = data.column.index;
      if (col == 4) {
        data.cell.styles.halign = "right";
      }
    }

    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : tempHeight,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: getRincianBiaya.data,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didDrawPage: (d) => {
        tempHeight = d.cursor.y;
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
    });
    getRincianBiaya.data.map((val) => {
      tempTotal += val.jumlah;
    });

    tempHeight += 2;
    if (tempHeight > 151) {
      doc.addPage();
      tempHeight = tempYStart;
    }
    doc.line(15, tempHeight, 195, tempHeight);
    tempHeight += 6;
    doc.setFontSize(10);
    doc.text(`TOTAL`, 15, tempHeight);
    doc.text(`${tempTotal.toLocaleString()}`, 177, tempHeight);

    window.open(URL.createObjectURL(doc.output("blob")));
    setLoading(false);
    setPersenLoading("0");
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Rincian Biaya - Per Motor
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Nopol :
              </Form.Label>
              <Col sm="9">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={nopolOptions}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      error={error && nopol.length === 0 && true}
                      helperText={
                        error && nopol.length === 0 && "Nopol harus diisi!"
                      }
                      placeholder="SEMUA NOPOL"
                      {...params}
                    />
                  )}
                  onInputChange={(e, value) => setNopol(value)}
                  value={nopol}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Per Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputSampaiTgl}
                  onChange={(e) => setInputSampaiTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputSampaiTgl === null && true}
                      helperText={
                        error &&
                        inputSampaiTgl === null &&
                        "Sampai Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <Button
          variant="contained"
          startIcon={<PrintIcon />}
          onClick={() => {
            if (inputSampaiTgl === null) {
              setError(true);
            } else downloadPdf();
          }}
        >
          CETAK
        </Button>
      </Box>
    </Box>
  );
};

export default RincianBiaya;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "60vw"
  // }
};
