import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { Colors } from "../../../constants/styles";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Loader, ScrollToTop } from "../../../components";
import {
  Paper,
  Box,
  TextField,
  Autocomplete,
  Typography,
  Divider,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row, Form } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";

const UbahTitip = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [noTitip, setNoTitip] = useState("");
  const [noJual, setNoJual] = useState("");
  const [inputTglPenarikan, setInputTglPenarikan] = useState(null);
  const [inputTglBatasWaktu, setInputTglBatasWaktu] = useState(null);
  const [statusStnk, setStatusStnk] = useState("");
  const [statusStnkLama, setStatusStnkLama] = useState("");
  const [inputTglJTOPajak, setInputTglJTOPajak] = useState(null);
  const [statusKunciMotor, setStatusKunciMotor] = useState("");
  const [statusKunciMotorLama, setStatusKunciMotorLama] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [nopol, setNopol] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeKolektor, setKodeKolektor] = useState("");
  const [kodeKolektorLama, setKodeKolektorLama] = useState("");

  const [kolektors, setKolektors] = useState([]);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const kolektorOptions = kolektors.map((kolektor) => ({
    label: `${kolektor.kodeKolektor} - ${kolektor.namaKolektor}`,
  }));

  useEffect(() => {
    getKolektor();
    getTitipById();
  }, [id]);

  const getKolektor = async () => {
    setLoading(true);
    const allKolektors = await axios.post(`${tempUrl}/kolektors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKolektors(allKolektors.data);
    setLoading(false);
  };

  const getTitipById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/titips/${id}`, {
        id: user._id,
        token: user.token,
      });
      setNoTitip(response.data.noTitip);
      setNoJual(response.data.noJual);
      setInputTglPenarikan(new Date(response.data.tglPenarikan));
      setInputTglBatasWaktu(new Date(response.data.tglBatasWaktu));
      setStatusStnk(response.data.statusStnk);
      setStatusStnkLama(response.data.statusStnk);
      setInputTglJTOPajak(new Date(response.data.tglJTOPajak));
      setStatusKunciMotor(response.data.statusKunciMotor);
      setStatusKunciMotorLama(response.data.statusKunciMotor);
      setNamaRegister(response.data.statusKunciMotor);
      setNamaRegister(response.data.namaRegister);
      setNopol(response.data.nopol);
      setKodeMarketing(
        `${response.data.idJual.kodeMarketing.kodeMarketing} - ${response.data.idJual.kodeMarketing.namaMarketing}`
      );
      setKodeSurveyor(
        `${response.data.idJual.kodeSurveyor.kodeSurveyor} - ${response.data.idJual.kodeSurveyor.namaSurveyor}`
      );
      setKodeKolektor(
        `${response.data.kodeKolektor.kodeKolektor} - ${response.data.kodeKolektor.namaKolektor}`
      );
      setKodeKolektorLama(
        `${response.data.kodeKolektor.kodeKolektor} - ${response.data.kodeKolektor.namaKolektor}`
      );
    }
  };

  let formatTimeDate = (date) => {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      date.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
    );
  };

  const updateTitip = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    let tglPenarikan = formatTimeDate(inputTglPenarikan);
    let tglBatasWaktu = formatTimeDate(inputTglBatasWaktu);
    let tglJTOPajak = formatTimeDate(inputTglJTOPajak);

    let isFailedValidation =
      inputTglPenarikan === null ||
      inputTglBatasWaktu === null ||
      statusStnk.length === 0 ||
      inputTglJTOPajak === null ||
      statusKunciMotor.length === 0 ||
      kodeKolektor.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        const tempKolektor = await axios.post(`${tempUrl}/kolektorByKode`, {
          kodeKolektor: kodeKolektor.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        await axios.post(`${tempUrl}/updateTitip/${id}`, {
          tglPenarikan,
          tglBatasWaktu,
          statusStnk,
          tglJTOPajak,
          statusKunciMotor,
          kodeKolektor: tempKolektor.data._id,
          tglUpdate: current_date,
          jamUpdate: current_time,
          userUpdate: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate(`/titip/${id}`);
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  const statusOption = [{ label: "ADA" }, { label: "TIDAK ADA" }];

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Ubah Titip
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={updateTitip}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. Titip :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={noTitip} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Tgl. Penarikan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={inputTglPenarikan}
                        onChange={(e) => {
                          setInputTglPenarikan(e);
                        }}
                        customInput={
                          <TextField
                            error={error && inputTglPenarikan === null && true}
                            helperText={
                              error &&
                              inputTglPenarikan === null &&
                              "Tgl. Surat Penarikan harus diisi!"
                            }
                            sx={{ width: "100%" }}
                            size="small"
                          />
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Tgl. Batas Waktu <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={inputTglBatasWaktu}
                        onChange={(e) => {
                          setInputTglBatasWaktu(e);
                        }}
                        customInput={
                          <TextField
                            error={error && inputTglBatasWaktu === null && true}
                            helperText={
                              error &&
                              inputTglBatasWaktu === null &&
                              "Tgl. Batas Waktu harus diisi!"
                            }
                            sx={{ width: "100%" }}
                            size="small"
                          />
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Status STNK <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={statusOption}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && statusStnk.length === 0 && true}
                            helperText={
                              error &&
                              statusStnk.length === 0 &&
                              "Status Stnk harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => setStatusStnk(value)}
                        inputValue={statusStnk}
                        onChange={(e, value) => setStatusStnkLama(value)}
                        value={statusStnkLama}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Tgl. JTO Pajak <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={inputTglJTOPajak}
                        onChange={(e) => {
                          setInputTglJTOPajak(e);
                        }}
                        customInput={
                          <TextField
                            error={error && inputTglJTOPajak === null && true}
                            helperText={
                              error &&
                              inputTglJTOPajak === null &&
                              "Tgl. Batas Waktu harus diisi!"
                            }
                            sx={{ width: "100%" }}
                            size="small"
                          />
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Status Kunci Motor <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={statusOption}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={
                              error && statusKunciMotor.length === 0 && true
                            }
                            helperText={
                              error &&
                              statusKunciMotor.length === 0 &&
                              "Status Kunci Motor harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => setStatusKunciMotor(value)}
                        inputValue={statusKunciMotor}
                        onChange={(e, value) => setStatusKunciMotorLama(value)}
                        value={statusKunciMotorLama}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Marketing :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={kodeMarketing} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Surveyor :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={kodeSurveyor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Kode Eksekutor <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={kolektorOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeKolektor.length === 0 && true}
                            helperText={
                              error &&
                              kodeKolektor.length === 0 &&
                              "Kode Eksekutor harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => setKodeKolektor(value)}
                        inputValue={kodeKolektor}
                        onChange={(e, value) => setKodeKolektorLama(value)}
                        value={kodeKolektorLama}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/titip/${id}`)}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<EditIcon />} type="submit">
              Ubah
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={dividerStyle} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default UbahTitip;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const alertBox = {
  width: "100%",
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};

const colorRed = {
  color: "red",
};
