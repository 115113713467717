import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableUser } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, usePagination } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const DaftarUser = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const [isFetchError, setIsFetchError] = useState(false);
  const [isUserExist, setIsUserExist] = useState(false);
  const [kodeCabang, setKodeCabang] = useState("");
  const [username, setUsername] = useState("");
  const [tipeUser, setTipeUser] = useState("");
  const [kodeKolektor, setKodeKolektor] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [periode, setPeriode] = useState("");
  const [kodeKwitansi, setKodeKwitansi] = useState("");
  const [noTerakhir, setNoTerakhir] = useState("");
  const [coaKasir, setCoaKasir] = useState("");

  // Akses Master
  const [motor, setMotor] = useState(false);
  const [area, setArea] = useState(false);
  const [bukuBesar, setBukuBesar] = useState(false);
  const [dealer, setDealer] = useState(false);
  const [kolektor, setKolektor] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [pekerjaan, setPekerjaan] = useState(false);
  const [surveyor, setSurveyor] = useState(false);
  const [leasing, setLeasing] = useState(false);
  const [supplier, setSupplier] = useState(false);
  const [cabang, setCabang] = useState(false);

  // Akses Pembelian
  const [beli, setBeli] = useState(false);

  // Akses Penjualan
  const [register, setRegister] = useState(false);
  const [survey, setSurvey] = useState(false);
  const [reject, setReject] = useState(false);
  const [blacklist, setBlacklist] = useState(false);
  const [masterCustomer, setMasterCustomer] = useState(false);
  const [customerPerSurveyor, setCustomerPerSurveyor] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [takeover, setTakeover] = useState(false);
  const [jual, setJual] = useState(false);

  // Akses Laporan
  const [daftarStok, setDaftarStok] = useState(false);
  const [totalPiutang, setTotalPiutang] = useState(false);
  const [tunggakan, setTunggakan] = useState(false);
  const [npl, setNpl] = useState(false);
  const [spkHarusDibuat, setSpkHarusDibuat] = useState(false);
  const [penerimaanKas, setPenerimaanKas] = useState(false);
  const [penerimaanKasBedaCabang, setPenerimaanKasBedaCabang] = useState(false);
  const [kasHarian, setKasHarian] = useState(false);
  const [rekapPenerimaan, setRekapPenerimaan] = useState(false);
  const [penambahanPiutang, setPenambahanPiutang] = useState(false);
  const [lapPenjualan, setLapPenjualan] = useState(false);
  const [labaRugiPerUnit, setLabaRugiPerUnit] = useState(false);
  const [biayaTarikPerSurveyor, setBiayaTarikPerSurveyor] = useState(false);
  const [jemputanPerKolektor, setJemputanPerKolektor] = useState(false);
  const [konsumenAktifPerSurveyor, setKonsumenAktifPerSurveyor] =
    useState(false);
  const [point6Bulan, setPoint6Bulan] = useState(false);

  // Akses Piutang
  const [angsuran, setAngsuran] = useState(false);
  const [percepatan, setPercepatan] = useState(false);
  const [angsuranBedaCabang, setAngsuranBedaCabang] = useState(false);
  const [percepatanBedaCabang, setPercepatanBedaCabang] = useState(false);
  const [sp, setSp] = useState(false);
  const [sp1, setSp1] = useState(false);
  const [sp2, setSp2] = useState(false);
  const [st, setSt] = useState(false);
  const [penarikan, setPenarikan] = useState(false);
  const [titip, setTitip] = useState(false);
  const [sweeping, setSweeping] = useState(false);
  const [motorHilang, setMotorHilang] = useState(false);
  const [konsumenJatuhTempo, setKonsumenJatuhTempo] = useState(false);
  const [pointKembali, setPointKembali] = useState(false);
  const [pemutihan, setPemutihan] = useState(false);
  const [approvePemutihan, setApprovePemutihan] = useState(false);

  // Akses Perawatan
  const [biayaPerawatan, setBiayaPerawatan] = useState(false);
  const [rincianBiaya, setRincianBiaya] = useState(false);

  // Akses Finance
  const [kasMasuk, setKasMasuk] = useState(false);
  const [kasKeluar, setKasKeluar] = useState(false);
  const [bankMasuk, setBankMasuk] = useState(false);
  const [bankKeluar, setBankKeluar] = useState(false);

  // Akses Accounting
  const [posting, setPosting] = useState(false);
  const [unposting, setUnposting] = useState(false);
  const [aktivitasBukuBesar, setAktivitasBukuBesar] = useState(false);
  const [labaRugi, setLabaRugi] = useState(false);
  const [neraca, setNeraca] = useState(false);
  const [neracaSaldo, setNeracaSaldo] = useState(false);
  const [tutupPeriode, setTutupPeriode] = useState(false);
  const [gantiPeriode, setGantiPeriode] = useState(false);

  // Akses Utility
  const [profilUser, setProfilUser] = useState(false);
  const [daftarUser, setDaftarUser] = useState(false);
  const [settingAkses, setSettingAkses] = useState(false);

  const [isUserHaveData, setIsUserHaveData] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUser] = useState([]);
  const [usersForDoc, setUsersForDoc] = useState([]);
  const navigate = useNavigate();

  let newNowDate = new Date();
  let nowYear = newNowDate.getFullYear().toString();
  let twoDigitsYear = nowYear.slice(2, 4);
  let tempNoTerakhirUnused = `${twoDigitsYear}000001`;

  const columns = [
    { title: "No", field: "no" },
    { title: "Username", field: "username" },
    { title: "Tipe User", field: "tipeUser" },
    { title: "Periode", field: "periode" },
    { title: "Kode Kwitansi", field: "kodeKwitansi" },
    { title: "No. Terakhir", field: "noTerakhir" },
    { title: "Cabang", field: "cabang" },
  ];

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const tempPosts = users.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (
      val.username.toUpperCase().includes(searchTerm.toUpperCase()) ||
      val.tipeUser.toUpperCase().includes(searchTerm.toUpperCase()) ||
      val.periode.namaPeriode
        .toUpperCase()
        .includes(searchTerm.toUpperCase()) ||
      val.kodeKwitansi.toUpperCase().includes(searchTerm.toUpperCase()) ||
      val.noTerakhir.toUpperCase().includes(searchTerm.toUpperCase()) ||
      val.cabang._id.toUpperCase().includes(searchTerm.toUpperCase()) ||
      val.cabang.namaCabang.toUpperCase().includes(searchTerm.toUpperCase())
    ) {
      return val;
    }
  });
  const currentPosts = tempPosts.slice(indexOfFirstPost, indexOfLastPost);

  const count = Math.ceil(tempPosts.length / PER_PAGE);
  const _DATA = usePagination(users, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    id ? getUserById() : setIsUserExist(false);
  }, [id]);

  useEffect(() => {
    getUsers();
    getUsersForDoc();
  }, []);

  const getUsers = async () => {
    setLoading(true);
    try {
      let response;
      if (user.tipeUser === "OWN") {
        response = await axios.post(`${tempUrl}/users`, {
          tipeAdmin: user.tipeUser,
          id: user._id,
          token: user.token,
        });
      } else {
        response = await axios.post(`${tempUrl}/usersPerCabang`, {
          tipeAdmin: user.tipeUser,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
      }
      setUser(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getUsersForDoc = async () => {
    setLoading(true);
    let response;
    if (user.tipeUser === "OWN") {
      response = await axios.post(`${tempUrl}/users/usersForDoc`, {
        tipeAdmin: user.tipeUser,
        id: user._id,
        token: user.token,
      });
    } else {
      response = await axios.post(`${tempUrl}/users/usersForDocPerCabang`, {
        tipeAdmin: user.tipeUser,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
    }
    setUsersForDoc(response.data);
    setLoading(false);
  };

  const getUserById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/findUser/${id}`, {
        tipeAdmin: user.tipeUser,
        id: user._id,
        token: user.token,
      });
      setIsUserExist(true);
      setUsername(response.data.username);
      setTipeUser(response.data.tipeUser);
      setKodeKolektor(response.data.kodeKolektor);
      setKodeSurveyor(response.data.kodeSurveyor);
      setKodeMarketing(response.data.kodeMarketing);
      setPeriode(response.data.periode.namaPeriode);
      setKodeKwitansi(response.data.kodeKwitansi);
      setNoTerakhir(response.data.noTerakhir);

      if (tempNoTerakhirUnused === response.data.noTerakhir) {
        setIsUserHaveData(true);
      } else {
        setIsUserHaveData(false);
      }

      setKodeCabang(response.data.cabang);
      setCoaKasir(response.data.coaKasir);

      // Akses Master
      setMotor(response.data.akses.motor);
      setArea(response.data.akses.area);
      setBukuBesar(response.data.akses.bukuBesar);
      setDealer(response.data.akses.dealer);
      setKolektor(response.data.akses.kolektor);
      setMarketing(response.data.akses.marketing);
      setPekerjaan(response.data.akses.pekerjaan);
      setSurveyor(response.data.akses.surveyor);
      setLeasing(response.data.akses.leasing);
      setSupplier(response.data.akses.supplier);
      setCabang(response.data.akses.cabang);

      // Akses Pembelian
      setBeli(response.data.akses.beli);

      // Akses Penjualan
      setRegister(response.data.akses.register);
      setSurvey(response.data.akses.survey);
      setReject(response.data.akses.reject);
      setBlacklist(response.data.akses.blacklist);
      setMasterCustomer(response.data.akses.masterCustomer);
      setCustomerPerSurveyor(response.data.akses.customerPerSurveyor);
      setCustomer(response.data.akses.customer);
      setTakeover(response.data.akses.takeover);
      setJual(response.data.akses.jual);

      // Akses Laporan
      setDaftarStok(response.data.akses.daftarStok);
      setTotalPiutang(response.data.akses.totalPiutang);
      setTunggakan(response.data.akses.tunggakan);
      setNpl(response.data.akses.npl);
      setSpkHarusDibuat(response.data.akses.spkHarusDibuat);
      setPenerimaanKas(response.data.akses.penerimaanKas);
      setPenerimaanKasBedaCabang(response.data.akses.penerimaanKasBedaCabang);
      setKasHarian(response.data.akses.kasHarian);
      setRekapPenerimaan(response.data.akses.rekapPenerimaan);
      setPenambahanPiutang(response.data.akses.penambahanPiutang);
      setLapPenjualan(response.data.akses.lapPenjualan);
      setLabaRugiPerUnit(response.data.akses.labaRugiPerUnit);
      setBiayaTarikPerSurveyor(response.data.akses.biayaTarikPerSurveyor);
      setJemputanPerKolektor(response.data.akses.jemputanPerKolektor);
      setKonsumenAktifPerSurveyor(response.data.akses.konsumenAktifPerSurveyor);
      setPoint6Bulan(response.data.akses.point6Bulan);

      // Akses Piutang
      setAngsuran(response.data.akses.angsuran);
      setPercepatan(response.data.akses.percepatan);
      setAngsuranBedaCabang(response.data.akses.angsuranBedaCabang);
      setPercepatanBedaCabang(response.data.akses.percepatanBedaCabang);
      setSp(response.data.akses.sp);
      setSp1(response.data.akses.sp1);
      setSp2(response.data.akses.sp2);
      setSt(response.data.akses.st);
      setPenarikan(response.data.akses.penarikan);
      setTitip(response.data.akses.titip);
      setSweeping(response.data.akses.sweeping);
      setMotorHilang(response.data.akses.motorHilang);
      setKonsumenJatuhTempo(response.data.akses.konsumenJatuhTempo);
      setPointKembali(response.data.akses.pointKembali);
      setPemutihan(response.data.akses.pemutihan);
      setApprovePemutihan(response.data.akses.approvePemutihan);

      // Akses Perawatan
      setBiayaPerawatan(response.data.akses.biayaPerawatan);
      setRincianBiaya(response.data.akses.rincianBiaya);

      // Akses Finance
      setKasMasuk(response.data.akses.kasMasuk);
      setKasKeluar(response.data.akses.kasKeluar);
      setBankMasuk(response.data.akses.bankMasuk);
      setBankKeluar(response.data.akses.bankKeluar);

      // Akses Accounting
      setPosting(response.data.akses.posting);
      setUnposting(response.data.akses.unposting);
      setAktivitasBukuBesar(response.data.akses.aktivitasBukuBesar);
      setLabaRugi(response.data.akses.labaRugi);
      setNeraca(response.data.akses.neraca);
      setNeracaSaldo(response.data.akses.neracaSaldo);
      setTutupPeriode(response.data.akses.tutupPeriode);
      setGantiPeriode(response.data.akses.gantiPeriode);

      // Akses Utility
      setProfilUser(response.data.akses.profilUser);
      setDaftarUser(response.data.akses.daftarUser);
      setSettingAkses(response.data.akses.setting);
    }
    setLoading(false);
  };

  const deleteUser = async (id) => {
    try {
      setLoading(true);
      await axios.post(`${tempUrl}/users/deleteUser/${id}`, {
        tipeAdmin: user.tipeUser,
        id: user._id,
        token: user.token,
      });
      getUsers();
      setUsername("");
      setTipeUser("");
      setKodeKolektor("");
      setKodeSurveyor("");
      setKodeMarketing("");
      setPeriode("");
      setKodeKwitansi("");
      setNoTerakhir("");
      setKodeCabang("");
      setLoading(false);

      if (user._id === id) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      } else {
        navigate("/daftarUser");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar User`, 90, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    let no = 0;
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: usersForDoc.map((col) => {
        no++;
        return { ...col, no };
      }),
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(usersForDoc);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `User`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarUser.xlsx`);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <Typography color="#757575">User</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Daftar User
      </Typography>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button startIcon={<PrintIcon />} onClick={() => downloadPdf()}>
            CETAK
          </Button>
          <Button startIcon={<DownloadIcon />} onClick={() => downloadExcel()}>
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>
      <Box sx={buttonModifierContainer}>
        <Button
          variant="contained"
          color="success"
          sx={{ bgcolor: "success.light", textTransform: "none" }}
          startIcon={<AddCircleOutlineIcon />}
          size="small"
          onClick={() => {
            navigate(`/daftarUser/tambahUser`);
          }}
        >
          Tambah
        </Button>
        {isUserExist && (
          <>
            <ButtonGroup variant="contained">
              <Button
                color="primary"
                startIcon={<EditIcon />}
                sx={{ textTransform: "none" }}
                onClick={() => {
                  navigate(`/daftarUser/${id}/edit`);
                }}
              >
                Ubah
              </Button>
              {isUserHaveData && (
                <Button
                  color="error"
                  startIcon={<DeleteOutlineIcon />}
                  sx={{ textTransform: "none" }}
                  onClick={handleClickOpen}
                >
                  Hapus
                </Button>
              )}
            </ButtonGroup>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {`Yakin ingin menghapus data ${username}?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => deleteUser(id)}>Ok</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
      <Divider sx={dividerStyle} />
      {isUserExist && (
        <>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Username :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={username} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tipe User :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tipeUser} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              {tipeUser === "EKS" && (
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Kode Eksekutor :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={`${kodeKolektor.kodeKolektor} - ${kodeKolektor.namaKolektor}`}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              )}

              {tipeUser === "SUR" && (
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Kode Surveyor :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={`${kodeSurveyor.kodeSurveyor} - ${kodeSurveyor.namaSurveyor}`}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              )}

              {tipeUser === "MAR" && (
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Kode Marketing :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={`${kodeMarketing.kodeMarketing} - ${kodeMarketing.namaMarketing}`}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Periode :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={periode} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Cabang :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={`${kodeCabang._id} - ${kodeCabang.namaCabang}`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Kode Kwitansi :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={kodeKwitansi} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      No Terakhir :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={noTerakhir} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      COA Kasir :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={coaKasir} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
          {user.tipeUser !== "ADM" && (
            <>
              <Typography variant="h5" sx={[labelInput, spacingTop]}>
                Hak Akses User
              </Typography>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Typography variant="p" sx={[spacingTop]}>
                    Master
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={motor} disabled />}
                      label="Motor"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={area} disabled />}
                      label="Area"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={bukuBesar} disabled />}
                      label="Buku Besar"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={dealer} disabled />}
                      label="Dealer"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={kolektor} disabled />}
                      label="Eksekutor"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={marketing} disabled />}
                      label="Marketing"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={pekerjaan} disabled />}
                      label="Pekerjaan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={surveyor} disabled />}
                      label="Surveyor"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={leasing} disabled />}
                      label="Leasing"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={supplier} disabled />}
                      label="Supplier"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={cabang} disabled />}
                      label="Cabang"
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Pembelian
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={beli} disabled />}
                      label="Beli"
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Penjualan
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={register} disabled />}
                      label="Register"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={survey} disabled />}
                      label="Survey"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={reject} disabled />}
                      label="Reject"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={blacklist} disabled />}
                      label="Blacklist"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={masterCustomer} disabled />}
                      label="Master Customer"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={customerPerSurveyor} disabled />
                      }
                      label="Customer Per Surveyor"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={customer} disabled />}
                      label="Customer"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={takeover} disabled />}
                      label="Takeover"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={jual} disabled />}
                      label="Jual"
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Laporan
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={daftarStok} disabled />}
                      label="Daftar Stok"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={totalPiutang} disabled />}
                      label="Total Piutang"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={tunggakan} disabled />}
                      label="Tunggakan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={npl} disabled />}
                      label="NPL"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={spkHarusDibuat} disabled />}
                      label="SPK harus dibuat"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={penerimaanKas} disabled />}
                      label="Penerimaan Kas"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={penerimaanKasBedaCabang} disabled />
                      }
                      label="Penerimaan Kas Beda Cabang"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={kasHarian} disabled />}
                      label="Kas Harian"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={rekapPenerimaan} disabled />}
                      label="Rekap Penerimaan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={penambahanPiutang} disabled />
                      }
                      label="Penambahan Piutang"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapPenjualan} disabled />}
                      label="Lap. Penjualan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={labaRugiPerUnit} disabled />}
                      label="L/R Per Unit"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={biayaTarikPerSurveyor} disabled />
                      }
                      label="Biaya Tarik/Surveyor"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={jemputanPerKolektor} disabled />
                      }
                      label="Jemputan/Kolektor"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={konsumenAktifPerSurveyor} disabled />
                      }
                      label="Konsumen Aktif/Surveyor"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={point6Bulan} disabled />}
                      label="Point 6 Bulan"
                    />
                  </FormGroup>
                </Box>
                <Box sx={[showDataWrapper, secondWrapper]}>
                  <Typography variant="p" sx={[spacingTop]}>
                    Piutang
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={angsuran} disabled />}
                      label="Angsuran"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={percepatan} disabled />}
                      label="Percepatan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={angsuranBedaCabang} disabled />
                      }
                      label="Angsuran Beda Cabang"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={percepatanBedaCabang} disabled />
                      }
                      label="Percepatan Beda Cabang"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sp} disabled />}
                      label="SP"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sp1} disabled />}
                      label="SP1"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sp2} disabled />}
                      label="SP2"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={st} disabled />}
                      label="ST"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={penarikan} disabled />}
                      label="Pelunasan Program"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={titip} disabled />}
                      label="Tarik/Titip"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sweeping} disabled />}
                      label="Sweeping"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={motorHilang} disabled />}
                      label="Motor Hilang"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={konsumenJatuhTempo} disabled />
                      }
                      label="Konsumen Jatuh Tempo"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={pointKembali} disabled />}
                      label="Point Kembali"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={pemutihan} disabled />}
                      label="Pemutihan"
                    />
                  </FormGroup>
                  {user.tipeUser === "OWN" && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox checked={approvePemutihan} disabled />
                        }
                        label="Approve Pemutihan"
                      />
                    </FormGroup>
                  )}
                  <Typography variant="p" sx={[spacingTop]}>
                    Perawatan
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={biayaPerawatan} disabled />}
                      label="Biaya Perawatan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={rincianBiaya} disabled />}
                      label="Rincian Biaya"
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Finance
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={kasMasuk} disabled />}
                      label="Kas Masuk"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={kasKeluar} disabled />}
                      label="Kas Keluar"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={bankMasuk} disabled />}
                      label="Bank Masuk"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={bankKeluar} disabled />}
                      label="Bank Keluar"
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Accounting
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={posting} disabled />}
                      label="Posting"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={unposting} disabled />}
                      label="Unposting"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={aktivitasBukuBesar} disabled />
                      }
                      label="Aktivitas Buku Besar"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={labaRugi} disabled />}
                      label="Laba Rugi"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={neraca} disabled />}
                      label="Neraca"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={neracaSaldo} disabled />}
                      label="Neraca Saldo"
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Utility
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={profilUser} disabled />}
                      label="Profil User"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={daftarUser} disabled />}
                      label="Daftar User"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={tutupPeriode} disabled />}
                      label="Tutup Periode"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={gantiPeriode} disabled />}
                      label="Ganti Periode"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={settingAkses} disabled />}
                      label="Setting"
                    />
                  </FormGroup>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
      <Box sx={searchBarContainer}>
        <SearchBar setSearchTerm={setSearchTerm} />
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disableElevation
        >
          Cari
        </Button>
      </Box>
      <Box sx={tableContainer}>
        <ShowTableUser currentPosts={currentPosts} searchTerm={searchTerm} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={count}
          page={page}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default DaftarUser;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
