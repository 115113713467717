import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  Button,
  Divider,
  Paper,
  Card,
  CardHeader,
  CardActionArea,
  CardMedia,
  CardActions,
  IconButton,
} from "@mui/material";
import Carousel from "react-elastic-carousel";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { KeyOffRounded } from "@mui/icons-material";
import { Form } from "react-bootstrap";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

const UbahFotoSurvey = () => {
  const [validated, setValidated] = useState(false);
  let [gambar, setGambar] = useState(null);
  let [arrayImage, setArrayImage] = useState([]);
  let [arrayImageUrl, setArrayImageUrl] = useState([]);
  let arrayImageSubmitted = [];
  const [invalidImage, setinvalidImage] = useState(null);

  const { user, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const idSurvey = location.pathname.split("/")[3];

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  useEffect(() => {
    getUrlImg();
    getSurveyById();
  }, [arrayImage]);

  const getUrlImg = () => {
    let tempArrayImageUrl = [];
    Object.keys(arrayImage).map(function (key, index) {
      tempArrayImageUrl.push(URL.createObjectURL(arrayImage[key]));
      setArrayImageUrl(tempArrayImageUrl);
    });
  };

  const getSurveyById = async () => {
    if (idSurvey) {
      const response = await axios.post(`${tempUrl}/surveys/${idSurvey}`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setGambar(response.data.gambar);
    }
  };

  const hapusGambar = async (img, i) => {
    if (window.confirm(`Yakin ingin menghapus gambar?`) == false) {
      return;
    }

    let tempGambar = gambar.filter((val) => {
      return val !== img;
    });

    setGambar(tempGambar);

    let image = firebase.storage().refFromURL(img);

    image
      .delete()
      .then(function () {
        console.log("image deleted");
      })
      .catch(function (error) {
        // An error occurred!
        console.log("an error occurred");
      });

    // Delete Gambar
    try {
      await axios.post(`${tempUrl}/deleteFotoSurvey/${idSurvey}`, {
        gambar: tempGambar,
        id: user._id,
        token: user.token,
      });
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      console.log(error);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      for (let tempGambarLama of gambar) {
        arrayImageSubmitted.push(tempGambarLama);
      }

      if (arrayImage.length === 0) {
        setLoading(false);
        navigate(`/daftarSurvey/survey/${idSurvey}`);
      }

      for (let i = 0; i < arrayImage.length; i++) {
        const selectedFile = arrayImage[i];
        if (selectedFile) {
          const storageRef = firebase.storage().ref();
          const fileRef = storageRef.child(
            `${Math.floor(Math.random() * 1000 + 1)}${selectedFile.name}`
          );

          fileRef.put(selectedFile).then((snapshot) => {
            snapshot.ref.getDownloadURL().then(async (downloadURL) => {
              arrayImageSubmitted.push(downloadURL);

              // Update Foto Customer
              await axios.post(`${tempUrl}/uploadFotoSurvey/${idSurvey}`, {
                gambar: arrayImageSubmitted,
                id: user._id,
                token: user.token,
                kodeCabang: user.cabang._id,
              });

              if (i + 1 === arrayImage.length) {
                setLoading(false);
                navigate(`/daftarSurvey/survey/${idSurvey}`);
              }
            });
          });
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Penjualan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Ubah Foto Surveyan
      </Typography>
      <Divider sx={dividerStyle} />
      <Form noValidate validated={validated} onSubmit={submit}>
        <Paper sx={contentContainer} elevation={12}>
          {/* Data Rincian Harga (Input) */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              UPLOAD FOTO
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                {invalidImage !== null ? (
                  <h4 className="error"> {invalidImage} </h4>
                ) : null}
                <label className="text-white">Pilih Gambar :</label>
                <input
                  accept="image/*"
                  type="file"
                  id="select-image"
                  style={{ display: "none" }}
                  onChange={(e) => setArrayImage(e.target.files)}
                  multiple
                />
                <label htmlFor="select-image">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    endIcon={<FileUploadIcon />}
                  >
                    Unggah Gambar
                  </Button>
                </label>
              </Box>
            </Box>
            <Box sx={listImageContainer}>
              {arrayImageUrl &&
                arrayImage &&
                arrayImageUrl.map((key, i) => (
                  <Card sx={imageCard} elevation={10}>
                    <CardActionArea>
                      <CardHeader title={`Gambar`} subheader={`No. ${i + 1}`} />
                      <CardMedia
                        component="img"
                        height="200px"
                        src={key}
                        alt={KeyOffRounded.name}
                      />
                    </CardActionArea>
                  </Card>
                ))}
            </Box>
          </Paper>

          {/* Foto Lama */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              FOTO LAMA
            </Typography>
            <Box sx={showDataContainer}>
              {gambar && (
                <Carousel breakPoints={breakPoints} sx={carouselStyle}>
                  {gambar &&
                    gambar.map((img, i) => (
                      <Card sx={oldImageCard} elevation={10}>
                        <CardActionArea disableRipple>
                          <CardHeader
                            title={`Gambar`}
                            subheader={`No. ${i + 1}`}
                          />
                          <CardMedia
                            component="img"
                            height="100%"
                            src={img}
                            alt="gambar"
                            sx={oldImageCardMedia}
                            onClick={() => {
                              window.open(img, "_blank", "noopener,noreferrer");
                            }}
                          />
                        </CardActionArea>
                        <CardActions sx={oldImageCardAction}>
                          <IconButton aria-label="delete">
                            <DeleteIcon
                              color="error"
                              onClick={() => hapusGambar(img, i)}
                            />
                          </IconButton>
                        </CardActions>
                      </Card>
                    ))}
                </Carousel>
              )}
            </Box>
          </Paper>

          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/daftarSurvey/survey/${idSurvey}`)}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Paper>
      </Form>
      <Divider sx={spacingTop} />
    </Box>
  );
};

export default UbahFotoSurvey;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const colorRed = {
  color: "red",
};

const listImageContainer = {
  display: "flex",
  flexWrap: "wrap",
};

const imageCard = {
  m: "auto",
  mt: 2,
};

const carouselStyle = {
  display: "flex",
  height: "400px",
};

const oldImageCard = {
  m: "auto",
  mt: 2,
  maxWidth: "500px",
  maxHeight: "500px",
  display: "flex",
  flexDirection: "column",
};

const oldImageCardMedia = {
  display: "flex",
  maxHeight: "150px",
};

const oldImageCardAction = {
  display: "flex",
  justifyContent: "center",
  mt: "auto",
};
