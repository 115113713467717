import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Colors } from "../../../constants/styles";
import { Col, Row, Form } from "react-bootstrap";

const UbahSetting = () => {
  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const { user, setting, dispatch } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  // Data Perusahaan
  const [namaPerusahaan, setNamaPerusahaan] = useState(setting.namaPerusahaan);
  const [lokasiPerusahaan, setLokasiPerusahaan] = useState(
    setting.lokasiPerusahaan
  );
  const [kotaPerusahaan, setKotaPerusahaan] = useState(setting.kotaPerusahaan);
  const [lokasiSP, setLokasiSP] = useState(setting.lokasiSP);
  const [noTelpPerusahaan, setNoTelpPerusahaan] = useState(
    setting.noTelpPerusahaan
  );
  const [namaKolektor, setNamaKolektor] = useState(setting.namaKolektor);
  const [namaPemilik, setNamaPemilik] = useState(setting.namaPemilik);
  const [alamatPemilik, setAlamatPemilik] = useState(setting.alamatPemilik);
  const [pekerjaanPemilik, setPekerjaanPemilik] = useState(
    setting.pekerjaanPemilik
  );
  const [kodeKolektor, setKodeKolektor] = useState(setting.kodeKolektor);
  const [biayaTarikSetting, setBiayaTarikSetting] = useState(
    setting.biayaTarikSetting
  );
  const [dendaSetting, setDendaSetting] = useState(setting.dendaSetting);
  const [dendaPersenSetting, setDendaPersenSetting] = useState(
    setting.dendaPersenSetting
  );
  const [jemputanSetting, setJemputanSetting] = useState(
    setting.jemputanSetting
  );
  const [toleransiSetting, setToleransiSetting] = useState(
    setting.toleransiSetting
  );
  const [maxMundurAngsuranSetting, setMaxMundurAngsuranSetting] = useState(
    setting.maxMundurAngsuranSetting
  );
  const [maxPotonganSetting, setMaxPotonganSetting] = useState(
    setting.maxPotonganSetting
  );
  const [passwordPotonganSetting, setPasswordPotonganSetting] = useState(
    setting.passwordPotonganSetting
  );
  const [ppnDipisah, setPpnDipisah] = useState(setting.ppnDipisah);
  const [PPN, setPPN] = useState(setting.PPN);
  const [supplierPenarikan, setSupplierPenarikan] = useState(
    setting.supplierPenarikan
  );
  const [nilaiPointSetting, setNilaiPointSetting] = useState(
    setting.nilaiPointSetting
  );
  const [batasNplSetting, setBatasNplSetting] = useState(
    setting.batasNplSetting
  );
  const [sp1Setting, setSp1Setting] = useState(setting.sp1Setting);
  const [sp2Setting, setSp2Setting] = useState(setting.sp2Setting);

  // Data Group COA
  const [groupAktivaLancar, setGroupAktivaLancar] = useState(
    setting.groupAktivaLancar
  );
  const [groupAktivaTetap, setGroupAktivaTetap] = useState(
    setting.groupAktivaTetap
  );
  const [groupKewajibanHutang, setGroupKewajibanHutang] = useState(
    setting.groupKewajibanHutang
  );
  const [groupModalSaham, setGroupModalSaham] = useState(
    setting.groupModalSaham
  );
  const [groupPendapatan, setGroupPendapatan] = useState(
    setting.groupPendapatan
  );
  const [groupBiayaOperasional, setGroupBiayaOperasional] = useState(
    setting.groupBiayaOperasional
  );
  const [groupPendapatanNonOperasional, setGroupPendapatanNonOperasional] =
    useState(setting.groupPendapatanNonOperasional);
  const [groupBiayaNonOperasional, setGroupBiayaNonOperasional] = useState(
    setting.groupBiayaNonOperasional
  );

  // Data COA
  const [uangTunaiKasir, setUangTunaiKasir] = useState(setting.uangTunaiKasir);
  const [bankJuly, setBankJuly] = useState(setting.bankJuly);
  const [piutangDagang, setPiutangDagang] = useState(setting.piutangDagang);
  const [persediaanMotorBaru, setPersediaanMotorBaru] = useState(
    setting.persediaanMotorBaru
  );
  const [persediaanMotorBekas, setPersediaanMotorBekas] = useState(
    setting.persediaanMotorBekas
  );
  const [persediaanSparepart, setPersediaanSparepart] = useState(
    setting.persediaanSparepart
  );
  const [hutangDagang, setHutangDagang] = useState(setting.hutangDagang);
  const [modalDanSaham, setModalDanSaham] = useState(setting.modalDanSaham);
  const [labaRugiPeriodeBerjalan, setLabaRugiPeriodeBerjalan] = useState(
    setting.labaRugiPeriodeBerjalan
  );
  const [labaRugiDitahan, setLabaRugiDitahan] = useState(
    setting.labaRugiDitahan
  );
  const [penjualanMotor, setPenjualanMotor] = useState(setting.penjualanMotor);
  const [penjualanSparepart, setPenjualanSparepart] = useState(
    setting.penjualanSparepart
  );
  const [potonganPenjualan, setPotonganPenjualan] = useState(
    setting.potonganPenjualan
  );
  const [pendapatanBungaAngsuran, setPendapatanBungaAngsuran] = useState(
    setting.pendapatanBungaAngsuran
  );
  const [pendapatanDenda, setPendapatanDenda] = useState(
    setting.pendapatanDenda
  );
  const [hppMotor, setHppMotor] = useState(setting.hppMotor);
  const [hppSparepart, setHppSparepart] = useState(setting.hppSparepart);
  const [ppnMasukkan, setPpnMasukkan] = useState(setting.ppnMasukkan);
  const [accountPembalance, setAccountPembalance] = useState(
    setting.accountPembalance
  );
  const [coaBank, setCoaBank] = useState(setting.coaBank);
  const [coaKas, setCoaKas] = useState(setting.coaKas);
  const [pendapatanJemputan, setPendapatanJemputan] = useState(
    setting.pendapatanJemputan
  );
  const [pendapatanBiayaTarik, setPendapatanBiayaTarik] = useState(
    setting.pendapatanBiayaTarik
  );

  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const updateUser = async (e) => {
    e.preventDefault();
    let isFailedValidation =
      namaPerusahaan.length === 0 ||
      lokasiPerusahaan.length === 0 ||
      kotaPerusahaan.length === 0 ||
      lokasiSP.length === 0 ||
      noTelpPerusahaan.length === 0 ||
      namaKolektor.length === 0 ||
      namaPemilik.length === 0 ||
      alamatPemilik.length === 0 ||
      pekerjaanPemilik.length === 0 ||
      kodeKolektor.length === 0 ||
      biayaTarikSetting.length === 0 ||
      dendaSetting.length === 0 ||
      dendaPersenSetting.length === 0 ||
      jemputanSetting.length === 0 ||
      toleransiSetting.length === 0 ||
      maxMundurAngsuranSetting.length === 0 ||
      maxPotonganSetting.length === 0 ||
      passwordPotonganSetting.length === 0 ||
      ppnDipisah.length === 0 ||
      PPN.length === 0 ||
      groupAktivaLancar.length === 0 ||
      groupAktivaTetap.length === 0 ||
      groupKewajibanHutang.length === 0 ||
      groupModalSaham.length === 0 ||
      groupPendapatan.length === 0 ||
      groupBiayaOperasional.length === 0 ||
      groupPendapatanNonOperasional.length === 0 ||
      groupBiayaNonOperasional.length === 0 ||
      uangTunaiKasir.length === 0 ||
      bankJuly.length === 0 ||
      piutangDagang.length === 0 ||
      persediaanMotorBaru.length === 0 ||
      persediaanMotorBekas.length === 0 ||
      persediaanSparepart.length === 0 ||
      hutangDagang.length === 0 ||
      modalDanSaham.length === 0 ||
      labaRugiPeriodeBerjalan.length === 0 ||
      labaRugiDitahan.length === 0 ||
      penjualanMotor.length === 0 ||
      penjualanSparepart.length === 0 ||
      potonganPenjualan.length === 0 ||
      pendapatanBungaAngsuran.length === 0 ||
      pendapatanDenda.length === 0 ||
      hppMotor.length === 0 ||
      hppSparepart.length === 0 ||
      ppnMasukkan.length === 0 ||
      accountPembalance.length === 0 ||
      coaBank.length === 0 ||
      coaKas.length === 0 ||
      pendapatanJemputan.length === 0 ||
      pendapatanBiayaTarik.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
    }
    try {
      setLoading(true);
      await axios.post(`${tempUrl}/updateSetting/${setting._id}`, {
        namaPerusahaan,
        lokasiPerusahaan,
        kotaPerusahaan,
        lokasiSP,
        noTelpPerusahaan,
        namaKolektor,
        namaPemilik,
        alamatPemilik,
        pekerjaanPemilik,
        kodeKolektor,
        biayaTarikSetting,
        dendaSetting,
        dendaPersenSetting,
        jemputanSetting,
        toleransiSetting,
        maxPotonganSetting,
        passwordPotonganSetting,
        maxMundurAngsuranSetting,
        ppnDipisah,
        PPN,
        supplierPenarikan,
        nilaiPointSetting,
        batasNplSetting,
        sp1Setting,
        sp2Setting,
        groupAktivaLancar,
        groupAktivaTetap,
        groupKewajibanHutang,
        groupModalSaham,
        groupPendapatan,
        groupBiayaOperasional,
        groupPendapatanNonOperasional,
        groupBiayaNonOperasional,
        uangTunaiKasir,
        bankJuly,
        piutangDagang,
        persediaanMotorBaru,
        persediaanMotorBekas,
        persediaanSparepart,
        hutangDagang,
        modalDanSaham,
        labaRugiPeriodeBerjalan,
        labaRugiDitahan,
        penjualanMotor,
        penjualanSparepart,
        potonganPenjualan,
        pendapatanBungaAngsuran,
        pendapatanDenda,
        hppMotor,
        hppSparepart,
        ppnMasukkan,
        accountPembalance,
        coaBank,
        coaKas,
        pendapatanJemputan,
        pendapatanBiayaTarik,
        id: user._id,
        token: user.token,
      });
      const findSetting = await axios.post(`${tempUrl}/lastSetting`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      const gantiPeriodeUser = await axios.post(
        `${tempUrl}/updateUserThenLogin/${user._id}`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: gantiPeriodeUser.data.details,
        setting: findSetting.data,
      });
      setLoading(false);
      navigate("/setting");
    } catch (error) {
      console.log(error);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Setting</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Ubah Setting
      </Typography>
      <Divider sx={dividerStyle} />

      <Form noValidate validated={validated} onSubmit={updateUser}>
        <Box sx={contentContainer}>
          {/* Data Perusahaan */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA PERUSAHAAN
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nama Perusahaan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={namaPerusahaan}
                          required
                          onChange={(e) =>
                            setNamaPerusahaan(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama Perusahaan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Lokasi Perusahaan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={lokasiPerusahaan}
                          required
                          onChange={(e) =>
                            setLokasiPerusahaan(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Lokasi Perusahaan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Kota Perusahaan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={kotaPerusahaan}
                          required
                          onChange={(e) =>
                            setKotaPerusahaan(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Kota Perusahaan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Lokasi SP :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={lokasiSP}
                          required
                          onChange={(e) =>
                            setLokasiSP(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Lokasi SP harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        No. Telp :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={noTelpPerusahaan}
                          required
                          onChange={(e) =>
                            setNoTelpPerusahaan(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          No. Telp harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nama Eksekutor :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={namaKolektor}
                          required
                          onChange={(e) =>
                            setNamaKolektor(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama Eksekutor harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nama Pemilik :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={namaPemilik}
                          required
                          onChange={(e) =>
                            setNamaPemilik(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama Pemilik harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        PPN Dipisah (true/false) :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={ppnDipisah}
                          required
                          onChange={(e) => setPpnDipisah(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          PPN Dipisah harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Alamat Pemilik :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={alamatPemilik}
                          required
                          onChange={(e) =>
                            setAlamatPemilik(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Alamat Pemilik harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Pekerjaan Pemilik :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={pekerjaanPemilik}
                          required
                          onChange={(e) =>
                            setPekerjaanPemilik(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Pekerjaan Pemilik harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Kode Eksekutor :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={kodeKolektor}
                          required
                          onChange={(e) =>
                            setKodeKolektor(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Kode Eksekutor harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Biaya Tarik :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={biayaTarikSetting}
                          required
                          onChange={(e) =>
                            setBiayaTarikSetting(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Biaya Tarik harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Denda :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={dendaSetting}
                          required
                          onChange={(e) =>
                            setDendaSetting(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Denda harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Denda (%) :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={dendaPersenSetting}
                          required
                          onChange={(e) =>
                            setDendaPersenSetting(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Denda (%) harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Jemputan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={jemputanSetting}
                          drequired
                          onChange={(e) =>
                            setJemputanSetting(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Jemputan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Toleransi :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={toleransiSetting}
                          required
                          onChange={(e) =>
                            setToleransiSetting(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Toleransi harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Max. Mundur Angsuran :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={maxMundurAngsuranSetting}
                          required
                          onChange={(e) =>
                            setMaxMundurAngsuranSetting(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Max. Mundur Angsuran harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Max. Potongan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={maxPotonganSetting}
                          required
                          onChange={(e) =>
                            setMaxPotonganSetting(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Max. Potongan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Password Potongan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={passwordPotonganSetting}
                          required
                          onChange={(e) =>
                            setPasswordPotonganSetting(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Password Potongan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        PPN :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={PPN}
                          required
                          onChange={(e) => setPPN(e.target.value.toUpperCase())}
                        />
                        <Form.Control.Feedback type="invalid">
                          PPN harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Supplier Penarikan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={supplierPenarikan}
                          required
                          onChange={(e) => setSupplierPenarikan(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Supplier Penarikan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nilai Point :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={nilaiPointSetting}
                          required
                          onChange={(e) => setNilaiPointSetting(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Nilai Point harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Batas Npl (hari) :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={batasNplSetting}
                          required
                          onChange={(e) => setBatasNplSetting(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Batas Npl (hari) Point harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        SP1 (hari) :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={sp1Setting}
                          required
                          onChange={(e) => setSp1Setting(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          SP1 (hari) Point harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        SP2 (hari) :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={sp2Setting}
                          required
                          onChange={(e) => setSp2Setting(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          SP2 (hari) Point harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data GROUP COA */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA GROUP COA
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Group Aktiva Lancar :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={groupAktivaLancar}
                          required
                          onChange={(e) =>
                            setGroupAktivaLancar(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Group Aktiva Lancar harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Group Aktiva Tetap :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={groupAktivaTetap}
                          required
                          onChange={(e) =>
                            setGroupAktivaTetap(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Group Aktiva Tetap harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Group Kewajiban Hutang :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={groupKewajibanHutang}
                          required
                          onChange={(e) =>
                            setGroupKewajibanHutang(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Group Kewajiban Hutang harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Group Modal Saham :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={groupModalSaham}
                          required
                          onChange={(e) =>
                            setGroupModalSaham(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Group Modal Saham harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Group Pendapatan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={groupPendapatan}
                          required
                          onChange={(e) =>
                            setGroupPendapatan(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Group Pendapatan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Group Biaya Operasional :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={groupBiayaOperasional}
                          required
                          onChange={(e) =>
                            setGroupBiayaOperasional(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Group Biaya Operasional harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Group Pendapatan Non Operasional :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={groupPendapatanNonOperasional}
                          required
                          onChange={(e) =>
                            setGroupPendapatanNonOperasional(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Group Pendapatan Non Operasional harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Group Biaya Non Operasional :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={groupBiayaNonOperasional}
                          required
                          onChange={(e) =>
                            setGroupBiayaNonOperasional(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Group Biaya Non Operasional harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data COA */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA COA
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Uang Tunai Kasir :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={uangTunaiKasir}
                          required
                          onChange={(e) =>
                            setUangTunaiKasir(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Uang Tunai Kasir harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Bank July :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={bankJuly}
                          required
                          onChange={(e) =>
                            setBankJuly(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Bank July harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Piutang Dagang :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={piutangDagang}
                          required
                          onChange={(e) =>
                            setPiutangDagang(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Piutang Dagang harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Persediaan Motor Baru :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={persediaanMotorBaru}
                          required
                          onChange={(e) =>
                            setPersediaanMotorBaru(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Persediaan Motor Baru harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Persediaan Motor Bekas :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={persediaanMotorBekas}
                          required
                          onChange={(e) =>
                            setPersediaanMotorBekas(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Persediaan Motor Bekas harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Persediaan Sparepart :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={persediaanSparepart}
                          required
                          onChange={(e) =>
                            setPersediaanSparepart(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Persediaan Sparepart harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Modal dan Saham :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={modalDanSaham}
                          required
                          onChange={(e) =>
                            setModalDanSaham(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Modal dan Saham harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Laba Rugi Periode Berjalan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={labaRugiPeriodeBerjalan}
                          required
                          onChange={(e) =>
                            setLabaRugiPeriodeBerjalan(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Laba Rugi Periode Berjalan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Laba Rugi Ditahan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={labaRugiDitahan}
                          required
                          onChange={(e) =>
                            setLabaRugiDitahan(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Laba Rugi Ditahan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Penjualan Motor :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={penjualanMotor}
                          required
                          onChange={(e) =>
                            setPenjualanMotor(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Penjualan Motor harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Penjualan Sparepart :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={penjualanSparepart}
                          required
                          onChange={(e) =>
                            setPenjualanSparepart(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Penjualan Sparepart harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Potongan Penjualan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={potonganPenjualan}
                          required
                          onChange={(e) =>
                            setPotonganPenjualan(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Potongan Penjualan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Pendapatan Bunga Angsuran :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={pendapatanBungaAngsuran}
                          required
                          onChange={(e) =>
                            setPendapatanBungaAngsuran(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Pendapatan Bunga Angsuran harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Pendapatan Denda :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={pendapatanDenda}
                          required
                          onChange={(e) =>
                            setPendapatanDenda(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Pendapatan Denda harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        HPP Motor :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={hppMotor}
                          required
                          onChange={(e) =>
                            setHppMotor(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          HPP Motor harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        HPP Sparepart :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={hppSparepart}
                          required
                          onChange={(e) =>
                            setHppSparepart(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          HPP Sparepart harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Account Pembalance :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={accountPembalance}
                          required
                          onChange={(e) =>
                            setAccountPembalance(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Account Pembalance harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        COA Bank :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={coaBank}
                          required
                          onChange={(e) =>
                            setCoaBank(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          COA Bank harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        COA Kas :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={coaKas}
                          required
                          onChange={(e) =>
                            setCoaKas(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          COA Kas harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Pendapatan Jemputan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={pendapatanJemputan}
                          required
                          onChange={(e) =>
                            setPendapatanJemputan(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Pendapatan Jemputan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Pendapatan Biaya Tarik :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={pendapatanBiayaTarik}
                          required
                          onChange={(e) =>
                            setPendapatanBiayaTarik(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Pendapatan Biaya Tarik harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/setting")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<EditIcon />} type="submit">
              Ubah
            </Button>
          </Box>

          <Divider sx={dividerStyle} />
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default UbahSetting;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const alertBox = {
  width: "100%",
};
