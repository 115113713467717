import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  ShowTableDashboardCabangNpl,
  ShowTableDashboardCabangNplKuantitas,
  ShowTableDashboardCabangNplDetail,
} from "../../../components/ShowTable";
import { Box, Typography, Divider, Paper } from "@mui/material";
import { Chart } from "react-google-charts";

export const optionsPenjualanPerCabang = {
  hAxis: {
    title: "Daftar Cabang",
  },
  vAxis: {
    title: "Jumlah Penjualan",
    minValue: 0,
  },
};

export const optionsAngsuranAktif = {
  hAxis: {
    title: "Daftar Cabang",
  },
  vAxis: {
    title: "Total Aktif",
    minValue: 0,
  },
};

export const optionsAngsuranAktifKuantitas = {
  hAxis: {
    title: "Daftar Cabang",
  },
  vAxis: {
    title: "Kuantitas Aktif",
    minValue: 0,
  },
};

export const optionsJatuhTempo = {
  hAxis: {
    title: "Daftar Cabang",
  },
  vAxis: {
    title: "Total Jatuh Tempo",
    minValue: 0,
  },
};

export const optionsJatuhTempoKuantitas = {
  hAxis: {
    title: "Daftar Cabang",
  },
  vAxis: {
    title: "Kuantitas Jatuh Tempo",
    minValue: 0,
  },
};

export const optionsLabaRugi = {
  hAxis: {
    title: "Daftar Cabang",
  },
  vAxis: {
    title: "Total Laba Rugi",
    minValue: 0,
  },
};

export const optionsAngsuranTotal = {
  hAxis: {
    title: "Daftar Cabang",
  },
  vAxis: {
    title: "Total Angsuran Total",
    minValue: 0,
  },
};

export const optionsAngsuranBunga = {
  hAxis: {
    title: "Daftar Cabang",
  },
  vAxis: {
    title: "Total Angsuran Bunga",
    minValue: 0,
  },
};

const DashboardOwner = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  let tempDate = new Date();
  const [todayDate, setTodayDate] = useState(
    `${tempDate.getFullYear()}-${tempDate.getMonth() + 1}-${tempDate.getDate()}`
  );
  const [dataBarLabaRugiBulan, setDataBarLabaRugiBulan] = useState([]);
  const [dataBarAngsuranTotalBulan, setDataBarAngsuranTotalBulan] = useState(
    []
  );
  const [dataBarAngsuranBungaBulan, setDataBarAngsuranBungaBulan] = useState(
    []
  );
  const [dataBarAngsuranAktif, setDataBarAngsuranAktif] = useState([]);
  const [dataBarAngsuranAktifKuantitas, setDataBarAngsuranAktifKuantitas] =
    useState([]);
  const [dataBarJatuhTempo, setDataBarJatuhTempo] = useState([]);
  const [dataBarJatuhTempoKuantitas, setDataBarJatuhTempoKuantitas] = useState(
    []
  );
  const [dataBarNpl, setDataBarNpl] = useState([]);
  const [dataBarNplSemua, setDataBarNplSemua] = useState([]);
  const [dataBarNplKuantitas, setDataBarNplKuantitas] = useState([]);
  const [dataBarNplSemuaKuantitas, setDataBarNplSemuaKuantitas] = useState([]);
  const [dataBarNplDetail, setDataBarNplDetail] = useState([]);

  const [totalSatuBulanNumberNpl, setTotalSatuBulanNumberNpl] = useState(0);
  const [totalDuaBulanNumberNpl, setTotalDuaBulanNumberNpl] = useState(0);
  const [totalTigaBulanNumberNpl, setTotalTigaBulanNumberNpl] = useState(0);
  const [
    totalLebihDariTigaBulanNumberNpl,
    setTotalLebihDariTigaBulanNumberNpl,
  ] = useState(0);
  const [totalTotalNumberNpl, setTotalTotalNumberNpl] = useState(0);

  const [
    totalSatuBulanNumberNplKuantitas,
    setTotalSatuBulanNumberNplKuantitas,
  ] = useState(0);
  const [totalDuaBulanNumberNplKuantitas, setTotalDuaBulanNumberNplKuantitas] =
    useState(0);
  const [
    totalTigaBulanNumberNplKuantitas,
    setTotalTigaBulanNumberNplKuantitas,
  ] = useState(0);
  const [
    totalLebihDariTigaBulanNumberNplKuantitas,
    setTotalLebihDariTigaBulanNumberNplKuantitas,
  ] = useState(0);
  const [totalTotalNumberNplKuantitas, setTotalTotalNumberNplKuantitas] =
    useState(0);

  const [
    dataBarPerbandinganPenjualanBulan,
    setDataBarPerbandinganPenjualanBulan,
  ] = useState([]);
  const [namaPeriodeLalu, setNamaPeriodeLalu] = useState("");
  const [
    loadingPerbandinganPenjualanSemuaCabangBulan,
    setLoadingPerbandinganPenjualanSemuaCabangBulan,
  ] = useState(false);
  const [
    loadingNeracaSaldoLabaRugiSemuaCabangBulan,
    setLoadingNeracaSaldoLabaRugiSemuaCabangBulan,
  ] = useState(false);
  const [
    loadingAngsuranForPenerimaanTotalSemuaCabang,
    setLoadingAngsuranForPenerimaanTotalSemuaCabang,
  ] = useState(false);
  const [
    loadingAngsuranForPenerimaanBungaSemuaCabang,
    setLoadingAngsuranForPenerimaanBungaSemuaCabang,
  ] = useState(false);
  const [loadingLaporanAngsuranAktif, setLoadingLaporanAngsuranAktif] =
    useState(false);
  const [
    loadingLaporanAngsuranAktifKuantitas,
    setLoadingLaporanAngsuranAktifKuantitas,
  ] = useState(false);
  const [loadingLaporanJatuhTempo, setLoadingLaporanJatuhTempo] =
    useState(false);
  const [
    loadingLaporanJatuhTempoKuantitas,
    setLoadingLaporanJatuhTempoKuantitas,
  ] = useState(false);
  const [loadingLaporanNpl, setLoadingLaporanNpl] = useState(false);
  const [loadingLaporanNplKuantitas, setLoadingLaporanNplKuantitas] =
    useState(false);
  const [loadingLaporanNplDetail, setLoadingLaporanNplDetail] = useState(false);

  function subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
    return date;
  }

  useEffect(() => {
    getBulanLalu();
    getPerbandinganPenjualanSemuaCabangBulan();
    getNeracaSaldoLabaRugiSemuaCabangBulan();
    getAngsuranForPenerimaanTotalSemuaCabang();
    getAngsuranForPenerimaanBungaSemuaCabang();
    getLaporanAngsuranAktif();
    getLaporanAngsuranAktifKuantitas();
    getLaporanJatuhTempo();
    getLaporanJatuhTempoKuantitas();
    getJualsForDashboardSemuaCabangNpl();
    getJualsKuantitasAllForDashboardSemuaCabangNpl();
    getJualsForDashboardSemuaCabangNplDetail();
  }, []);

  const getPerbandinganPenjualanSemuaCabangBulan = async () => {
    setLoadingPerbandinganPenjualanSemuaCabangBulan(true);
    try {
      let tempPerbandinganPenjualanSemuaCabang = [];
      const newBulan = new Date(user.periode.periodeAwal);
      const minOneBulan = subtractMonths(newBulan, 1);
      let dariTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        minOneBulan.getDate();

      var lastday = function (year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();
      };

      let sampaiTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        lastday(minOneBulan.getFullYear(), minOneBulan.getMonth());

      let kodeCabangDiKunci = "";

      for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
        if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
          kodeCabangDiKunci =
            setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
        }
      }

      const jualSemuaCabangLalu = await axios.post(
        `${tempUrl}/jualsForLaporanSemuaCabangRebuild`,
        {
          dariTgl,
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );

      const jualSemuaCabangIni = await axios.post(
        `${tempUrl}/jualsForLaporanSemuaCabangRebuild`,
        {
          dariTgl: user.periode.periodeAwal,
          sampaiTgl: user.periode.periodeAkhir,
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );

      for (let i = 0; i < jualSemuaCabangLalu.data.length; i++) {
        for (let j = 0; j < jualSemuaCabangIni.data.length; j++) {
          if (
            jualSemuaCabangLalu.data[i].cabang ===
            jualSemuaCabangIni.data[j].cabang
          ) {
            let objectJualSemuaCabang = {
              tanggal: jualSemuaCabangLalu.data[i].tanggal,
              namaCabang: jualSemuaCabangLalu.data[i].namaCabang,
              cabang: jualSemuaCabangLalu.data[i].cabang,
              countLalu: jualSemuaCabangLalu.data[j].count,
              count: jualSemuaCabangIni.data[i].count,
            };
            tempPerbandinganPenjualanSemuaCabang.push(objectJualSemuaCabang);
          }
        }
      }

      let tempDataBarChart = [
        ["Total Jual Per Cabang", "Bulan Lalu", "Bulan Ini"],
      ];

      for (let i = 0; i < tempPerbandinganPenjualanSemuaCabang.length; i++) {
        let tempTotalSuara = [
          `${tempPerbandinganPenjualanSemuaCabang[i].namaCabang}`,
          tempPerbandinganPenjualanSemuaCabang[i].countLalu,
          tempPerbandinganPenjualanSemuaCabang[i].count,
        ];
        tempDataBarChart.push(tempTotalSuara);
      }
      setDataBarPerbandinganPenjualanBulan(tempDataBarChart);
    } catch (e) {
      console.log(e);
    }
    setLoadingPerbandinganPenjualanSemuaCabangBulan(false);
  };

  const getNeracaSaldoLabaRugiSemuaCabangBulan = async () => {
    setLoadingNeracaSaldoLabaRugiSemuaCabangBulan(true);
    try {
      let tempPerbandinganLabaRugiSemuaCabang = [];
      const newBulan = new Date(user.periode.periodeAwal);
      const minOneBulan = subtractMonths(newBulan, 1);
      let dariTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        minOneBulan.getDate();

      var lastday = function (year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();
      };

      let sampaiTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        lastday(minOneBulan.getFullYear(), minOneBulan.getMonth());

      let kodeCabangDiKunci = "";

      for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
        if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
          kodeCabangDiKunci =
            setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
        }
      }

      const labaRugiSemuaCabangLalu = await axios.post(
        `${tempUrl}/neracaSaldoLabaRugiSemuaCabangRebuild`,
        {
          labaRugiPeriodeBerjalan: setting.labaRugiPeriodeBerjalan,
          dariTgl,
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );

      const labaRugiSemuaCabangIni = await axios.post(
        `${tempUrl}/neracaSaldoLabaRugiSemuaCabangRebuild`,
        {
          labaRugiPeriodeBerjalan: setting.labaRugiPeriodeBerjalan,
          dariTgl: user.periode.periodeAwal,
          sampaiTgl: user.periode.periodeAkhir,
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );

      for (let i = 0; i < labaRugiSemuaCabangLalu.data.length; i++) {
        for (let j = 0; j < labaRugiSemuaCabangIni.data.length; j++) {
          if (
            labaRugiSemuaCabangLalu.data[i].cabang ===
            labaRugiSemuaCabangIni.data[j].cabang
          ) {
            let objectLabaRugiSemuaCabang = {
              namaCabang: labaRugiSemuaCabangLalu.data[i].namaCabang,
              cabang: labaRugiSemuaCabangLalu.data[i].cabang,
              countLalu: labaRugiSemuaCabangLalu.data[j].count,
              count: labaRugiSemuaCabangIni.data[i].count,
            };
            tempPerbandinganLabaRugiSemuaCabang.push(objectLabaRugiSemuaCabang);
          }
        }
      }

      let tempDataBarChart = [
        ["Total Laba Rugi per Cabang", "Bulan Lalu", "Bulan Ini"],
      ];

      for (let i = 0; i < tempPerbandinganLabaRugiSemuaCabang.length; i++) {
        let tempTotalSuara = [
          `${tempPerbandinganLabaRugiSemuaCabang[i].namaCabang}`,
          tempPerbandinganLabaRugiSemuaCabang[i].countLalu,
          tempPerbandinganLabaRugiSemuaCabang[i].count,
        ];
        tempDataBarChart.push(tempTotalSuara);
      }
      setDataBarLabaRugiBulan(tempDataBarChart);
    } catch (e) {
      console.log(e);
    }
    setLoadingNeracaSaldoLabaRugiSemuaCabangBulan(false);
  };

  const getAngsuranForPenerimaanTotalSemuaCabang = async () => {
    setLoadingAngsuranForPenerimaanTotalSemuaCabang(true);
    try {
      let tempPerbandinganAngsuranTotalSemuaCabang = [];
      const newBulan = new Date(user.periode.periodeAwal);
      const minOneBulan = subtractMonths(newBulan, 1);
      let dariTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        minOneBulan.getDate();

      var lastday = function (year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();
      };

      let sampaiTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        lastday(minOneBulan.getFullYear(), minOneBulan.getMonth());

      let kodeCabangDiKunci = "";

      for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
        if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
          kodeCabangDiKunci =
            setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
        }
      }

      const angsuranTotalSemuaCabangLalu = await axios.post(
        `${tempUrl}/penerimaansTotalSemuaCabangRebuild`,
        {
          dariTgl,
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );

      const angsuranTotalSemuaCabangIni = await axios.post(
        `${tempUrl}/penerimaansTotalSemuaCabangRebuild`,
        {
          dariTgl: user.periode.periodeAwal,
          sampaiTgl: user.periode.periodeAkhir,
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );

      for (let i = 0; i < angsuranTotalSemuaCabangLalu.data.length; i++) {
        for (let j = 0; j < angsuranTotalSemuaCabangIni.data.length; j++) {
          if (
            angsuranTotalSemuaCabangLalu.data[i].cabang ===
            angsuranTotalSemuaCabangIni.data[j].cabang
          ) {
            let objectAngsuranTotalSemuaCabang = {
              namaCabang: angsuranTotalSemuaCabangIni.data[i].namaCabang,
              cabang: angsuranTotalSemuaCabangLalu.data[i].cabang,
              countLalu: angsuranTotalSemuaCabangLalu.data[j].count,
              count: angsuranTotalSemuaCabangIni.data[i].count,
            };
            tempPerbandinganAngsuranTotalSemuaCabang.push(
              objectAngsuranTotalSemuaCabang
            );
          }
        }
      }

      let tempDataBarChart = [
        ["Total Penerimaan Angsuran per Cabang", "Bulan Lalu", "Bulan Ini"],
      ];

      for (
        let i = 0;
        i < tempPerbandinganAngsuranTotalSemuaCabang.length;
        i++
      ) {
        let tempTotalSuara = [
          `${tempPerbandinganAngsuranTotalSemuaCabang[i].namaCabang}`,
          tempPerbandinganAngsuranTotalSemuaCabang[i].countLalu,
          tempPerbandinganAngsuranTotalSemuaCabang[i].count,
        ];
        tempDataBarChart.push(tempTotalSuara);
      }
      setDataBarAngsuranTotalBulan(tempDataBarChart);
    } catch (e) {
      console.log(e);
    }
    setLoadingAngsuranForPenerimaanTotalSemuaCabang(false);
  };

  const getAngsuranForPenerimaanBungaSemuaCabang = async () => {
    setLoadingAngsuranForPenerimaanBungaSemuaCabang(true);
    try {
      let tempPerbandinganAngsuranTotalSemuaCabang = [];
      const newBulan = new Date(user.periode.periodeAwal);
      const minOneBulan = subtractMonths(newBulan, 1);
      let dariTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        minOneBulan.getDate();

      var lastday = function (year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();
      };

      let sampaiTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        lastday(minOneBulan.getFullYear(), minOneBulan.getMonth());

      let kodeCabangDiKunci = "";

      for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
        if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
          kodeCabangDiKunci =
            setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
        }
      }

      const angsuranTotalSemuaCabangLalu = await axios.post(
        `${tempUrl}/penerimaansBungaSemuaCabangRebuild`,
        {
          dariTgl,
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );

      const angsuranTotalSemuaCabangIni = await axios.post(
        `${tempUrl}/penerimaansBungaSemuaCabangRebuild`,
        {
          dariTgl: user.periode.periodeAwal,
          sampaiTgl: user.periode.periodeAkhir,
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );

      for (let i = 0; i < angsuranTotalSemuaCabangLalu.data.length; i++) {
        for (let j = 0; j < angsuranTotalSemuaCabangIni.data.length; j++) {
          if (
            angsuranTotalSemuaCabangLalu.data[i].cabang ===
            angsuranTotalSemuaCabangIni.data[j].cabang
          ) {
            let objectAngsuranTotalSemuaCabang = {
              namaCabang: angsuranTotalSemuaCabangIni.data[i].namaCabang,
              cabang: angsuranTotalSemuaCabangLalu.data[i].cabang,
              countLalu: angsuranTotalSemuaCabangLalu.data[j].count,
              count: angsuranTotalSemuaCabangIni.data[i].count,
            };
            tempPerbandinganAngsuranTotalSemuaCabang.push(
              objectAngsuranTotalSemuaCabang
            );
          }
        }
      }

      let tempDataBarChart = [
        ["Total Penerimaan Angsuran per Cabang", "Bulan Lalu", "Bulan Ini"],
      ];

      for (
        let i = 0;
        i < tempPerbandinganAngsuranTotalSemuaCabang.length;
        i++
      ) {
        let tempTotalSuara = [
          `${tempPerbandinganAngsuranTotalSemuaCabang[i].namaCabang}`,
          tempPerbandinganAngsuranTotalSemuaCabang[i].countLalu,
          tempPerbandinganAngsuranTotalSemuaCabang[i].count,
        ];
        tempDataBarChart.push(tempTotalSuara);
      }
      setDataBarAngsuranBungaBulan(tempDataBarChart);
    } catch (e) {
      console.log(e);
    }
    setLoadingAngsuranForPenerimaanBungaSemuaCabang(false);
  };

  const getBulanLalu = () => {
    try {
      let tempDateName;
      const newBulan = new Date(user.periode.periodeAwal);
      const minOneBulan = subtractMonths(newBulan, 1);
      switch (minOneBulan.getMonth() + 1) {
        case 1:
          tempDateName = "JANUARI";
          break;
        case 2:
          tempDateName = "FEBRUARI";
          break;
        case 3:
          tempDateName = "MARET";
          break;
        case 4:
          tempDateName = "APRIL";
          break;
        case 5:
          tempDateName = "MEI";
          break;
        case 6:
          tempDateName = "JUNI";
          break;
        case 7:
          tempDateName = "JULI";
          break;
        case 8:
          tempDateName = "AGUSTUS";
          break;
        case 9:
          tempDateName = "SEPTEMBER";
          break;
        case 10:
          tempDateName = "OKTOBER";
          break;
        case 11:
          tempDateName = "NOVEMBER";
          break;
        case 12:
          tempDateName = "DESEMBER";
          break;
        default:
          break;
      }
      setNamaPeriodeLalu(`${tempDateName} ${minOneBulan.getFullYear()}`);
    } catch (e) {
      console.log(e);
    }
  };

  const getLaporanAngsuranAktif = async () => {
    setLoadingLaporanAngsuranAktif(true);
    try {
      let kodeCabangDiKunci = "";

      for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
        if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
          kodeCabangDiKunci =
            setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
        }
      }

      const angsuranAktif = await axios.post(
        `${tempUrl}/jualsForAngsuranAktifRebuild`,
        {
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );
      let tempDataBarChart = [["Angsuran Aktif", "Total Aktif"]];

      if (angsuranAktif.data.length > 0) {
        // Angsuran Aktif Exist
        for (let i = 0; i < angsuranAktif.data.length; i++) {
          let tempAngsuranAktif = [
            `${angsuranAktif.data[i].namaCabang}`,
            angsuranAktif.data[i].count,
          ];
          tempDataBarChart.push(tempAngsuranAktif);
        }
        setDataBarAngsuranAktif(tempDataBarChart);
      } else {
        // Angsuran Aktif Not Exist
        const allCabang = await axios.post(`${tempUrl}/cabangsExclude`, {
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        });
        for (let i = 0; i < allCabang.data.length; i++) {
          let tempAngsuranAktif = [`${allCabang.data[i].namaCabang}`, 0];
          tempDataBarChart.push(tempAngsuranAktif);
        }
        setDataBarAngsuranAktif(tempDataBarChart);
      }
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanAngsuranAktif(false);
  };

  const getLaporanAngsuranAktifKuantitas = async () => {
    setLoadingLaporanAngsuranAktifKuantitas(true);
    try {
      let kodeCabangDiKunci = "";

      for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
        if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
          kodeCabangDiKunci =
            setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
        }
      }

      const angsuranAktif = await axios.post(
        `${tempUrl}/jualsForAngsuranAktifKuantitasRebuild`,
        {
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );
      let tempDataBarChart = [["Angsuran Aktif", "Total Aktif"]];

      if (angsuranAktif.data.length > 0) {
        // Angsuran Aktif Exist
        for (let i = 0; i < angsuranAktif.data.length; i++) {
          let tempAngsuranAktif = [
            `${angsuranAktif.data[i].namaCabang}`,
            angsuranAktif.data[i].count,
          ];
          tempDataBarChart.push(tempAngsuranAktif);
        }
        setDataBarAngsuranAktifKuantitas(tempDataBarChart);
      } else {
        // Angsuran Aktif Not Exist
        const allCabang = await axios.post(`${tempUrl}/cabangsExclude`, {
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        });
        for (let i = 0; i < allCabang.data.length; i++) {
          let tempAngsuranAktif = [`${allCabang.data[i].namaCabang}`, 0];
          tempDataBarChart.push(tempAngsuranAktif);
        }
        setDataBarAngsuranAktifKuantitas(tempDataBarChart);
      }
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanAngsuranAktifKuantitas(false);
  };

  const getLaporanJatuhTempo = async () => {
    setLoadingLaporanJatuhTempo(true);
    try {
      let kodeCabangDiKunci = "";

      for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
        if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
          kodeCabangDiKunci =
            setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
        }
      }

      const angsuranJatuhTempo = await axios.post(
        `${tempUrl}/jualsJatuhTempoRebuild`,
        {
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );
      let tempDataBarChart = [["Jatuh Tempo", "Total Jatuh Tempo"]];

      if (angsuranJatuhTempo.data.length > 0) {
        // Jatuh Tempo Exist
        for (let i = 0; i < angsuranJatuhTempo.data.length; i++) {
          let tempJatuhTempo = [
            `${angsuranJatuhTempo.data[i].namaCabang}`,
            angsuranJatuhTempo.data[i].count,
          ];
          tempDataBarChart.push(tempJatuhTempo);
        }
        setDataBarJatuhTempo(tempDataBarChart);
      } else {
        // Jatuh Tempo Not Exist
        const allCabang = await axios.post(`${tempUrl}/cabangsExclude`, {
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        });
        for (let i = 0; i < allCabang.data.length; i++) {
          let tempJatuhTempo = [`${allCabang.data[i].namaCabang}`, 0];
          tempDataBarChart.push(tempJatuhTempo);
        }
        setDataBarJatuhTempo(tempDataBarChart);
      }
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanJatuhTempo(false);
  };

  const getLaporanJatuhTempoKuantitas = async () => {
    setLoadingLaporanJatuhTempoKuantitas(true);
    try {
      let kodeCabangDiKunci = "";

      for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
        if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
          kodeCabangDiKunci =
            setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
        }
      }

      const angsuranJatuhTempo = await axios.post(
        `${tempUrl}/jualsJatuhTempoKuantitasRebuild`,
        {
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );
      let tempDataBarChart = [["Jatuh Tempo", "Total Jatuh Tempo"]];

      if (angsuranJatuhTempo.data.length > 0) {
        // Jatuh Tempo Exist
        for (let i = 0; i < angsuranJatuhTempo.data.length; i++) {
          let tempJatuhTempo = [
            `${angsuranJatuhTempo.data[i].namaCabang}`,
            angsuranJatuhTempo.data[i].count,
          ];
          tempDataBarChart.push(tempJatuhTempo);
        }
        setDataBarJatuhTempoKuantitas(tempDataBarChart);
      } else {
        // Jatuh Tempo Not Exist
        const allCabang = await axios.post(`${tempUrl}/cabangsExclude`, {
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        });
        for (let i = 0; i < allCabang.data.length; i++) {
          let tempJatuhTempo = [`${allCabang.data[i].namaCabang}`, 0];
          tempDataBarChart.push(tempJatuhTempo);
        }
        setDataBarJatuhTempoKuantitas(tempDataBarChart);
      }
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanJatuhTempoKuantitas(false);
  };

  const getJualsForDashboardSemuaCabangNpl = async () => {
    setLoadingLaporanNpl(true);
    try {
      let kodeCabangDiKunci = "";

      for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
        if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
          kodeCabangDiKunci =
            setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
        }
      }

      const allJualsTunggakkan = await axios.post(
        `${tempUrl}/jualsForDashboardSemuaCabangNpl`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );
      setDataBarNpl(allJualsTunggakkan.data);

      const allJuals = await axios.post(
        `${tempUrl}/jualsAllForDashboardSemuaCabangNpl`,
        {
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );
      setDataBarNplSemua(allJuals.data);

      let tempTotalSatuBulanNumberNpl =
        (allJualsTunggakkan.data[0].totalSatuBulanNumber /
          allJuals.data[0].totalSatuBulanNumber) *
        100;
      let tempTotalDuaBulanNumber =
        (allJualsTunggakkan.data[0].totalDuaBulanNumber /
          allJuals.data[0].totalDuaBulanNumber) *
        100;
      let tempTotalTigaBulanNumber =
        (allJualsTunggakkan.data[0].totalTigaBulanNumber /
          allJuals.data[0].totalTigaBulanNumber) *
        100;
      let tempTotalLebihDariTigaBulanNumber =
        (allJualsTunggakkan.data[0].totalLebihDariTigaBulanNumber /
          allJuals.data[0].totalLebihDariTigaBulanNumber) *
        100;
      let tempTotalNumber =
        (allJualsTunggakkan.data[0].totalTotalNumber /
          allJuals.data[0].totalTotalNumber) *
        100;

      setTotalSatuBulanNumberNpl(tempTotalSatuBulanNumberNpl);
      setTotalDuaBulanNumberNpl(tempTotalDuaBulanNumber);
      setTotalTigaBulanNumberNpl(tempTotalTigaBulanNumber);
      setTotalLebihDariTigaBulanNumberNpl(tempTotalLebihDariTigaBulanNumber);
      setTotalTotalNumberNpl(tempTotalNumber);
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanNpl(false);
  };

  const getJualsKuantitasAllForDashboardSemuaCabangNpl = async () => {
    setLoadingLaporanNplKuantitas(true);
    try {
      let kodeCabangDiKunci = "";

      for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
        if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
          kodeCabangDiKunci =
            setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
        }
      }

      const allJualsTunggakkan = await axios.post(
        `${tempUrl}/jualsKuantitasForDashboardSemuaCabangNpl`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );
      setDataBarNplKuantitas(allJualsTunggakkan.data);

      const allJuals = await axios.post(
        `${tempUrl}/jualsKuantitasAllForDashboardSemuaCabangNpl`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );
      setDataBarNplSemuaKuantitas(allJuals.data);

      let tempTotalSatuBulanNumberNpl =
        (allJualsTunggakkan.data[0].totalSatuBulanNumber /
          allJuals.data[0].totalSatuBulanNumber) *
        100;
      let tempTotalDuaBulanNumber =
        (allJualsTunggakkan.data[0].totalDuaBulanNumber /
          allJuals.data[0].totalDuaBulanNumber) *
        100;
      let tempTotalTigaBulanNumber =
        (allJualsTunggakkan.data[0].totalTigaBulanNumber /
          allJuals.data[0].totalTigaBulanNumber) *
        100;
      let tempTotalLebihDariTigaBulanNumber =
        (allJualsTunggakkan.data[0].totalLebihDariTigaBulanNumber /
          allJuals.data[0].totalLebihDariTigaBulanNumber) *
        100;
      let tempTotalNumber =
        (allJualsTunggakkan.data[0].totalTotalNumber /
          allJuals.data[0].totalTotalNumber) *
        100;

      setTotalSatuBulanNumberNplKuantitas(tempTotalSatuBulanNumberNpl);
      setTotalDuaBulanNumberNplKuantitas(tempTotalDuaBulanNumber);
      setTotalTigaBulanNumberNplKuantitas(tempTotalTigaBulanNumber);
      setTotalLebihDariTigaBulanNumberNplKuantitas(
        tempTotalLebihDariTigaBulanNumber
      );
      setTotalTotalNumberNplKuantitas(tempTotalNumber);
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanNplKuantitas(false);
  };

  const getJualsForDashboardSemuaCabangNplDetail = async () => {
    setLoadingLaporanNplDetail(true);
    try {
      let kodeCabangDiKunci = "";

      for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
        if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
          kodeCabangDiKunci =
            setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
        }
      }

      const allJualsTunggakkan = await axios.post(
        `${tempUrl}/jualsForDashboardSemuaCabangNplDetail`,
        {
          id: user._id,
          token: user.token,
          batasNplSetting: setting.batasNplSetting,
          kodeCabang: user.cabang._id,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );

      const allJuals = await axios.post(
        `${tempUrl}/jualsAllForDashboardSemuaCabangNplDetail`,
        {
          id: user._id,
          token: user.token,
          batasNplSetting: setting.batasNplSetting,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );

      let tempJual = [].concat(allJualsTunggakkan.data);

      for (let i = 0; i < allJualsTunggakkan.data.length; i++) {
        let findJual = allJuals.data.find(
          (jual) => jual.namaCabang === allJualsTunggakkan.data[i].namaCabang
        );

        tempJual[i].totalJuals = findJual.totalJuals;
        tempJual[i].kuantitasJuals = findJual.kuantitasJuals;

        tempJual[i].npl =
          (tempJual[i].totalTunggakkan / findJual.totalJuals) * 100;
        tempJual[i].kuantitasNpl =
          (tempJual[i].kuantitasTunggakkan / findJual.kuantitasJuals) * 100;
      }

      setDataBarNplDetail(tempJual);
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanNplDetail(false);
  };

  return (
    <Box>
      <Typography color="#757575">Dashboard Owner</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Dashboard Owner
      </Typography>
      <Typography sx={subTitleText}>
        Periode : {user.periode.namaPeriode}
      </Typography>
      <Divider sx={dividerStyle} />

      <Box sx={{ display: "none" }}>
        <Chart />
      </Box>

      <Paper elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>
          Perbandingan Laba Rugi Per Cabang
        </Typography>
        <Typography sx={detailText}>
          {namaPeriodeLalu} - {user.periode.namaPeriode}
        </Typography>
        <Box sx={graphContainer}>
          {loadingNeracaSaldoLabaRugiSemuaCabangBulan ? (
            <Loader />
          ) : (
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="400px"
              data={dataBarLabaRugiBulan}
              options={optionsLabaRugi}
            />
          )}
        </Box>
      </Paper>

      <Paper sx={contentSecondContainer} elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>
          Penerimaan Angsuran Per Cabang
        </Typography>
        <Typography sx={detailText}>
          {namaPeriodeLalu} - {user.periode.namaPeriode}
        </Typography>
        <Box sx={graphContainer}>
          <Box sx={graphWrapper}>
            <Typography sx={detailText}>Total</Typography>
            {loadingAngsuranForPenerimaanTotalSemuaCabang ? (
              <Loader />
            ) : (
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={dataBarAngsuranTotalBulan}
                options={optionsAngsuranTotal}
              />
            )}
          </Box>
          <Divider orientation="vertical" flexItem />
          <Divider />
          <Box sx={graphWrapper}>
            <Typography sx={detailText}>Bunga</Typography>
            {loadingAngsuranForPenerimaanBungaSemuaCabang ? (
              <Loader />
            ) : (
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={dataBarAngsuranBungaBulan}
                options={optionsAngsuranBunga}
              />
            )}
          </Box>
        </Box>
        <Box sx={graphContainer}></Box>
      </Paper>

      <Paper sx={contentSecondContainer} elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>
          Perbandingan Laporan Penjualan Per Cabang
        </Typography>
        <Box>
          <Typography sx={detailText}>
            {namaPeriodeLalu} - {user.periode.namaPeriode}
          </Typography>
          {loadingPerbandinganPenjualanSemuaCabangBulan ? (
            <Loader />
          ) : (
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="400px"
              data={dataBarPerbandinganPenjualanBulan}
              options={optionsPenjualanPerCabang}
            />
          )}
        </Box>
      </Paper>

      <Paper sx={contentSecondContainer} elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>
          Angsuran Per. Tgl {todayDate}
        </Typography>
        <Box sx={graphContainer}>
          <Box sx={graphWrapper}>
            <Typography sx={[detailText, dividerStyle]}>
              Angsuran Aktif (Rupiah)
            </Typography>
            <Box sx={graphContainer}>
              {loadingLaporanAngsuranAktif ? (
                <Loader />
              ) : (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={dataBarAngsuranAktif}
                  options={optionsAngsuranAktif}
                />
              )}
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Divider />
          <Box sx={graphWrapper}>
            <Typography sx={[detailText, dividerStyle]}>
              Angsuran Jatuh Tempo (Rupiah)
            </Typography>
            <Box sx={graphContainer}>
              {loadingLaporanJatuhTempo ? (
                <Loader />
              ) : (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={dataBarJatuhTempo}
                  options={optionsJatuhTempo}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={graphContainer}>
          <Box sx={graphWrapper}>
            <Typography sx={[detailText, dividerStyle]}>
              Angsuran Aktif (Quantity)
            </Typography>
            <Box sx={graphContainer}>
              {loadingLaporanAngsuranAktifKuantitas ? (
                <Loader />
              ) : (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={dataBarAngsuranAktifKuantitas}
                  options={optionsAngsuranAktifKuantitas}
                />
              )}
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Divider />
          <Box sx={graphWrapper}>
            <Typography sx={[detailText, dividerStyle]}>
              Angsuran Jatuh Tempo (Quantity)
            </Typography>
            <Box sx={graphContainer}>
              {loadingLaporanJatuhTempoKuantitas ? (
                <Loader />
              ) : (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={dataBarJatuhTempoKuantitas}
                  options={optionsJatuhTempoKuantitas}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Paper>

      <Paper sx={contentSecondContainer} elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>
          NPL Semua Cabang (Rupiah)
        </Typography>
        <Box sx={tableContainer}>
          {loadingLaporanNpl ? (
            <Loader />
          ) : (
            <ShowTableDashboardCabangNpl
              tunggakans={dataBarNpl}
              angsurans={dataBarNplSemua}
              totalSatuBulanNumberNpl={totalSatuBulanNumberNpl}
              totalDuaBulanNumberNpl={totalDuaBulanNumberNpl}
              totalTigaBulanNumberNpl={totalTigaBulanNumberNpl}
              totalLebihDariTigaBulanNumberNpl={
                totalLebihDariTigaBulanNumberNpl
              }
              totalTotalNumberNpl={totalTotalNumberNpl}
            />
          )}
        </Box>
      </Paper>

      <Paper sx={contentSecondContainer} elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>
          NPL Semua Cabang (Quantity)
        </Typography>
        <Box sx={tableContainer}>
          {loadingLaporanNplKuantitas ? (
            <Loader />
          ) : (
            <ShowTableDashboardCabangNplKuantitas
              tunggakans={dataBarNplKuantitas}
              angsurans={dataBarNplSemuaKuantitas}
              totalSatuBulanNumberNpl={totalSatuBulanNumberNplKuantitas}
              totalDuaBulanNumberNpl={totalDuaBulanNumberNplKuantitas}
              totalTigaBulanNumberNpl={totalTigaBulanNumberNplKuantitas}
              totalLebihDariTigaBulanNumberNpl={
                totalLebihDariTigaBulanNumberNplKuantitas
              }
              totalTotalNumberNpl={totalTotalNumberNplKuantitas}
            />
          )}
        </Box>
      </Paper>

      <Paper sx={contentSecondContainer} elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>NPL Per Cabang ({setting.batasNplSetting} hari)</Typography>
        <Box sx={tableContainer}>
          {loadingLaporanNplDetail ? (
            <Loader />
          ) : (
            <ShowTableDashboardCabangNplDetail juals={dataBarNplDetail} />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default DashboardOwner;

const contentSecondContainer = {
  mt: 4,
};

const subTitleText = {
  fontWeight: "900",
};

const detailText = {
  fontWeight: "900",
  textAlign: "center",
};

const dividerStyle = {
  pt: 4,
};

const graphContainer = {
  p: {
    xs: 0,
    sm: 6,
  },
  display: "flex",
  justifyContent: {
    sm: "space-around",
  },
  flexDirection: {
    xs: "column",
    sm: "column",
    md: "row",
  },
};

const graphWrapper = {
  pt: {
    xs: 4,
    sm: 0,
  },
  width: {
    xs: "300px",
    sm: "400px",
  },
};

const tableContainer = {
  p: 5,
  display: "flex",
  justifyContent: "center",
};
