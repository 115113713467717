import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableDaftarBeli } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  ButtonGroup,
  Button,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TampilDaftarBeli = () => {
  const { user, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [belisData, setBelisData] = useState([]);
  const kode = null;

  const [loading, setLoading] = useState(false);

  const [dariTgl, setDariTgl] = useState(new Date());
  const [sampaiTgl, setSampaiTgl] = useState(new Date());
  const [toggleCetak, setToggleCetak] = useState(false);

  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  const columnsCetak = [
    { title: "No.", field: "no" },
    { title: "No. Beli", field: "noBeli" },
    { title: "Supplier", field: "supplier" },
    { title: "Jumlah", field: "jumlahBeli" },
    { title: "PPN", field: "ppnBeli" },
    { title: "Potongan", field: "potongan" },
    { title: "Lama", field: "lama" },
    { title: "Tanggal", field: "tanggalBeliFormatted" },
    { title: "Jatuh Tempo", field: "jatuhTempoFormatted" },
    { title: "Jenis", field: "jenisBeli" },
  ];

  useEffect(() => {
    getBelisData();
  }, [page, searchTerm, id]);

  const getBelisData = async () => {
    setLoading(true);
    try {
      const allBelis = await axios.post(
        `${tempUrl}/belisPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          dariTgl: user.periode.periodeAwal,
          sampaiTgl: user.periode.periodeAkhir,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      setQuery(searchTerm);
      setBelisData(allBelis.data.belis);
      setPage(allBelis.data.page);
      setPages(allBelis.data.totalPage);
      setRows(allBelis.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const downloadPdfCetak = async () => {
    setLoading(true);
    let allBelis = await axios.post(`${tempUrl}/belisTanggal`, {
      dariTgl,
      sampaiTgl,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    const doc = new jsPDF("l", "mm", [297, 210]);
    doc.setFontSize(12);
    doc.text(`${user.cabang.istilahCabang}`, 15, 10);
    doc.setFontSize(16);
    doc.text(`Daftar Beli`, 140, 30);
    doc.setFontSize(10);

    let no = 0;
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      columns: columnsCetak.map((col) => ({ ...col, dataKey: col.field })),
      body: allBelis.data.map((col) => {
        no++;
        return { ...col, no };
      }),
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      styles: {
        fontSize: 8,
      },
    });

    doc.setFontSize(12);
    window.open(URL.createObjectURL(doc.output("blob")));
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <Typography color="#757575">Pembelian</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Daftar Beli
      </Typography>
      <Typography sx={subTitleText}>
        Periode : {user.periode.namaPeriode}
      </Typography>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            variant="contained"
            onClick={() => setToggleCetak(!toggleCetak)}
          >
            Cetak
          </Button>
        </ButtonGroup>
      </Box>
      {toggleCetak && (
        <>
          <Box sx={spacingTop}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Dari Tanggal :
                  </Form.Label>
                  <Col sm="5">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={dariTgl}
                      onChange={(e) => setDariTgl(e)}
                      customInput={
                        <TextField sx={{ width: "100%" }} size="small" />
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Sampai Tanggal :
                  </Form.Label>
                  <Col sm="5">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={sampaiTgl}
                      onChange={(e) => setSampaiTgl(e)}
                      customInput={
                        <TextField sx={{ width: "100%" }} size="small" />
                      }
                    />
                  </Col>
                  <Col sm="4">
                    <Button
                      variant="contained"
                      startIcon={<PrintIcon />}
                      onClick={() => {
                        if (dariTgl === null || sampaiTgl == null) {
                          alert("Input Tanggal harus diisi!");
                        } else {
                          downloadPdfCetak();
                        }
                      }}
                    >
                      CETAK
                    </Button>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <hr />
        </>
      )}
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={"/"}
          kode={kode}
          addLink={`/daftarBeli/beli/tambahBeli`}
          editLink={`/`}
          deleteUser={"/"}
        />
      </Box>
      <Divider sx={dividerStyle} />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableDaftarBeli currentPosts={belisData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilDaftarBeli;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const spacingTop = {
  mt: 4,
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
