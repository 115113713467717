import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { ShowTablePemutihanSemuaCabang } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ScrollToTop } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Pagination,
  Autocomplete,
  ButtonGroup,
  Button,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";
import { Colors } from "../../../constants/styles";

const TampilDaftarApprovePemutihan = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const [isFetchError, setIsFetchError] = useState(false);
  const [cabangInput, setCabangInput] = useState("");

  const [value, setValue] = useState("Semua");
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(false);
  const [allCabang, setAllCabang] = useState([]);
  const [pemutihanData, setPemutihanData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  const cabangOptions = allCabang.map((user) => ({
    label: `${user._id} - ${user.namaCabang}`,
  }));

  const handleChangeFilter = (event) => {
    setValue(event.target.value);
  };

  const columns = [
    { title: "No", field: "no" },
    { title: "No. Bukti", field: "noBukti" },
    { title: "Tgl. Pengajuan", field: "tglPengajuanFormatted" },
  ];

  useEffect(() => {
    getAllCabang();
  }, []);

  useEffect(() => {
    getPemutihanData();
  }, [value, page, searchTerm, cabangInput]);

  const getAllCabang = async () => {
    setLoading(true);

    let kodeCabangDiKunci = "";

    for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
      if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
        kodeCabangDiKunci =
          setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
      }
    }

    const allCabang = await axios.post(`${tempUrl}/cabangsExclude`, {
      id: user._id,
      token: user.token,
      kodeCabangDiKunci: kodeCabangDiKunci,
    });
    setAllCabang(allCabang.data);
    setLoading(false);
  };

  const getPemutihanData = async () => {
    try {
      let allPemutihans;
      if (cabangInput.length !== 0) {
        switch (value) {
          case "Belum Approve":
            allPemutihans = await axios.post(
              `${tempUrl}/pemutihansPaginationBelumApprove?search_query=${searchTerm}&page=${page}&limit=${limit}`,
              {
                id: user._id,
                token: user.token,
                kodeCabang: cabangInput,
              }
            );
            break;
          case "Sudah Approve":
            allPemutihans = await axios.post(
              `${tempUrl}/pemutihansPaginationSudahApprove?search_query=${searchTerm}&page=${page}&limit=${limit}`,
              {
                id: user._id,
                token: user.token,
                kodeCabang: cabangInput,
              }
            );
            break;
          default:
            allPemutihans = await axios.post(
              `${tempUrl}/pemutihansPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
              {
                id: user._id,
                token: user.token,
                kodeCabang: cabangInput,
              }
            );
            break;
        }
      } else {
        switch (value) {
          case "Belum Approve":
            allPemutihans = await axios.post(
              `${tempUrl}/pemutihansSemuaCabangPaginationBelumApprove?search_query=${searchTerm}&page=${page}&limit=${limit}`,
              {
                id: user._id,
                token: user.token,
              }
            );
            break;
          case "Sudah Approve":
            allPemutihans = await axios.post(
              `${tempUrl}/pemutihansSemuaCabangPaginationSudahApprove?search_query=${searchTerm}&page=${page}&limit=${limit}`,
              {
                id: user._id,
                token: user.token,
              }
            );
            break;
          default:
            allPemutihans = await axios.post(
              `${tempUrl}/pemutihansSemuaCabangPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
              {
                id: user._id,
                token: user.token,
              }
            );
            break;
        }
      }

      setQuery(searchTerm);
      setPemutihanData(allPemutihans.data.pemutihans);
      setPage(allPemutihans.data.page);
      setPages(allPemutihans.data.totalPage);
      setRows(allPemutihans.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setCabangInput(cabangInput);
  };

  const getPemutihansForDoc = async (isPdf, isExcel) => {
    setLoading(true);
    const allPemutihansForDoc = await axios.post(
      `${tempUrl}/pemutihansSemuaCabangForDoc`,
      {
        id: user._id,
        token: user.token,
      }
    );
    setLoading(false);
    if (isPdf) {
      downloadPdf(allPemutihansForDoc.data);
    } else if (isExcel) {
      downloadExcel(allPemutihansForDoc.data);
    }
  };

  const downloadPdf = async (data) => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Pemutihan`, 90, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    let no = 0;
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: data.map((col) => {
        no++;
        return { ...col, no };
      }),
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadExcel = (data) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `Pemutihan`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarPemutihan.xlsx`);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Daftar Pemutihan
      </Typography>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            startIcon={<PrintIcon />}
            onClick={() => getPemutihansForDoc(true, false)}
          >
            CETAK
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => getPemutihansForDoc(false, true)}
          >
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Cabang :
              </Form.Label>
              <Col sm="9">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={cabangOptions}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      error={error && cabangInput.length === 0 && true}
                      helperText={
                        error &&
                        cabangInput.length === 0 &&
                        "Cabang harus diisi!"
                      }
                      placeholder="SEMUA CABANG"
                      {...params}
                    />
                  )}
                  onInputChange={(e, value) => {
                    setCabangInput(value.split(" ", 1)[0]);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <hr />
      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Filter</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="Semua"
          value={value}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            value="Sudah Approve"
            control={<Radio />}
            label="Sudah Approve"
          />
          <FormControlLabel
            value="Belum Approve"
            control={<Radio />}
            label="Belum Approve"
          />
          <FormControlLabel value="Semua" control={<Radio />} label="Semua" />
        </RadioGroup>
      </FormControl>
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={{ mt: 2 }}>
        <Chip
          label="Sudah Approve"
          sx={{
            backgroundColor: Colors.green400,
          }}
        />
        <Chip
          label="Belum Approve"
          sx={{
            backgroundColor: Colors.red400,
          }}
        />
      </Box>
      <Box sx={tableContainer}>
        <ShowTablePemutihanSemuaCabang currentPosts={pemutihanData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilDaftarApprovePemutihan;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
