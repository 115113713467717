import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableJoinMarketing } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Loader, ButtonModifier, ScrollToTop } from "../../../components";
import {
  Box,
  Paper,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import { Colors } from "../../../constants/styles";

const TampilMarketing = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const [isFetchError, setIsFetchError] = useState(false);
  const [isMarketingExist, setIsMarketingExist] = useState(false);
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [namaMarketing, setNamaMarketing] = useState("");
  const [teleponMarketing, setTeleponMarketing] = useState("");
  const [marketingsData, setMarketingsData] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    id ? getMarketingById() : setIsMarketingExist(false);
  }, [id]);

  const getMarketingById = async () => {
    setLoading(true);
    if (id) {
      const pickedMarketing = await axios.post(`${tempUrl}/marketings/${id}`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setIsMarketingExist(true);
      setKodeMarketing(pickedMarketing.data.kodeMarketing);
      setNamaMarketing(pickedMarketing.data.namaMarketing);
      setTeleponMarketing(pickedMarketing.data.teleponMarketing);
      setMarketingsData(pickedMarketing.data.joinMarketing);
    }
    setLoading(false);
  };

  const deleteMarketing = async (id) => {
    const findJualMarketing = await axios.post(`${tempUrl}/jualsMarketing`, {
      kodeMarketing: id,
      id: user._id,
      token: user.token,
    });
    const findRejectMarketing = await axios.post(
      `${tempUrl}/rejectsMarketing`,
      {
        kodeMarketing: id,
        id: user._id,
        token: user.token,
      }
    );
    let isMarketingDataExist =
      findJualMarketing.data.length > 0 || findRejectMarketing.data.length > 0;
    if (isMarketingDataExist) {
      // There's Record -> Forbid Delete
      handleClickOpen();
    } else {
      // No Record Found -> Delete
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/deleteMarketing/${id}`, {
          id: user._id,
          token: user.token,
        });
        setKodeMarketing("");
        setNamaMarketing("");
        setTeleponMarketing("");
        setLoading(false);
        navigate("/marketing");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/marketing")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Typography color="#757575">Master</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Marketing
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Tidak bisa dihapus karena sudah ada data!`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Nama Marketing data: ${namaMarketing}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={isMarketingExist}
          addLink={`/marketing/${id}/tambahMarketingJoin`}
          editLink={`/marketing/${id}/edit`}
          deleteUser={deleteMarketing}
          nameUser={kodeMarketing}
        />
      </Box>
      <Divider sx={dividerStyle} />
      {isMarketingExist && (
        <>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Marketing :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeMarketing} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Marketing :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaMarketing} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={teleponMarketing} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
      <Paper sx={contentContainer} elevation={12}>
        <Typography variant="h5" sx={titleStyle} color="primary">
          Daftar Join Marketing
        </Typography>
        <Box sx={tableContainer}>
          <ShowTableJoinMarketing id={id} currentPosts={marketingsData} />
        </Box>
      </Paper>
    </Box>
  );
};

export default TampilMarketing;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};
