import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  ShowTableDashboardCabangNpl,
  ShowTableDashboardCabangNplKuantitas,
} from "../../../components/ShowTable";
import { Box, Typography, Divider, Paper } from "@mui/material";
import { Chart } from "react-google-charts";
import { formatDate } from "../../../constants/helper";

export const optionsLabaRugi = {
  hAxis: {
    title: "Cabang",
  },
  vAxis: {
    title: "Total Laba Rugi",
    minValue: 0,
  },
};

export const optionsAngsuranTotal = {
  hAxis: {
    title: "Cabang",
  },
  vAxis: {
    title: "Total Angsuran Total",
    minValue: 0,
  },
};

export const optionsAngsuranBunga = {
  hAxis: {
    title: "Cabang",
  },
  vAxis: {
    title: "Total Angsuran Bunga",
    minValue: 0,
  },
};

export const optionsPenjualanPerCabang = {
  hAxis: {
    title: "Cabang",
  },
  vAxis: {
    title: "Jumlah Penjualan",
    minValue: 0,
  },
};

export const optionsJual = {
  hAxis: {
    title: "Cabang",
  },
  vAxis: {
    title: "Total Penjualan",
    minValue: 0,
  },
};

export const optionsAngsuranAktif = {
  hAxis: {
    title: "Cabang",
  },
  vAxis: {
    title: "Total Aktif",
    minValue: 0,
  },
};

export const optionsAngsuranAktifKuantitas = {
  hAxis: {
    title: "Cabang",
  },
  vAxis: {
    title: "Kuantitas Aktif",
    minValue: 0,
  },
};

export const optionsJatuhTempo = {
  hAxis: {
    title: "Cabang",
  },
  vAxis: {
    title: "Total Jatuh Tempo",
    minValue: 0,
  },
};

export const optionsJatuhTempoKuantitas = {
  hAxis: {
    title: "Cabang",
  },
  vAxis: {
    title: "Kuantitas Jatuh Tempo",
    minValue: 0,
  },
};

const DashboardCabang = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  let tempDate = new Date();
  const [todayDate, setTodayDate] = useState(formatDate(tempDate));
  const [
    dataBarPerbandinganPenjualanBulan,
    setDataBarPerbandinganPenjualanBulan,
  ] = useState([]);
  const [dataBarLabaRugiBulan, setDataBarLabaRugiBulan] = useState([]);
  const [dataBarAngsuranTotalBulan, setDataBarAngsuranTotalBulan] = useState(
    []
  );
  const [dataBarAngsuranBungaBulan, setDataBarAngsuranBungaBulan] = useState(
    []
  );
  const [dataBarAngsuranAktif, setDataBarAngsuranAktif] = useState([]);
  const [dataBarAngsuranAktifKuantitas, setDataBarAngsuranAktifKuantitas] =
    useState([]);
  const [dataBarJatuhTempo, setDataBarJatuhTempo] = useState([]);
  const [dataBarJatuhTempoKuantitas, setDataBarJatuhTempoKuantitas] = useState(
    []
  );
  const [dataBarNpl, setDataBarNpl] = useState([]);
  const [dataBarNplSemua, setDataBarNplSemua] = useState([]);
  const [dataBarNplKuantitas, setDataBarNplKuantitas] = useState([]);
  const [dataBarNplSemuaKuantitas, setDataBarNplSemuaKuantitas] = useState([]);

  const [totalSatuBulanNumberNpl, setTotalSatuBulanNumberNpl] = useState(0);
  const [totalDuaBulanNumberNpl, setTotalDuaBulanNumberNpl] = useState(0);
  const [totalTigaBulanNumberNpl, setTotalTigaBulanNumberNpl] = useState(0);
  const [
    totalLebihDariTigaBulanNumberNpl,
    setTotalLebihDariTigaBulanNumberNpl,
  ] = useState(0);
  const [totalTotalNumberNpl, setTotalTotalNumberNpl] = useState(0);

  const [
    totalSatuBulanNumberNplKuantitas,
    setTotalSatuBulanNumberNplKuantitas,
  ] = useState(0);
  const [totalDuaBulanNumberNplKuantitas, setTotalDuaBulanNumberNplKuantitas] =
    useState(0);
  const [
    totalTigaBulanNumberNplKuantitas,
    setTotalTigaBulanNumberNplKuantitas,
  ] = useState(0);
  const [
    totalLebihDariTigaBulanNumberNplKuantitas,
    setTotalLebihDariTigaBulanNumberNplKuantitas,
  ] = useState(0);
  const [totalTotalNumberNplKuantitas, setTotalTotalNumberNplKuantitas] =
    useState(0);

  const [namaPeriodeLalu, setNamaPeriodeLalu] = useState("");
  const [loadingBulanLalu, setLoadingBulanLalu] = useState(false);
  const [
    loadingNeracaSaldoLabaRugiCabangBulan,
    setLoadingNeracaSaldoLabaRugiCabangBulan,
  ] = useState(false);
  const [
    loadingAngsuranForPenerimaanTotalCabang,
    setLoadingAngsuranForPenerimaanTotalCabang,
  ] = useState(false);
  const [
    loadingAngsuranForPenerimaanBungaCabang,
    setLoadingAngsuranForPenerimaanBungaCabang,
  ] = useState(false);
  const [loadingLaporanAngsuranAktif, setLoadingLaporanAngsuranAktif] =
    useState(false);
  const [
    loadingLaporanAngsuranAktifKuantitas,
    setLoadingLaporanAngsuranAktifKuantitas,
  ] = useState(false);
  const [loadingLaporanJatuhTempo, setLoadingLaporanJatuhTempo] =
    useState(false);
  const [
    loadingLaporanJatuhTempoKuantitas,
    setLoadingLaporanJatuhTempoKuantitas,
  ] = useState(false);
  const [loadingLaporanNpl, setLoadingLaporanNpl] = useState(false);
  const [loadingLaporanNplKuantitas, setLoadingLaporanNplKuantitas] =
    useState(false);

  function subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
    return date;
  }

  useEffect(() => {
    getBulanLalu();
    getPerbandinganPenjualanCabangBulan();
    getNeracaSaldoLabaRugiCabangBulan();
    getAngsuranForPenerimaanTotalCabang();
    getAngsuranForPenerimaanBungaCabang();
    getLaporanAngsuranAktif();
    getLaporanAngsuranAktifKuantitas();
    getLaporanJatuhTempo();
    getLaporanJatuhTempoKuantitas();
    getJualsForDashboardCabangNpl();
    getJualsKuantitasAllForDashboardCabangNpl();
  }, []);

  const getPerbandinganPenjualanCabangBulan = async () => {
    setLoadingBulanLalu(true);
    try {
      let tempPerbandinganPenjualanCabang = [];
      const newBulan = new Date(user.periode.periodeAwal);
      const minOneBulan = subtractMonths(newBulan, 1);
      let dariTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        minOneBulan.getDate();

      var lastday = function (year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();
      };

      let sampaiTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        lastday(minOneBulan.getFullYear(), minOneBulan.getMonth());

      const jualCabangLalu = await axios.post(
        `${tempUrl}/jualsForDashboardPerCabang`,
        {
          dariTgl,
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );

      const jualCabangIni = await axios.post(
        `${tempUrl}/jualsForDashboardPerCabang`,
        {
          dariTgl: user.periode.periodeAwal,
          sampaiTgl: user.periode.periodeAkhir,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );

      for (let i = 0; i < jualCabangLalu.data.length; i++) {
        for (let j = 0; j < jualCabangIni.data.length; j++) {
          if (jualCabangLalu.data[i].cabang === jualCabangIni.data[j].cabang) {
            let objectJualCabang = {
              tanggal: jualCabangLalu.data[i].tanggal,
              cabang: jualCabangLalu.data[i].cabang,
              namaCabang: jualCabangLalu.data[i].namaCabang,
              countLalu: jualCabangLalu.data[j].count,
              count: jualCabangIni.data[j] ? jualCabangIni.data[j].count : 0,
            };
            tempPerbandinganPenjualanCabang.push(objectJualCabang);
          }
        }
      }

      let tempDataBarChart = [["Total Jual", "Bulan Lalu", "Bulan Ini"]];

      for (let i = 0; i < tempPerbandinganPenjualanCabang.length; i++) {
        let tempTotalSuara = [
          `${tempPerbandinganPenjualanCabang[i].namaCabang}`,
          tempPerbandinganPenjualanCabang[i].countLalu,
          tempPerbandinganPenjualanCabang[i].count,
        ];
        tempDataBarChart.push(tempTotalSuara);
      }
      setDataBarPerbandinganPenjualanBulan(tempDataBarChart);
    } catch (e) {
      console.log(e);
    }
    setLoadingBulanLalu(false);
  };

  const getBulanLalu = () => {
    try {
      let tempDateName;
      const newBulan = new Date(user.periode.periodeAwal);
      const minOneBulan = subtractMonths(newBulan, 1);
      switch (minOneBulan.getMonth() + 1) {
        case 1:
          tempDateName = "JANUARI";
          break;
        case 2:
          tempDateName = "FEBRUARI";
          break;
        case 3:
          tempDateName = "MARET";
          break;
        case 4:
          tempDateName = "APRIL";
          break;
        case 5:
          tempDateName = "MEI";
          break;
        case 6:
          tempDateName = "JUNI";
          break;
        case 7:
          tempDateName = "JULI";
          break;
        case 8:
          tempDateName = "AGUSTUS";
          break;
        case 9:
          tempDateName = "SEPTEMBER";
          break;
        case 10:
          tempDateName = "OKTOBER";
          break;
        case 11:
          tempDateName = "NOVEMBER";
          break;
        case 12:
          tempDateName = "DESEMBER";
          break;
        default:
          break;
      }
      setNamaPeriodeLalu(`${tempDateName} ${minOneBulan.getFullYear()}`);
    } catch (e) {
      console.log(e);
    }
  };

  const getNeracaSaldoLabaRugiCabangBulan = async () => {
    setLoadingNeracaSaldoLabaRugiCabangBulan(true);
    try {
      let tempPerbandinganLabaRugiCabang = [];
      const newBulan = new Date(user.periode.periodeAwal);
      const minOneBulan = subtractMonths(newBulan, 1);
      let dariTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        minOneBulan.getDate();

      var lastday = function (year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();
      };

      let sampaiTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        lastday(minOneBulan.getFullYear(), minOneBulan.getMonth());

      const labaRugiCabangLalu = await axios.post(
        `${tempUrl}/neracaSaldoLabaRugiCabang`,
        {
          labaRugiPeriodeBerjalan: setting.labaRugiPeriodeBerjalan,
          dariTgl,
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );

      const labaRugiCabangIni = await axios.post(
        `${tempUrl}/neracaSaldoLabaRugiCabang`,
        {
          labaRugiPeriodeBerjalan: setting.labaRugiPeriodeBerjalan,
          dariTgl: user.periode.periodeAwal,
          sampaiTgl: user.periode.periodeAkhir,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );

      for (let i = 0; i < labaRugiCabangLalu.data.length; i++) {
        for (let j = 0; j < labaRugiCabangIni.data.length; j++) {
          if (
            labaRugiCabangLalu.data[i].cabang ===
            labaRugiCabangIni.data[j].cabang
          ) {
            let objectLabaRugiCabang = {
              namaCabang: labaRugiCabangLalu.data[i].namaCabang,
              cabang: labaRugiCabangLalu.data[i].cabang,
              countLalu: labaRugiCabangLalu.data[j].count,
              count: labaRugiCabangIni.data[i].count,
            };
            tempPerbandinganLabaRugiCabang.push(objectLabaRugiCabang);
          }
        }
      }

      let tempDataBarChart = [["Total Laba Rugi", "Bulan Lalu", "Bulan Ini"]];

      for (let i = 0; i < tempPerbandinganLabaRugiCabang.length; i++) {
        let tempLabaRugi = [
          `${tempPerbandinganLabaRugiCabang[i].namaCabang}`,
          tempPerbandinganLabaRugiCabang[i].countLalu,
          tempPerbandinganLabaRugiCabang[i].count,
        ];
        tempDataBarChart.push(tempLabaRugi);
      }
      setDataBarLabaRugiBulan(tempDataBarChart);
    } catch (e) {
      console.log(e);
    }
    setLoadingNeracaSaldoLabaRugiCabangBulan(false);
  };

  const getAngsuranForPenerimaanTotalCabang = async () => {
    setLoadingAngsuranForPenerimaanTotalCabang(true);
    try {
      let tempPerbandinganAngsuranTotalCabang = [];
      const newBulan = new Date(user.periode.periodeAwal);
      const minOneBulan = subtractMonths(newBulan, 1);
      let dariTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        minOneBulan.getDate();

      var lastday = function (year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();
      };

      let sampaiTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        lastday(minOneBulan.getFullYear(), minOneBulan.getMonth());

      const angsuranTotalCabangLalu = await axios.post(
        `${tempUrl}/penerimaansTotalCabang`,
        {
          dariTgl,
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );

      const angsuranTotalCabangIni = await axios.post(
        `${tempUrl}/penerimaansTotalCabang`,
        {
          dariTgl: user.periode.periodeAwal,
          sampaiTgl: user.periode.periodeAkhir,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );

      for (let i = 0; i < angsuranTotalCabangLalu.data.length; i++) {
        for (let j = 0; j < angsuranTotalCabangIni.data.length; j++) {
          if (
            angsuranTotalCabangLalu.data[i].cabang ===
            angsuranTotalCabangIni.data[j].cabang
          ) {
            let objectAngsuranTotalCabang = {
              namaCabang: angsuranTotalCabangLalu.data[i].namaCabang,
              cabang: angsuranTotalCabangLalu.data[i].cabang,
              countLalu: angsuranTotalCabangLalu.data[j].count,
              count: angsuranTotalCabangIni.data[i].count,
            };
            tempPerbandinganAngsuranTotalCabang.push(objectAngsuranTotalCabang);
          }
        }
      }

      let tempDataBarChart = [
        ["Total Penerimaan Angsuran", "Bulan Lalu", "Bulan Ini"],
      ];

      for (let i = 0; i < tempPerbandinganAngsuranTotalCabang.length; i++) {
        let tempTotalSuara = [
          `${tempPerbandinganAngsuranTotalCabang[i].namaCabang}`,
          tempPerbandinganAngsuranTotalCabang[i].countLalu,
          tempPerbandinganAngsuranTotalCabang[i].count,
        ];
        tempDataBarChart.push(tempTotalSuara);
      }
      setDataBarAngsuranTotalBulan(tempDataBarChart);
    } catch (e) {
      console.log(e);
    }
    setLoadingAngsuranForPenerimaanTotalCabang(false);
  };

  const getAngsuranForPenerimaanBungaCabang = async () => {
    setLoadingAngsuranForPenerimaanBungaCabang(true);
    try {
      let tempPerbandinganAngsuranTotalCabang = [];
      const newBulan = new Date(user.periode.periodeAwal);
      const minOneBulan = subtractMonths(newBulan, 1);
      let dariTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        minOneBulan.getDate();

      var lastday = function (year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();
      };

      let sampaiTgl =
        minOneBulan.getFullYear() +
        "-" +
        (minOneBulan.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        lastday(minOneBulan.getFullYear(), minOneBulan.getMonth());

      const angsuranTotalCabangLalu = await axios.post(
        `${tempUrl}/penerimaansBungaCabang`,
        {
          dariTgl,
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );

      const angsuranTotalCabangIni = await axios.post(
        `${tempUrl}/penerimaansBungaCabang`,
        {
          dariTgl: user.periode.periodeAwal,
          sampaiTgl: user.periode.periodeAkhir,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );

      for (let i = 0; i < angsuranTotalCabangLalu.data.length; i++) {
        for (let j = 0; j < angsuranTotalCabangIni.data.length; j++) {
          if (
            angsuranTotalCabangLalu.data[i].cabang ===
            angsuranTotalCabangIni.data[j].cabang
          ) {
            let objectAngsuranTotalCabang = {
              namaCabang: angsuranTotalCabangLalu.data[i].namaCabang,
              cabang: angsuranTotalCabangLalu.data[i].cabang,
              countLalu: angsuranTotalCabangLalu.data[j].count,
              count: angsuranTotalCabangIni.data[i].count,
            };
            tempPerbandinganAngsuranTotalCabang.push(objectAngsuranTotalCabang);
          }
        }
      }

      let tempDataBarChart = [
        ["Total Penerimaan Angsuran", "Bulan Lalu", "Bulan Ini"],
      ];

      for (let i = 0; i < tempPerbandinganAngsuranTotalCabang.length; i++) {
        let tempTotalSuara = [
          `${tempPerbandinganAngsuranTotalCabang[i].namaCabang}`,
          tempPerbandinganAngsuranTotalCabang[i].countLalu,
          tempPerbandinganAngsuranTotalCabang[i].count,
        ];
        tempDataBarChart.push(tempTotalSuara);
      }
      setDataBarAngsuranBungaBulan(tempDataBarChart);
    } catch (e) {
      console.log(e);
    }
    setLoadingAngsuranForPenerimaanBungaCabang(false);
  };

  const getLaporanAngsuranAktif = async () => {
    setLoadingLaporanAngsuranAktif(true);
    try {
      const angsuranAktif = await axios.post(
        `${tempUrl}/jualsForAngsuranAktifCabang`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      let tempDataBarChart = [["Angsuran Aktif", "Total Aktif"]];

      if (angsuranAktif.data.length > 0) {
        // Angsuran Aktif Exist
        for (let i = 0; i < angsuranAktif.data.length; i++) {
          let tempAngsuranAktif = [
            `${angsuranAktif.data[i].namaCabang}`,
            angsuranAktif.data[i].count,
          ];
          tempDataBarChart.push(tempAngsuranAktif);
        }
        setDataBarAngsuranAktif(tempDataBarChart);
      } else {
        // Angsuran Aktif Not Exist
        let tempAngsuranAktif = [`${user.cabang.namaCabang}`, 0];
        tempDataBarChart.push(tempAngsuranAktif);
        setDataBarAngsuranAktif(tempDataBarChart);
      }
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanAngsuranAktif(false);
  };

  const getLaporanAngsuranAktifKuantitas = async () => {
    setLoadingLaporanAngsuranAktifKuantitas(true);
    try {
      const angsuranAktif = await axios.post(
        `${tempUrl}/jualsForAngsuranAktifKuantitasCabang`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      let tempDataBarChart = [["Angsuran Aktif", "Total Aktif"]];

      if (angsuranAktif.data.length > 0) {
        // Angsuran Aktif Exist
        for (let i = 0; i < angsuranAktif.data.length; i++) {
          let tempAngsuranAktif = [
            `${angsuranAktif.data[i].namaCabang}`,
            angsuranAktif.data[i].count,
          ];
          tempDataBarChart.push(tempAngsuranAktif);
        }
        setDataBarAngsuranAktifKuantitas(tempDataBarChart);
      } else {
        // Angsuran Aktif Not Exist
        let tempAngsuranAktif = [`${user.cabang.namaCabang}`, 0];
        tempDataBarChart.push(tempAngsuranAktif);
        setDataBarAngsuranAktifKuantitas(tempDataBarChart);
      }
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanAngsuranAktifKuantitas(false);
  };

  const getLaporanJatuhTempo = async () => {
    setLoadingLaporanJatuhTempo(true);
    try {
      const angsuranJatuhTempo = await axios.post(
        `${tempUrl}/jualsJatuhTempoCabang`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      let tempDataBarChart = [["Jatuh Tempo", "Total Jatuh Tempo"]];

      if (angsuranJatuhTempo.data.length > 0) {
        // Jatuh Tempo Exist
        for (let i = 0; i < angsuranJatuhTempo.data.length; i++) {
          let tempJatuhTempo = [
            `${angsuranJatuhTempo.data[i].namaCabang}`,
            angsuranJatuhTempo.data[i].count,
          ];
          tempDataBarChart.push(tempJatuhTempo);
        }
        setDataBarJatuhTempo(tempDataBarChart);
      } else {
        // Jatuh Tempo Not Exist
        let tempJatuhTempo = [`${user.cabang.namaCabang}`, 0];
        tempDataBarChart.push(tempJatuhTempo);
        setDataBarJatuhTempo(tempDataBarChart);
      }
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanJatuhTempo(false);
  };

  const getLaporanJatuhTempoKuantitas = async () => {
    setLoadingLaporanJatuhTempoKuantitas(true);
    try {
      const angsuranJatuhTempo = await axios.post(
        `${tempUrl}/jualsTotalJatuhTempoKuantitasCabang`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      let tempDataBarChart = [["Jatuh Tempo", "Total Jatuh Tempo"]];

      if (angsuranJatuhTempo.data.length > 0) {
        // Jatuh Tempo Exist
        for (let i = 0; i < angsuranJatuhTempo.data.length; i++) {
          let tempJatuhTempo = [
            `${angsuranJatuhTempo.data[i].namaCabang}`,
            angsuranJatuhTempo.data[i].count,
          ];
          tempDataBarChart.push(tempJatuhTempo);
        }
        setDataBarJatuhTempoKuantitas(tempDataBarChart);
      } else {
        // Jatuh Tempo Not Exist
        let tempJatuhTempo = [`${user.cabang.namaCabang}`, 0];
        tempDataBarChart.push(tempJatuhTempo);
        setDataBarJatuhTempoKuantitas(tempDataBarChart);
      }
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanJatuhTempoKuantitas(false);
  };

  const getJualsForDashboardCabangNpl = async () => {
    setLoadingLaporanNpl(true);
    try {
      const allJualsTunggakkan = await axios.post(
        `${tempUrl}/jualsForDashboardCabangNpl`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      setDataBarNpl(allJualsTunggakkan.data);

      const allJuals = await axios.post(
        `${tempUrl}/jualsAllForDashboardCabangNpl`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      setDataBarNplSemua(allJuals.data);

      let tempTotalSatuBulanNumberNpl =
        (allJualsTunggakkan.data[0].totalSatuBulanNumber /
          allJuals.data[0].totalSatuBulanNumber) *
        100;
      let tempTotalDuaBulanNumber =
        (allJualsTunggakkan.data[0].totalDuaBulanNumber /
          allJuals.data[0].totalDuaBulanNumber) *
        100;
      let tempTotalTigaBulanNumber =
        (allJualsTunggakkan.data[0].totalTigaBulanNumber /
          allJuals.data[0].totalTigaBulanNumber) *
        100;
      let tempTotalLebihDariTigaBulanNumber =
        (allJualsTunggakkan.data[0].totalLebihDariTigaBulanNumber /
          allJuals.data[0].totalLebihDariTigaBulanNumber) *
        100;
      let tempTotalNumber =
        (allJualsTunggakkan.data[0].totalTotalNumber /
          allJuals.data[0].totalTotalNumber) *
        100;

      setTotalSatuBulanNumberNpl(tempTotalSatuBulanNumberNpl);
      setTotalDuaBulanNumberNpl(tempTotalDuaBulanNumber);
      setTotalTigaBulanNumberNpl(tempTotalTigaBulanNumber);
      setTotalLebihDariTigaBulanNumberNpl(tempTotalLebihDariTigaBulanNumber);
      setTotalTotalNumberNpl(tempTotalNumber);
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanNpl(false);
  };

  const getJualsKuantitasAllForDashboardCabangNpl = async () => {
    setLoadingLaporanNplKuantitas(true);
    try {
      const allJualsTunggakkan = await axios.post(
        `${tempUrl}/jualsKuantitasForDashboardCabangNpl`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      setDataBarNplKuantitas(allJualsTunggakkan.data);

      const allJuals = await axios.post(
        `${tempUrl}/jualsKuantitasAllForDashboardCabangNpl`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      setDataBarNplSemuaKuantitas(allJuals.data);

      let tempTotalSatuBulanNumberNpl =
        (allJualsTunggakkan.data[0].totalSatuBulanNumber /
          allJuals.data[0].totalSatuBulanNumber) *
        100;
      let tempTotalDuaBulanNumber =
        (allJualsTunggakkan.data[0].totalDuaBulanNumber /
          allJuals.data[0].totalDuaBulanNumber) *
        100;
      let tempTotalTigaBulanNumber =
        (allJualsTunggakkan.data[0].totalTigaBulanNumber /
          allJuals.data[0].totalTigaBulanNumber) *
        100;
      let tempTotalLebihDariTigaBulanNumber =
        (allJualsTunggakkan.data[0].totalLebihDariTigaBulanNumber /
          allJuals.data[0].totalLebihDariTigaBulanNumber) *
        100;
      let tempTotalNumber =
        (allJualsTunggakkan.data[0].totalTotalNumber /
          allJuals.data[0].totalTotalNumber) *
        100;

      setTotalSatuBulanNumberNplKuantitas(tempTotalSatuBulanNumberNpl);
      setTotalDuaBulanNumberNplKuantitas(tempTotalDuaBulanNumber);
      setTotalTigaBulanNumberNplKuantitas(tempTotalTigaBulanNumber);
      setTotalLebihDariTigaBulanNumberNplKuantitas(
        tempTotalLebihDariTigaBulanNumber
      );
      setTotalTotalNumberNplKuantitas(tempTotalNumber);
    } catch (e) {
      console.log(e);
    }
    setLoadingLaporanNplKuantitas(false);
  };

  return (
    <Box>
      <Typography color="#757575">Dashboard Cabang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Dashboard Cabang
      </Typography>
      <Typography sx={subTitleText}>
        Periode : {user.periode.namaPeriode}
      </Typography>
      <Divider sx={dividerStyle} />

      <Box sx={{ display: "none" }}>
        <Chart />
      </Box>

      <Paper elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>
          Perbandingan Laba Rugi Per Cabang
        </Typography>
        <Typography sx={detailText}>
          {namaPeriodeLalu} - {user.periode.namaPeriode}
        </Typography>
        <Box sx={graphContainer}>
          {loadingNeracaSaldoLabaRugiCabangBulan ? (
            <Loader />
          ) : (
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="400px"
              data={dataBarLabaRugiBulan}
              options={optionsLabaRugi}
            />
          )}
        </Box>
      </Paper>

      <Paper sx={contentSecondContainer} elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>
          Penerimaan Angsuran Per Cabang
        </Typography>
        <Typography sx={detailText}>
          {namaPeriodeLalu} - {user.periode.namaPeriode}
        </Typography>
        <Box sx={graphContainer}>
          <Box sx={graphWrapper}>
            <Typography sx={detailText}>Total</Typography>
            {loadingAngsuranForPenerimaanTotalCabang ? (
              <Loader />
            ) : (
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={dataBarAngsuranTotalBulan}
                options={optionsAngsuranTotal}
              />
            )}
          </Box>
          <Divider orientation="vertical" flexItem />
          <Divider />
          <Box sx={graphWrapper}>
            <Typography sx={detailText}>Bunga</Typography>
            {loadingAngsuranForPenerimaanBungaCabang ? (
              <Loader />
            ) : (
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={dataBarAngsuranBungaBulan}
                options={optionsAngsuranBunga}
              />
            )}
          </Box>
        </Box>
        <Box sx={graphContainer}></Box>
      </Paper>

      <Paper sx={contentSecondContainer} elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>
          Perbandingan Laporan Penjualan Cabang {user.cabang._id}
        </Typography>
        <Box>
          <Typography sx={detailText}>
            {namaPeriodeLalu} - {user.periode.namaPeriode}
          </Typography>
          {loadingBulanLalu ? (
            <Loader />
          ) : (
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="400px"
              data={dataBarPerbandinganPenjualanBulan}
              options={optionsPenjualanPerCabang}
            />
          )}
        </Box>
      </Paper>

      <Paper sx={contentSecondContainer} elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>
          Angsuran Per. Tgl {todayDate}
        </Typography>
        <Box sx={graphContainer}>
          <Box sx={graphWrapper}>
            <Typography sx={[detailText, dividerStyle]}>
              Angsuran Aktif (Rupiah)
            </Typography>
            <Box sx={graphContainer}>
              {loadingLaporanAngsuranAktif ? (
                <Loader />
              ) : (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={dataBarAngsuranAktif}
                  options={optionsAngsuranAktif}
                />
              )}
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Divider />
          <Box sx={graphWrapper}>
            <Typography sx={[detailText, dividerStyle]}>
              Angsuran Jatuh Tempo (Rupiah)
            </Typography>
            <Box sx={graphContainer}>
              {loadingLaporanJatuhTempo ? (
                <Loader />
              ) : (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={dataBarJatuhTempo}
                  options={optionsJatuhTempo}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={graphContainer}>
          <Box sx={graphWrapper}>
            <Typography sx={[detailText, dividerStyle]}>
              Angsuran Aktif (Quantity)
            </Typography>
            <Box sx={graphContainer}>
              {loadingLaporanAngsuranAktifKuantitas ? (
                <Loader />
              ) : (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={dataBarAngsuranAktifKuantitas}
                  options={optionsAngsuranAktifKuantitas}
                />
              )}
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Divider />
          <Box sx={graphWrapper}>
            <Typography sx={[detailText, dividerStyle]}>
              Angsuran Jatuh Tempo (Quantity)
            </Typography>
            <Box sx={graphContainer}>
              {loadingLaporanJatuhTempoKuantitas ? (
                <Loader />
              ) : (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={dataBarJatuhTempoKuantitas}
                  options={optionsJatuhTempoKuantitas}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Paper>

      <Paper sx={contentSecondContainer} elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>
          NPL {user.cabang.namaCabang} (Rupiah)
        </Typography>
        <Box sx={tableContainer}>
          {loadingLaporanNpl ? (
            <Loader />
          ) : (
            <ShowTableDashboardCabangNpl
              tunggakans={dataBarNpl}
              angsurans={dataBarNplSemua}
              totalSatuBulanNumberNpl={totalSatuBulanNumberNpl}
              totalDuaBulanNumberNpl={totalDuaBulanNumberNpl}
              totalTigaBulanNumberNpl={totalTigaBulanNumberNpl}
              totalLebihDariTigaBulanNumberNpl={
                totalLebihDariTigaBulanNumberNpl
              }
              totalTotalNumberNpl={totalTotalNumberNpl}
            />
          )}
        </Box>
      </Paper>

      <Paper sx={contentSecondContainer} elevation={20}>
        <Typography sx={[detailText, dividerStyle]}>
          NPL {user.cabang.namaCabang} (Quantity)
        </Typography>
        <Box sx={tableContainer}>
          {loadingLaporanNplKuantitas ? (
            <Loader />
          ) : (
            <ShowTableDashboardCabangNplKuantitas
              tunggakans={dataBarNplKuantitas}
              angsurans={dataBarNplSemuaKuantitas}
              totalTotalNumberNpl={totalTotalNumberNplKuantitas}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default DashboardCabang;

const contentSecondContainer = {
  mt: 4,
};

const subTitleText = {
  fontWeight: "900",
};

const detailText = {
  fontWeight: "900",
  textAlign: "center",
};

const dividerStyle = {
  pt: 4,
};

const graphContainer = {
  p: {
    xs: 0,
    sm: 6,
  },
  display: "flex",
  justifyContent: {
    sm: "space-around",
  },
  flexDirection: {
    xs: "column",
    sm: "column",
    md: "row",
  },
};

const graphWrapper = {
  pt: {
    xs: 4,
    sm: 0,
  },
  width: {
    xs: "300px",
    sm: "400px",
  },
};

const tableContainer = {
  p: 5,
  display: "flex",
  justifyContent: "center",
};
