import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Loader, ScrollToTop } from "../../../components";
import { Colors } from "../../../constants/styles";
import {
  Box,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Col, Row, Form } from "react-bootstrap";

const TampilJoinMarketing = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const idJoinMarketing = location.pathname.split("/")[3];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const [isFetchError, setIsFetchError] = useState(false);
  const [isMarketingExist, setIsMarketingExist] = useState(false);
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [namaMarketing, setNamaMarketing] = useState("");
  const [teleponMarketing, setTeleponMarketing] = useState("");
  const [marketingsData, setMarketingsData] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    id ? getMarketingById() : setIsMarketingExist(false);
  }, [id]);

  const getMarketingById = async () => {
    setLoading(true);
    if (id) {
      const pickedJoinMarketing = await axios.post(
        `${tempUrl}/marketings/${idJoinMarketing}`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      setIsMarketingExist(true);
      setKodeMarketing(pickedJoinMarketing.data.kodeMarketing);
      setNamaMarketing(pickedJoinMarketing.data.namaMarketing);
      setTeleponMarketing(pickedJoinMarketing.data.teleponMarketing);

      const pickedMarketing = await axios.post(`${tempUrl}/marketings/${id}`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setMarketingsData(pickedMarketing.data.joinMarketing);
    }
    setLoading(false);
  };

  const deleteJoinMarketing = async () => {
    try {
      setLoading(true);
      await axios.post(`${tempUrl}/deleteJoinMarketing/${id}`, {
        joinMarketing: marketingsData,
        kodeMarketing,
        namaMarketing,
        teleponMarketing,
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setLoading(false);
      navigate(`/marketing/${id}`);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(`/marketing/${id}`)}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Typography color="#757575">Master</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Marketing
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Tidak bisa dihapus karena sudah ada data!`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Nama Marketing data: ${namaMarketing}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Box sx={buttonModifierContainer}>
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteOutlineIcon />}
          sx={{ textTransform: "none" }}
          onClick={deleteJoinMarketing}
        >
          Hapus
        </Button>
      </Box>
      <Divider sx={dividerStyle} />
      {isMarketingExist && (
        <>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Marketing :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeMarketing} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Marketing :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaMarketing} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={teleponMarketing} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
    </Box>
  );
};

export default TampilJoinMarketing;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
