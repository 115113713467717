import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Button,
  ButtonGroup,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Row, Form } from "react-bootstrap";

const ProfilUser = () => {
  const { user, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Box>
      <Typography color="#757575">Utility</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Profil User
      </Typography>
      <Box sx={buttonModifierContainer}>
        <ButtonGroup variant="contained">
          <Button
            color="primary"
            startIcon={<EditIcon />}
            sx={{ textTransform: "none" }}
            onClick={() => {
              navigate(`/profilUser/${user._id}/edit`);
            }}
          >
            Ubah Password
          </Button>
        </ButtonGroup>
      </Box>
      <Divider sx={dividerStyle} />
      <Box sx={showDataContainer}>
        <Box sx={showDataWrapper}>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="3" style={textRight}>
                  Username :
                </Form.Label>
                <Col sm="9">
                  <Form.Control value={user.username} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="3" style={textRight}>
                  Tipe User :
                </Form.Label>
                <Col sm="9">
                  <Form.Control value={user.tipeUser} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="3" style={textRight}>
                  Periode :
                </Form.Label>
                <Col sm="9">
                  <Form.Control value={user.periode.namaPeriode} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Box>
        <Box sx={[showDataWrapper, secondWrapper]}>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="3" style={textRight}>
                  Kode Kwitansi :
                </Form.Label>
                <Col sm="9">
                  <Form.Control value={user.kodeKwitansi} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="3" style={textRight}>
                  No Terakhir :
                </Form.Label>
                <Col sm="9">
                  <Form.Control value={user.noTerakhir} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="3" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    value={`${user.cabang._id} - ${user.cabang.namaCabang}`}
                    disabled
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfilUser;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw",
  // },
};

const textFieldStyle = {
  display: "flex",
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
