import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Colors } from "../../../constants/styles";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Button,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Col, Row, Form } from "react-bootstrap";

const UbahRejectChild = () => {
  const { user, dispatch } = useContext(AuthContext);
  const { id, idRejectChild } = useParams();
  const navigate = useNavigate();
  const [noKKRejectAnak, setNoKKRejectAnak] = useState("");
  const [noKTPRejectAnak, setNoKTPRejectAnak] = useState("");
  const [namaRejectAnak, setNamaRejectAnak] = useState("");
  const [tlpRejectAnak, setTlpRejectAnak] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getRejectChildById();
  }, []);

  const getRejectChildById = async () => {
    if (id) {
      const response = await axios.post(
        `${tempUrl}/findAnakRejects/${idRejectChild}`,
        {
          id: user._id,
          token: user.token,
        }
      );
      setNoKKRejectAnak(response.data.noKKRejectAnak);
      setNoKTPRejectAnak(response.data.noKTPRejectAnak);
      setNamaRejectAnak(response.data.namaRejectAnak);
      setTlpRejectAnak(response.data.tlpRejectAnak);
    }
  };

  const updateRejectChild = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation =
      noKKRejectAnak.length === 0 ||
      noKTPRejectAnak.length === 0 ||
      namaRejectAnak.length === 0 ||
      tlpRejectAnak.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/updateAnakReject/${idRejectChild}`, {
          noKKRejectAnak,
          noKTPRejectAnak,
          namaRejectAnak,
          tlpRejectAnak,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate(`/daftarReject/reject/${id}/${idRejectChild}`);
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Box>
        <Typography color="#757575">Penjualan</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Terkait Reject
        </Typography>
        <Divider sx={dividerStyle} />
        <Paper sx={contentContainer} elevation={12}>
          <Form noValidate validated={validated} onSubmit={updateRejectChild}>
            <Box sx={textFieldContainer}>
              <Row>
                <Col sm={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Reject <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaRejectAnak}
                        required
                        onChange={(e) =>
                          setNamaRejectAnak(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Nama Reject harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={tlpRejectAnak}
                        type="number"
                        required
                        onChange={(e) =>
                          setTlpRejectAnak(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Telepon harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KK Reject <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noKKRejectAnak}
                        required
                        onChange={(e) =>
                          setNoKKRejectAnak(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        No. KK Reject harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KTP Reject <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noKTPRejectAnak}
                        required
                        onChange={(e) =>
                          setNoKTPRejectAnak(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        No. KTP Reject harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  navigate(`/daftarReject/reject/${id}/${idRejectChild}`)
                }
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Paper>
        <Divider sx={spacingTop} />
        {error && (
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={alertBox}>
              Data belum terisi semua!
            </Alert>
          </Snackbar>
        )}
      </Box>
    </>
  );
};

export default UbahRejectChild;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  mt: 4,
  // display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};