import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Autocomplete,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import SaveIcon from "@mui/icons-material/Save";

const TambahLeasing = () => {
  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [kodeLeasing, setKodeLeasing] = useState("");
  const [namaLeasing, setNamaLeasing] = useState("");
  const [alamatLeasing, setAlamatLeasing] = useState("");
  const [teleponLeasing, setTeleponLeasing] = useState("");
  const [picLeasing, setPicLeasing] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();

  const [coas, setCoas] = useState([]);
  const [coaLeasing, setCoaLeasing] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const coaOptions = coas.map((coa) => ({
    label: `${coa.kodeCOA} - ${coa.namaCOA}`,
  }));

  useEffect(() => {
    getCoasData();
    getNextKodeLeasing();
  }, []);

  const getNextKodeLeasing = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/leasingsNextKode`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKodeLeasing(response.data);
    setLoading(false);
  };

  const getCoasData = async (kodeUnit) => {
    setCoaLeasing("");
    const response = await axios.post(`${tempUrl}/COAs`, {
      id: user._id,
      token: user.token,
    });
    setCoas(response.data);
  };

  const saveLeasing = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation =
      namaLeasing.length === 0 ||
      picLeasing.length === 0 ||
      coaLeasing.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveLeasing`, {
          namaLeasing,
          alamatLeasing,
          teleponLeasing,
          picLeasing,
          coaLeasing: coaLeasing.split(" ", 1)[0],
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate("/leasing");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Box>
      <Typography color="#757575">Master</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah Leasing
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveLeasing}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Leasing :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeLeasing} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Leasing <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaLeasing}
                        required
                        onChange={(e) =>
                          setNamaLeasing(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Nama Leasing harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={alamatLeasing}
                        onChange={(e) =>
                          setAlamatLeasing(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={teleponLeasing}
                        type="number"
                        onChange={(e) =>
                          setTeleponLeasing(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      PIC <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={picLeasing}
                        onChange={(e) =>
                          setPicLeasing(e.target.value.toUpperCase())
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        PIC Leasing harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      COA Leasing <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={coaOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && coaLeasing.length === 0 && true}
                            helperText={
                              error &&
                              coaLeasing.length === 0 &&
                              "COA Leasing harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => setCoaLeasing(value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/leasing")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default TambahLeasing;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};