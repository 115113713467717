import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { formatDate, formatTime } from "../../../../constants/helper";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  ButtonGroup,
  Autocomplete,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Col, Row, Form } from "react-bootstrap";
import { Colors } from "../../../../constants/styles";

const CariLapPenjualanKreditPerTenor = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  let date = new Date();
  const [cabangInput, setCabangInput] = useState("");
  let [inputDariTgl, setInputDariTgl] = useState(date);
  let [inputSampaiTgl, setInputSampaiTgl] = useState(date);
  const [filterJenisStruk, setFilterJenisStruk] = useState("");
  const [allCabang, setAllCabang] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { screenSize } = useStateContext();

  const filterJenisStrukOption = [
    { label: "KM" },
    { label: "KU" },
    { label: "KL" },
  ];

  const cabangOptions = allCabang.map((user) => ({
    label: `${user._id} - ${user.namaCabang}`,
  }));

  useEffect(() => {
    if (user.tipeUser === "OWN") {
      getAllCabang();
    } else {
      setCabangInput(user.cabang._id);
    }
  }, []);

  const getAllCabang = async () => {
    setLoading(true);

    let kodeCabangDiKunci = "";

    for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
      if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
        kodeCabangDiKunci =
          setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
      }
    }

    const allCabang = await axios.post(`${tempUrl}/cabangsExclude`, {
      id: user._id,
      token: user.token,
      kodeCabangDiKunci: kodeCabangDiKunci,
    });
    setAllCabang(allCabang.data);
    setLoading(false);
  };

  const downloadPdf = async () => {
    setLoading(true);
    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);

    let jualsForLaporanPenjualanByKreditPerTenor;

    let tempTotal = 0;
    let tempHeight = 50;
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Laporan Penjualan Kredit Per Tenor`, 60, 30);
    doc.setFontSize(10);
    doc.text(`Dari Tanggal : ${dariTgl} s/d ${sampaiTgl}`, 15, 40);
    doc.text(
      `Cabang : ${cabangInput === "" ? "SEMUA CABANG" : cabangInput}`,
      15,
      45
    );
    doc.setFontSize(12);

    let kodeCabangDiKunci = "";

    for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
      if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
        kodeCabangDiKunci =
          setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
      }
    }

    let columns = [];
    let tempJual = [];

    if (cabangInput === "") {
      const allCabang = await axios.post(`${tempUrl}/cabangsExclude`, {
        id: user._id,
        token: user.token,
        kodeCabangDiKunci: kodeCabangDiKunci,
      });
      setAllCabang(allCabang.data);

      columns = [{ title: "Tenor", field: "_id" }];

      for (let cabang of allCabang.data) {
        let objectCabang = {
          title: cabang.namaCabang,
          field: cabang.namaCabang,
        };
        columns.push(objectCabang);
      }

      columns.push({
        title: "Total",
        field: "total",
      });

      jualsForLaporanPenjualanByKreditPerTenor = await axios.post(
        `${tempUrl}/jualsForLaporanPenjualanByKreditPerTenorSemuaCabang`,
        {
          dariTgl: inputDariTgl,
          sampaiTgl: inputSampaiTgl,
          filterJenisStruk,
          id: user._id,
          token: user.token,
          kodeCabangDiKunci: kodeCabangDiKunci,
        }
      );

      // Contain data
      tempJual = [].concat(jualsForLaporanPenjualanByKreditPerTenor.data);

      let temp = JSON.parse(
        JSON.stringify(jualsForLaporanPenjualanByKreditPerTenor.data[0])
      );

      // Add new Object Total in Array
      tempJual.push(temp);
      tempJual[tempJual.length - 1].tenor = "-";
      tempJual[tempJual.length - 1]._id = "TOTAL";
      tempJual[tempJual.length - 1].total = 0;

      // Count Total
      for (let cabang of allCabang.data) {
        tempJual[tempJual.length - 1][cabang.namaCabang] = 0;

        for (
          let i = 0;
          i < jualsForLaporanPenjualanByKreditPerTenor.data.length;
          i++
        ) {
          tempJual[tempJual.length - 1][cabang.namaCabang] +=
            jualsForLaporanPenjualanByKreditPerTenor.data[i][cabang.namaCabang];

          tempJual[tempJual.length - 1].total +=
            jualsForLaporanPenjualanByKreditPerTenor.data[i][cabang.namaCabang];
        }
      }
    } else {
      columns = [
        { title: "Tenor", field: "_id" },
        { title: "Total", field: "total" },
      ];

      jualsForLaporanPenjualanByKreditPerTenor = await axios.post(
        `${tempUrl}/jualsForLaporanPenjualanByKreditPerTenor`,
        {
          dariTgl: inputDariTgl,
          sampaiTgl: inputSampaiTgl,
          filterJenisStruk,
          id: user._id,
          token: user.token,
          kodeCabang: cabangInput,
        }
      );

      // tempJual = jualsForLaporanPenjualanByKreditPerTenor.data
      // Contain data
      tempJual = [].concat(jualsForLaporanPenjualanByKreditPerTenor.data);

      let temp = JSON.parse(
        JSON.stringify(jualsForLaporanPenjualanByKreditPerTenor.data[0])
      );

      // Add new Object Total in Array
      tempJual.push(temp);
      tempJual[tempJual.length - 1].tenor = "-";
      tempJual[tempJual.length - 1]._id = "TOTAL";
      tempJual[tempJual.length - 1].total = 0;

      for (
        let i = 0;
        i < jualsForLaporanPenjualanByKreditPerTenor.data.length;
        i++
      ) {
        tempJual[tempJual.length - 1].total +=
          jualsForLaporanPenjualanByKreditPerTenor.data[i].total;
      }
    }

    function alignCol(data) {
      data.cell.styles.halign = "right";

      if (data.row.raw._id === "TOTAL") {
        data.cell.styles.fillColor = Colors.grey400;
      }
    }

    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 50,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: tempJual,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didDrawPage: (d) => {
        tempHeight = d.cursor.y;
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
    });
    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan Penjualan Kredit Per Tenor
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Cabang :
              </Form.Label>
              {user.tipeUser === "OWN" ? (
                <Col sm="9">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={cabangOptions}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        error={error && cabangInput.length === 0 && true}
                        helperText={
                          error &&
                          cabangInput.length === 0 &&
                          "Cabang harus diisi!"
                        }
                        placeholder="SEMUA CABANG"
                        {...params}
                      />
                    )}
                    onInputChange={(e, value) => {
                      setCabangInput(value.split(" ", 1)[0]);
                    }}
                  />
                </Col>
              ) : (
                <Col sm="9">
                  <Form.Control value={cabangInput} disabled />
                </Col>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputDariTgl}
                  onChange={(e) => setInputDariTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputDariTgl === null && true}
                      helperText={
                        error &&
                        inputDariTgl === null &&
                        "Dari Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Sampai Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputSampaiTgl}
                  onChange={(e) => setInputSampaiTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputSampaiTgl === null && true}
                      helperText={
                        error &&
                        inputSampaiTgl === null &&
                        "Sampai Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Filter :
              </Form.Label>
              <Col sm="9">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={filterJenisStrukOption}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      error={error && filterJenisStruk.length === 0 && true}
                      helperText={
                        error &&
                        filterJenisStruk.length === 0 &&
                        "Filter harus diisi!"
                      }
                      {...params}
                    />
                  )}
                  onInputChange={(e, value) => setFilterJenisStruk(value)}
                  value={filterJenisStruk}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            color="secondary"
            startIcon={<PrintIcon />}
            onClick={() => {
              if (inputSampaiTgl === null || inputDariTgl === null) {
                setError(true);
              } else downloadPdf();
            }}
          >
            PDF
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default CariLapPenjualanKreditPerTenor;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw"
  // }
};
