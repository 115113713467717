import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Colors } from "../../../constants/styles";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { Col, Row, Form } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const KonsumenJatuhTempo = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  var date = new Date();
  const [id, setId] = useState("");
  const [tampilTunggakan, setTampilTunggakan] = useState(false);
  const [cabangInput, setCabangInput] = useState("");
  const [inputPerTanggal, setInputPerTanggal] = useState(date);
  const [lebihDari, setLebihDari] = useState("");
  const [sampaiDengan, setSampaiDengan] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [allCabang, setAllCabang] = useState([]);
  const [marketings, setMarketings] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [tunggakans, setTunggakans] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [noJual, setNoJual] = useState("");
  const [inputMd1, setInputMd1] = useState("");
  const [angsuranId, setAngsuranId] = useState("");
  const [openUbah, setOpenUbah] = useState(false);
  const [validated, setValidated] = useState(false);

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
  }));

  const surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  const cabangOptions = allCabang.map((user) => ({
    label: `${user._id} - ${user.namaCabang}`,
  }));

  const handleClickOpenUbah = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenUbah(true);
  };

  const handleCloseUbah = () => {
    setOpenUbah(false);
  };

  useEffect(() => {
    if (user.tipeUser === "OWN") {
      getMarketingAllCabang();
      getSurveyorAll();
      getAllCabang();
    } else {
      getMarketing();
      getSurveyor();
      setCabangInput(user.cabang._id);
    }
    setSampaiDengan("0");
    setLebihDari("0");
  }, []);

  const getAllCabang = async () => {
    setLoading(true);
    const allCabang = await axios.post(`${tempUrl}/cabangs`, {
      id: user._id,
      token: user.token,
    });
    setAllCabang(allCabang.data);
    setLoading(false);
  };

  const getMarketing = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/marketings`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setMarketings(response.data);
    setLoading(false);
  };

  const getMarketingAllCabang = async () => {
    setLoading(true);
    const allMarketings = await axios.post(`${tempUrl}/marketingsAllCabang`, {
      id: user._id,
      token: user.token,
    });
    setMarketings(allMarketings.data);
    setLoading(false);
  };

  const getSurveyor = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(response.data);
    setLoading(false);
  };

  const getSurveyorAll = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyorsAllCabang`, {
      id: user._id,
      token: user.token,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const searchKonsumenJatuhTempo = async () => {
    setLoading(true);
    let perTanggal = formatDate(inputPerTanggal);
    const tempMarketing = await axios.post(`${tempUrl}/marketingByKode`, {
      kodeMarketing: kodeMarketing.split(" ", 1)[0],
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
      kodeSurveyor: kodeSurveyor.split(" ", 1)[0],
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    const response = await axios.post(`${tempUrl}/jualsForTunggakan`, {
      perTanggal: inputPerTanggal,
      lebihDari,
      sampaiDengan,
      kodeMarketing: tempMarketing.data ? tempMarketing.data._id : null,
      kodeSurveyor: tempSurveyor.data ? tempSurveyor.data._id : null,
      id: user._id,
      token: user.token,
      kodeCabang: cabangInput,
    });
    setTunggakans(response.data);

    console.log(response.data);

    setLoading(false);
    setTampilTunggakan(true);
  };

  const updateJual = async (e) => {
    e.preventDefault();

    // let isFailedValidation = inputMd1.length === 0;
    let isFailedValidation = false;
    if (isFailedValidation) {
      setError(true);
      setOpenUbah(!openUbah);
    } else {
      try {
        await axios.post(`${tempUrl}/updateAngsuranMd1`, {
          jualData: {
            idJual: id,
            tglMd1: inputMd1,
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          },
          angsuranData: {
            noJual,
            tglMd1: inputMd1,
            angsuranId,
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          },
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });

        setInputMd1("");
        searchKonsumenJatuhTempo();
        handleCloseUbah(e);
      } catch (error) {
        alert(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Konsumen Jatuh Tempo
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Cabang :
              </Form.Label>
              {user.tipeUser === "OWN" ? (
                <Col sm="9">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={cabangOptions}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        error={error && cabangInput.length === 0 && true}
                        helperText={
                          error &&
                          cabangInput.length === 0 &&
                          "Cabang harus diisi!"
                        }
                        placeholder="SEMUA CABANG"
                        {...params}
                      />
                    )}
                    onInputChange={(e, value) => {
                      setCabangInput(value.split(" ", 1)[0]);
                    }}
                  />
                </Col>
              ) : (
                <Col sm="9">
                  <Form.Control value={cabangInput} disabled />
                </Col>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputPerTanggal}
                  onChange={(e) => setInputPerTanggal(e)}
                  customInput={
                    <TextField
                      error={error && inputPerTanggal === null && true}
                      helperText={
                        error &&
                        inputPerTanggal === null &&
                        "Dari Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Kode Marketing :
              </Form.Label>
              <Col sm="9">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={marketingOptions}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      error={error && kodeMarketing.length === 0 && true}
                      helperText={
                        error &&
                        kodeMarketing.length === 0 &&
                        "Kode Marketing harus diisi!"
                      }
                      placeholder="SEMUA MARKETING"
                      {...params}
                    />
                  )}
                  onInputChange={(e, value) => setKodeMarketing(value)}
                  value={kodeMarketing}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Kode Surveyor :
              </Form.Label>
              <Col sm="9">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={surveyorOptions}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      error={error && kodeSurveyor.length === 0 && true}
                      helperText={
                        error &&
                        kodeSurveyor.length === 0 &&
                        "Kode Surveyor harus diisi!"
                      }
                      placeholder="SEMUA SURVEYOR"
                      {...params}
                    />
                  )}
                  onInputChange={(e, value) => setKodeSurveyor(value)}
                  value={kodeSurveyor}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Lebih Dari :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="number"
                  required
                  value={lebihDari}
                  onChange={(e) => {
                    setLebihDari(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Sampai Dengan :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="number"
                  required
                  value={sampaiDengan}
                  onChange={(e) => {
                    setSampaiDengan(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <Button
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={() => searchKonsumenJatuhTempo()}
        >
          CARI
        </Button>
      </Box>
      {tampilTunggakan && (
        <Box sx={spacingTop}>
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table aria-label="simple table">
              <TableHead className={classes.root}>
                <TableRow>
                  <TableCell
                    sx={tableText}
                    className={classes.tableRightBorder}
                  >
                    No.
                  </TableCell>
                  <TableCell
                    sx={tableText}
                    className={classes.tableRightBorder}
                  >
                    No. Kontrak
                  </TableCell>
                  <TableCell
                    sx={tableText}
                    className={classes.tableRightBorder}
                  >
                    Nama Konsumen
                  </TableCell>
                  <TableCell
                    sx={tableText}
                    className={classes.tableRightBorder}
                  >
                    Alamat
                  </TableCell>
                  <TableCell
                    sx={tableText}
                    className={classes.tableRightBorder}
                  >
                    No. Telp / HP
                  </TableCell>
                  <TableCell
                    sx={tableText}
                    className={classes.tableRightBorder}
                  >
                    J. Tempo
                  </TableCell>
                  <TableCell
                    sx={tableText}
                    className={classes.tableRightBorder}
                  >
                    Md1
                  </TableCell>
                  <TableCell
                    sx={tableText}
                    className={classes.tableRightBorder}
                  >
                    Srt. Tarik
                  </TableCell>
                  <TableCell
                    sx={tableText}
                    className={classes.tableRightBorder}
                  >
                    Lama
                  </TableCell>
                  <TableCell
                    sx={tableText}
                    className={classes.tableRightBorder}
                  >
                    Bln
                  </TableCell>
                  <TableCell
                    sx={tableText}
                    className={classes.tableRightBorder}
                  >
                    Angsuran
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tunggakans.map((user, index) => {
                  return (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      component={Link}
                      onClick={(e) => {
                        setId(user._id);
                        setNoJual(user.noJual);
                        if (user.md1) {
                          setInputMd1(new Date(user.md1));
                        } else {
                          setInputMd1("");
                        }
                        setAngsuranId(user.tenor - user.sisaBulan);
                        handleClickOpenUbah(e);
                      }}
                      // to={`/daftarAngsuran/angsuran/${user.noJual}/${
                      //   user.tenor - user.sisaBulan + 1
                      // }`}
                    >
                      <TableCell component="th" scope="row" sx={tableText}>
                        {user.no}
                      </TableCell>
                      <TableCell sx={tableText}>{user.noJual}</TableCell>
                      <TableCell sx={tableText}>{user.namaRegister}</TableCell>
                      <TableCell sx={tableText}>{user.almRegister}</TableCell>
                      <TableCell sx={tableText}>{user.tlpRegister}</TableCell>
                      <TableCell sx={tableText}>{user.tglJatuhTempo}</TableCell>
                      <TableCell sx={tableText}>{user.tglMd1}</TableCell>
                      <TableCell sx={tableText}>{user.tglStTerakhir}</TableCell>
                      <TableCell sx={tableText}>{user.hr}</TableCell>
                      <TableCell sx={tableText}>{user.sisaBulan}</TableCell>
                      <TableCell sx={tableText}>{user.angPerBulan}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Dialog
        onClose={handleCloseUbah}
        aria-labelledby="customized-dialog-title"
        open={openUbah}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Ubah Konsumen Jatuh Tempo
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseUbah}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ height: "200px" }}>
          <div>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Md1 <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={inputMd1}
                      onChange={(e) => {
                        setInputMd1(e);
                      }}
                      customInput={
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          size="small"
                        />
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={updateJual}
          >
            Simpan
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default KonsumenJatuhTempo;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};

const tableText = {
  fontSize: "10px",
  fontWeight: "bold",
};

const colorRed = {
  color: "red",
};
