import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { ShowTableCustomerJualAngsuran } from "../../../components/ShowTable";
import { Loader, usePagination } from "../../../components";
import {
  Paper,
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  Chip,
  Card,
  CardHeader,
  CardActionArea,
  CardMedia,
  ButtonGroup,
  Link,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import Carousel from "react-elastic-carousel";
import EditIcon from "@mui/icons-material/Edit";

const TampilCustomerAngsuran = () => {
  const { user, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const idJual = location.pathname.split("/")[3];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "13px",
  };
  // Data Register/Pembeli
  const [noRegister, setNoRegister] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [almKantor, setAlmKantor] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [noKtpRegister, setNoKtpRegister] = useState("");
  const [noKKRegister, setNoKKRegister] = useState("");
  const [namaPjmRegister, setNamaPjmRegister] = useState("");
  const [noKtpPjmRegister, setNoKtpPjmRegister] = useState("");
  const [linkMapsRegister, setLinkMapsRegister] = useState("");
  const [linkMapsRegister2, setLinkMapsRegister2] = useState("");
  const [linkMapsRegister3, setLinkMapsRegister3] = useState("");
  const [email, setEmail] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [almRefRegister, setAlmRefRegister] = useState("");
  const [tlpRefRegister, setTlpRefRegister] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeSurveyorAsli, setKodeSurveyorAsli] = useState("");
  const [kodePekerjaan, setKodePekerjaan] = useState("");
  const [ketPekerjaan, setKetPekerjaan] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodeLeasing, setKodeLeasing] = useState("");

  // Data Motor -> Dari Stok
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [nopol, setNopol] = useState("");
  const [tipe, setTipe] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [tahun, setTahun] = useState("");

  // Data Penjualan -> dari input
  const [hargaTunai, setHargaTunai] = useState("");
  const [uangMuka, setUangMuka] = useState("");
  const [sisaPiutang, setSisaPiutang] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");
  const [tenor, setTenor] = useState("");
  const [sisaBulan, setSisaBulan] = useState("");
  const [bunga, setBunga] = useState("");
  const [jumlahPiutang, setJumlahPiutang] = useState("");
  const [angModal, setAngModal] = useState("");
  const [angBunga, setAngBunga] = useState("");
  const [noJual, setNoJual] = useState("");
  const [noKwitansi, setNoKwitansi] = useState("");
  const [tglJual, setTglJual] = useState("");
  const [tglJualDate, setTglJualDate] = useState();
  const [jenisJual, setJenisJual] = useState("");
  const [leasing, setLeasing] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [tglAngFormatted, setTglAngFormatted] = useState("");
  const [tglAngAkhir, setTglAngAkhir] = useState("");
  const [tglAngAkhirFormatted, setTglAngAkhirFormatted] = useState("");
  const [kodeAngsuran, setKodeAngsuran] = useState("");
  const [isPotong90Hari, setIsPotong90Hari] = useState(false);
  const [gambar, setGambar] = useState([]);
  const [isPost, setIsPost] = useState("");

  const [customersAngsuranData, setCustomersAngsuranData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const currentPosts = customersAngsuranData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const count = Math.ceil(customersAngsuranData.length / PER_PAGE);
  const _DATA = usePagination(customersAngsuranData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 5 },
  ];

  useEffect(() => {
    idJual && getJualById();
  }, [idJual]);

  const getJualById = async () => {
    if (idJual) {
      const response = await axios.post(`${tempUrl}/juals/${idJual}`, {
        id: user._id,
        token: user.token,
      });
      // Data Register/Pembeli
      setNoRegister(response.data.noRegister);
      setNamaRegister(response.data.namaRegister);
      setAlmRegister(response.data.almRegister);
      setAlmKantor(response.data.almKantor);
      setTlpRegister(response.data.tlpRegister);
      setNoKtpRegister(response.data.noKtpRegister);
      setNoKKRegister(response.data.noKKRegister);
      setNamaPjmRegister(response.data.namaPjmRegister);
      setNoKtpPjmRegister(response.data.noKtpPjmRegister);
      setLinkMapsRegister(response.data.linkMapsRegister);
      setLinkMapsRegister2(response.data.linkMapsRegister2);
      setLinkMapsRegister3(response.data.linkMapsRegister3);
      setEmail(response.data.email);
      setNamaRefRegister(response.data.namaRefRegister);
      setAlmRefRegister(response.data.almRefRegister);
      setTlpRefRegister(response.data.tlpRefRegister);
      setKodeMarketing(response.data.kodeMarketing);
      setKodeSurveyor(response.data.kodeSurveyor);
      setKodeSurveyorAsli(response.data.kodeSurveyorAsli);
      setKodePekerjaan(response.data.kodePekerjaan);
      setKetPekerjaan(response.data.ketPekerjaan);
      setKodeKecamatan(response.data.kodeKecamatan);
      setKodeLeasing(response.data.kodeLeasing);
      setKodeCOA(response.data.COA);

      // Data Motor -> Dari Stok
      setNoRangka(response.data.noRangka);
      setNoMesin(response.data.noMesin);
      setNopol(response.data.nopol);
      setTipe(response.data.tipe);
      setNamaWarna(response.data.namaWarna);
      setTahun(response.data.tahun);

      // Data Penjualan -> dari input
      setHargaTunai(response.data.hargaTunai);
      setUangMuka(response.data.uangMuka);
      setSisaPiutang(response.data.sisaPiutang);
      setAngPerBulan(response.data.angPerBulan);
      setTenor(response.data.tenor);
      setSisaBulan(response.data.sisaBulan);
      setBunga(response.data.bunga);
      setJumlahPiutang(response.data.jumlahPiutang);
      setAngModal(response.data.angModal);
      setAngBunga(response.data.angBunga);
      setNoJual(response.data.noJual);
      setNoKwitansi(response.data.noKwitansi);
      setTglJual(formatDate(response.data.tanggalJual));
      setTglJualDate(new Date(response.data.tanggalJual));
      setJenisJual(response.data.jenisJual);
      setLeasing(response.data.leasing);
      setTglAng(response.data.tglAng);
      setTglAngFormatted(formatDate(response.data.tglAng));
      setTglAngAkhir(response.data.tglAngAkhir);
      setTglAngAkhirFormatted(formatDate(response.data.tglAngAkhir));
      setKodeAngsuran(response.data.kodeAngsuran);
      setIsPotong90Hari(response.data.isPotong90Hari);
      setGambar(response.data.gambar);
      setIsPost(response.data.isPost);

      const response2 = await axios.post(`${tempUrl}/angsuransByNoJual`, {
        noJual: response.data.noJual,
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setCustomersAngsuranData(response2.data.angsuran);
    }
  };

  const updateJualIsPotong90Hari = async (e) => {
    e.preventDefault();
    setLoading(true);
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    try {
      await axios.post(`${tempUrl}/updateJualIsPotong90Hari/${idJual}`, {
        isPotong90Hari: !isPotong90Hari,
        tglUpdate: current_date,
        jamUpdate: current_time,
        userUpdate: user.username,
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setLoading(false);
      navigate(`/customer/${id}/${idJual}`);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      console.log(error);
    }

    getJualById();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(`/customer/${id}`)}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box>
        <Typography color="#757575">Penjualan</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Customer Penjualan
        </Typography>

        <Box sx={buttonModifierContainer}>
          <ButtonGroup variant="contained">
            <Button
              color="primary"
              startIcon={<EditIcon />}
              sx={{ textTransform: "none" }}
              onClick={() => {
                navigate(`/customer/${id}/${idJual}/edit`);
              }}
            >
              Ubah Foto
            </Button>
          </ButtonGroup>
        </Box>

        <Box sx={{ width: "300px", marginTop: "10px" }}>
          <ButtonGroup variant="contained">
            <Button
              variant="contained"
              color="secondary"
              sx={{ textTransform: "none" }}
              onClick={updateJualIsPotong90Hari}
            >
              Potong 90 Hari
            </Button>
            <Form.Control
              value={isPotong90Hari === true ? "Sudah" : "Belum"}
              disabled
            />
          </ButtonGroup>
        </Box>

        <Divider sx={dividerStyle} />

        {gambar.length !== 0 && (
          <Carousel breakPoints={breakPoints} sx={carouselStyle}>
            {gambar.length !== 0 &&
              gambar.map((img, i) => (
                <Card sx={carouselCard} elevation={10}>
                  <CardActionArea>
                    <CardHeader title={`Gambar`} subheader={`No. ${i + 1}`} />
                    <CardMedia
                      component="img"
                      height="100%"
                      src={img}
                      alt={namaRegister}
                      sx={oldImageCardMedia}
                      onClick={() => {
                        window.open(img, "_blank", "noopener,noreferrer");
                      }}
                    />
                  </CardActionArea>
                </Card>
              ))}
          </Carousel>
        )}

        <Divider sx={dividerStyle} />

        {/* Data Penjualan */}
        <Paper elevation={6} sx={mainContainer}>
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA PENJUALAN
          </Typography>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Kwitansi :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noKwitansi} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tanggal Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Jenis Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={jenisJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      COA :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${kodeCOA.kodeCOA} - ${kodeCOA.namaCOA}`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </Paper>

        {/* Data Customer */}
        <Paper elevation={6} sx={mainContainer}>
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA CUSTOMER
          </Typography>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Rumah :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Kantor :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almKantor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tlpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KTP Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noKtpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KK Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noKKRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Nama Penjamin Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaPjmRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      No. KTP Penjamin Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noKtpPjmRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Link Maps Register :
                    </Form.Label>
                    <Col sm="8">
                      <Link href={linkMapsRegister}>Link Google Maps</Link>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Link Maps Register 2 :
                    </Form.Label>
                    <Col sm="8">
                      <Link href={linkMapsRegister2}>Link Google Maps</Link>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Link Maps Register 3 :
                    </Form.Label>
                    <Col sm="8">
                      <Link href={linkMapsRegister3}>Link Google Maps</Link>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Nama Ref. Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRefRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Alamat Ref. Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almRefRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Telepon Ref. Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tlpRefRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Marketing :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${kodeMarketing.kodeMarketing} - ${kodeMarketing.namaMarketing}`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Surveyor :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${kodeSurveyor.kodeSurveyor} - ${kodeSurveyor.namaSurveyor}`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Surveyor Asli :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${kodeSurveyorAsli.kodeSurveyor} - ${kodeSurveyorAsli.namaSurveyor}`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Pekerjaan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${kodePekerjaan.kodePekerjaan} - ${kodePekerjaan.namaPekerjaan}`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}></Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={ketPekerjaan}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Kecamatan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${kodeKecamatan.kodeKecamatan} - ${kodeKecamatan.namaKecamatan}`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Leasing :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${kodeLeasing.kodeLeasing} - ${kodeLeasing.namaLeasing}`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Email :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={email} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </Paper>

        {/* Data Motor */}
        <Paper elevation={6} sx={mainContainer}>
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA MOTOR
          </Typography>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Rangka :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRangka} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Mesin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noMesin} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tipe :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tipe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Warna :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaWarna} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tahun Perakitan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tahun} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </Paper>

        {/* Data Rincian Harga (Input) */}
        <Paper elevation={6} sx={mainContainer}>
          <Typography variant="h5" sx={titleStyle} color="primary">
            RINCIAN HARGA
          </Typography>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Tanggal Angsuran I :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglAngFormatted} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Harga Tunai :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        //type="number"
                        value={hargaTunai.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Uang Muka :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        //type="number"
                        value={uangMuka.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Sisa Piutang :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={sisaPiutang.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRightSmall}>
                      Tanggal Angsuran Akhir :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglAngAkhirFormatted} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Angsuran/Bulan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        //type="number"
                        value={angPerBulan.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tenor :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control type="number" value={tenor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Total Piutang :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={jumlahPiutang.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </Paper>

        <hr />
        <Chip
          label="Percepatan"
          sx={{
            backgroundColor: Colors.blue100,
          }}
        />
        <Chip
          label="Percepatan Dibayar dari Cabang Lain"
          sx={{
            backgroundColor: Colors.green400,
          }}
        />
        <Chip
          label="Dibayar dari Cabang Lain"
          sx={{
            backgroundColor: Colors.yellow100,
          }}
        />
        <Chip
          label="Penarikan"
          sx={{
            backgroundColor: Colors.red400,
          }}
        />
        <Box sx={tableContainer}>
          <ShowTableCustomerJualAngsuran
            id={id}
            idJual={idJual}
            currentPosts={currentPosts}
          />
        </Box>
        <Box sx={tableContainer}>
          <Pagination
            count={count}
            page={page}
            onChange={handleChange}
            color="primary"
            size={screenSize <= 600 ? "small" : "large"}
          />
        </Box>
      </Box>
    </>
  );
};

export default TampilCustomerAngsuran;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw"
  // }
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const carouselStyle = {
  display: "flex",
  height: "200px",
};

const carouselCard = {
  m: "auto",
  mt: 2,
  maxWidth: "500px",
  maxHeight: "500px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "primary",
};

const oldImageCardMedia = {
  display: "flex",
  maxHeight: "150px",
};
