import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Pagination,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahTakeover = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  var date = new Date();

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [juals, setJuals] = useState([]);
  // Customer
  const [noTakeover, setNoTakeover] = useState(""); // I
  const [inputTglTakeover, setInputTglTakeover] = useState(date); // I
  const [idJual, setIdJual] = useState(""); // I
  const [noJual, setNoJual] = useState(""); // I
  const [noRegisterDari, setNoRegisterDari] = useState("");
  const [namaRegisterDari, setNamaRegisterDari] = useState("");
  const [noKtpRegisterDari, setNoKtpRegisterDari] = useState("");
  const [keterangan, setKeterangan] = useState("");

  // Data Register/Pembeli
  const [registers, setRegisters] = useState([]);
  const [noRegister, setNoRegister] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [almKantor, setAlmKantor] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [noKtpRegister, setNoKtpRegister] = useState("");
  const [noKKRegister, setNoKKRegister] = useState("");
  const [namaPjmRegister, setNamaPjmRegister] = useState("");
  const [noKtpPjmRegister, setNoKtpPjmRegister] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [almRefRegister, setAlmRefRegister] = useState("");
  const [tlpRefRegister, setTlpRefRegister] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeMarketingLama, setKodeMarketingLama] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeSurveyorLama, setKodeSurveyorLama] = useState("");
  const [kodePekerjaan, setKodePekerjaan] = useState("");
  const [kodePekerjaanLama, setKodePekerjaanLama] = useState("");
  const [ketPekerjaan, setKetPekerjaan] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodeKecamatanLama, setKodeKecamatanLama] = useState("");
  const [kodeLeasing, setKodeLeasing] = useState("");
  const [kodeLeasingLama, setKodeLeasingLama] = useState("");
  const [marketings, setMarketings] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [pekerjaans, setPekerjaans] = useState([]);
  const [kecamatans, setKecamatans] = useState([]);
  const [leasings, setLeasings] = useState([]);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTermJual, setSearchTermJual] = useState("");
  const [openJual, setOpenJual] = useState(false);
  const [searchTermRegister, setSearchTermRegister] = useState("");
  const [openRegister, setOpenRegister] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");
  let [pageRegister, setPageRegister] = useState(0);
  const [limitRegister, setLimitRegister] = useState(10);
  const [pagesRegister, setPagesRegister] = useState(0);
  const [rowsRegister, setRowsRegister] = useState(0);
  const [queryRegister, setQueryRegister] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const handleChangeRegister = (e, p) => {
    setPageRegister(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermJual(query);
  };

  const searchDataRegister = (e) => {
    e.preventDefault();
    setPageRegister(0);
    setSearchTermRegister(queryRegister);
  };

  const classes = useStyles();

  const handleClickOpenJual = () => {
    setOpenJual(true);
  };

  const handleCloseJual = () => {
    setOpenJual(false);
  };

  const handleClickOpenRegister = () => {
    setOpenRegister(true);
  };

  const handleCloseRegister = () => {
    setOpenRegister(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const marketingOptions = marketings.map((marketing) => ({
    value: marketing.kodeMarketing,
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
  }));

  const surveyorOptions = surveyors.map((surveyor) => ({
    value: surveyor.kodeSurveyor,
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  const pekerjaanOptions = pekerjaans.map((pekerjaan) => ({
    value: pekerjaan.kodePekerjaan,
    label: `${pekerjaan.kodePekerjaan} - ${pekerjaan.namaPekerjaan}`,
  }));

  const kecamatanOptions = kecamatans.map((kecamatan) => ({
    value: kecamatan.kodeKecamatan,
    label: `${kecamatan.kodeKecamatan} - ${kecamatan.namaKecamatan}`,
  }));

  const leasingOptions = leasings.map((leasing) => ({
    value: leasing.kodeLeasing,
    label: `${leasing.kodeLeasing} - ${leasing.namaLeasing}`,
  }));

  useEffect(() => {
    getRegister();
    getTakeoverNextKode(inputTglTakeover);
    getMarketing();
    getSurveyor();
    getPekerjaan();
    getKecamatan();
    getLeasing();
  }, []);

  useEffect(() => {
    getJual();
  }, [page, searchTermJual]);

  useEffect(() => {
    getRegister();
  }, [pageRegister, searchTermRegister]);

  const getRegister = async () => {
    const allRegisters = await axios.post(
      `${tempUrl}/registersPagination?search_query=${searchTermRegister}&page=${pageRegister}&limit=${limitRegister}`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setQueryRegister(searchTermRegister);
    setRegisters(allRegisters.data.registers);
    setPageRegister(allRegisters.data.page);
    setPagesRegister(allRegisters.data.totalPage);
    setRowsRegister(allRegisters.data.totalRows);
  };

  const getTakeoverNextKode = async (date) => {
    const nextKodeTakeover = await axios.post(`${tempUrl}/takeoversNextKode`, {
      tglTakeover: date,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setNoTakeover(nextKodeTakeover.data);
  };

  const getMarketing = async () => {
    setLoading(true);
    const allMarketings = await axios.post(`${tempUrl}/marketings`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setMarketings(allMarketings.data);
    setLoading(false);
  };

  const getSurveyor = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const getPekerjaan = async () => {
    setLoading(true);
    const allPekerjaans = await axios.post(`${tempUrl}/pekerjaans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setPekerjaans(allPekerjaans.data);
    setLoading(false);
  };

  const getKecamatan = async () => {
    setLoading(true);
    const allKecamatans = await axios.post(`${tempUrl}/kecamatans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKecamatans(allKecamatans.data);
    setLoading(false);
  };

  const getLeasing = async () => {
    setLoading(true);
    const allLeasings = await axios.post(`${tempUrl}/leasings`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setLeasings(allLeasings.data);
    setLoading(false);
  };

  const getJual = async () => {
    const allJualsPenarikan = await axios.post(
      `${tempUrl}/jualsForAngsuranAktifPagination?search_query=${searchTermJual}&page=${page}&limit=${limit}`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setJuals(allJualsPenarikan.data.juals);
    setPage(allJualsPenarikan.data.page);
    setPages(allJualsPenarikan.data.totalPage);
    setRows(allJualsPenarikan.data.totalRows);
  };

  const saveTakeover = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation =
      noTakeover.length === 0 ||
      inputTglTakeover === null ||
      noJual.length === 0 ||
      kodeMarketing.length === 0 ||
      kodeSurveyor.length === 0 ||
      kodePekerjaan.length === 0 ||
      kodeKecamatan.length === 0 ||
      kodeLeasing.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        // Find Jual
        const response = await axios.post(`${tempUrl}/jualByNoJual`, {
          noJual,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        // Save Point Kembali
        let savedTakeover = await axios.post(`${tempUrl}/saveTakeover`, {
          noTakeover,
          tanggalTakeover: inputTglTakeover,
          noJual,
          keterangan,
          noRegisterDari: noRegisterDari,
          noRegisterKe: noRegister,
          namaRegisterDari: namaRegisterDari,
          namaRegisterKe: namaRegister,
          noKtpRegisterDari: noKtpRegisterDari,
          noKtpRegisterKe: noKtpRegister,
          idJual: response.data._id,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });

        const tempMarketing = await axios.post(`${tempUrl}/marketingByKode`, {
          kodeMarketing: kodeMarketing.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
          kodeSurveyor: kodeSurveyor.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const tempPekerjaan = await axios.post(`${tempUrl}/pekerjaanByKode`, {
          kodePekerjaan: kodePekerjaan.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const tempKecamatan = await axios.post(`${tempUrl}/kecamatanByKode`, {
          kodeKecamatan: kodeKecamatan.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const tempLeasing = await axios.post(`${tempUrl}/leasingByKode`, {
          kodeLeasing: kodeLeasing.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        // Update Customer Jual
        await axios.post(`${tempUrl}/updateJual/${idJual}`, {
          noRegister,
          namaRegister,
          almRegister,
          almKantor,
          tlpRegister,
          noKtpRegister,
          noKKRegister,
          namaPjmRegister,
          noKtpPjmRegister,
          namaRefRegister,
          almRefRegister,
          tlpRefRegister,
          kodeMarketing: tempMarketing.data._id,
          kodeSurveyor: tempSurveyor.data._id,
          kodePekerjaan: tempPekerjaan.data._id,
          ketPekerjaan,
          kodeKecamatan: tempKecamatan.data._id,
          kodeLeasing: tempLeasing.data._id,
          tglUpdate: current_date,
          jamUpdate: current_time,
          userUpdate: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        await axios.post(`${tempUrl}/saveAndCheckCustomer`, {
          noRegister,
          namaRegister,
          almRegister,
          almKantor,
          tlpRegister,
          noKtpRegister,
          noKKRegister,
          namaPjmRegister,
          noKtpPjmRegister,
          namaRefRegister,
          almRefRegister,
          tlpRefRegister,
          kodeMarketing: tempMarketing.data._id,
          kodeSurveyor: tempSurveyor.data._id,
          kodePekerjaan: tempPekerjaan.data._id,
          ketPekerjaan,
          kodeKecamatan: tempKecamatan.data._id,
          kodeLeasing: tempLeasing.data._id,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate("/takeover");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Jual</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah Takeover
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveTakeover}>
          {/* Data Point Kembali */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA TAKEOVER
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Takeover <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noTakeover} disabled readOnly />
                        <Form.Control.Feedback type="invalid">
                          No. Takeover harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Takeover <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={inputTglTakeover}
                          onChange={(e) => {
                            setInputTglTakeover(e);
                          }}
                          customInput={
                            <TextField
                              error={error && inputTglTakeover === null && true}
                              helperText={
                                error &&
                                inputTglTakeover === null &&
                                "Tgl. Takeover harus diisi!"
                              }
                              sx={{ width: "100%" }}
                              size="small"
                            />
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Jual <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noJual}
                          required
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenJual()}
                          onChange={(e) =>
                            setNoTakeover(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          No. Jual harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={keterangan}
                          onChange={(e) => {
                            setKeterangan(e.target.value.toUpperCase());
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Register Dari :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noRegisterDari} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Dari :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaRegisterDari} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KTP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noKtpRegisterDari} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Customer Baru */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA CUSTOMER BARU
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Register <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noRegister}
                          required
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenRegister()}
                        />
                        <Form.Control.Feedback type="invalid">
                          Kode Register harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaRegister}
                          onChange={(e) =>
                            setNamaRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Rumah :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={almRegister}
                          onChange={(e) =>
                            setAlmRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Kantor :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={almKantor}
                          onChange={(e) =>
                            setAlmKantor(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Telepon Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tlpRegister}
                          onChange={(e) =>
                            setTlpRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KTP Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noKtpRegister}
                          onChange={(e) =>
                            setNoKtpRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noKKRegister}
                          onChange={(e) =>
                            setNoKKRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Penjamin Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaPjmRegister}
                          onChange={(e) =>
                            setNamaPjmRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KTP Penjamin Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noKtpPjmRegister}
                          onChange={(e) =>
                            setNoKtpPjmRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaRefRegister}
                          onChange={(e) =>
                            setNamaRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={almRefRegister}
                          onChange={(e) =>
                            setAlmRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Telepon Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tlpRefRegister}
                          onChange={(e) =>
                            setTlpRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Marketing <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={marketingOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error && kodeMarketing.length === 0 && true
                              }
                              helperText={
                                error &&
                                kodeMarketing.length === 0 &&
                                "Kode Marketing harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodeMarketing(value)}
                          inputValue={kodeMarketing}
                          onChange={(e, value) => setKodeMarketingLama(value)}
                          value={kodeMarketingLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Surveyor <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={surveyorOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && kodeSurveyor.length === 0 && true}
                              helperText={
                                error &&
                                kodeSurveyor.length === 0 &&
                                "Kode Surveyor harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodeSurveyor(value)}
                          inputValue={kodeSurveyor}
                          onChange={(e, value) => setKodeSurveyorLama(value)}
                          value={kodeSurveyorLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Pekerjaan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={pekerjaanOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error && kodePekerjaan.length === 0 && true
                              }
                              helperText={
                                error &&
                                kodePekerjaan.length === 0 &&
                                "Kode Pekerjaan harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodePekerjaan(value)}
                          inputValue={kodePekerjaan}
                          onChange={(e, value) => setKodePekerjaanLama(value)}
                          value={kodePekerjaanLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}></Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={ketPekerjaan}
                          onChange={(e) => {
                            setKetPekerjaan(e.target.value.toUpperCase());
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kecamatan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={kecamatanOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error && kodeKecamatan.length === 0 && true
                              }
                              helperText={
                                error &&
                                kodeKecamatan.length === 0 &&
                                "Kode Kecamatan harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodeKecamatan(value)}
                          inputValue={kodeKecamatan}
                          onChange={(e, value) => setKodeKecamatanLama(value)}
                          value={kodeKecamatanLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Leasing <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={leasingOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && kodeLeasing.length === 0 && true}
                              helperText={
                                error &&
                                kodeLeasing.length === 0 &&
                                "Kode Leasing harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodeLeasing(value)}
                          inputValue={kodeLeasing}
                          onChange={(e, value) => setKodeLeasingLama(value)}
                          value={kodeLeasingLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/takeover")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openJual}
        onClose={handleCloseJual}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Jual`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Jual
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nama Register
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tanggal
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Plat
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Alamat</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {juals.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIdJual(user._id);
                        setNoJual(user.noJual);
                        setNoRegisterDari(user.noRegister);
                        setNamaRegisterDari(user.namaRegister);
                        setNoKtpRegisterDari(user.noKtpRegister);

                        handleCloseJual();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noJual}
                      </TableCell>
                      <TableCell>{user.namaRegister}</TableCell>
                      <TableCell>{user.tanggalJualFormatted}</TableCell>
                      <TableCell>{user.nopol}</TableCell>
                      <TableCell>{user.almRegister}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRegister}
        onClose={handleCloseRegister}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Pembeli`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchDataRegister}>
              <Box sx={searchBarContainer}>
                <SearchBar
                  value={queryRegister}
                  setSearchTerm={setQueryRegister}
                />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Register
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tanggal
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Customer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {registers.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNoRegister(user.noRegister);
                        setNamaRegister(user.namaRegister);
                        setAlmRegister(user.almRegister);
                        setAlmKantor(user.almRegister);
                        setTlpRegister(user.tlpRegister);
                        setNoKtpRegister(user.noKtpRegister);
                        setNoKKRegister(user.noKKRegister);
                        setNamaPjmRegister(user.namaPjmRegister);
                        setNoKtpPjmRegister(user.noKtpPjmRegister);
                        setNamaRefRegister(user.namaRefRegister);
                        setAlmRefRegister(user.almRefRegister);
                        setTlpRefRegister(user.tlpRefRegister);
                        handleCloseRegister();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noRegister}
                      </TableCell>
                      <TableCell>{user.tanggalRegister}</TableCell>
                      <TableCell>{user.namaRegister}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pagesRegister}
                page={pageRegister + 1}
                onChange={handleChangeRegister}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TambahTakeover;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
