import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader, ScrollToTop } from "../../../components";
import { Paper, Box, Typography, Button, Divider } from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import SaveIcon from "@mui/icons-material/Save";

const UbahDaftarStok = () => {
  let navigate = useNavigate();
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isDaftarStoksExist, setIsDaftarStoksExist] = useState(false);
  const [noBeli, setNoBeli] = useState("");
  const [tanggalBeli, setTanggalBeli] = useState("");
  const [supplier, setSupplier] = useState("");
  const [merk, setMerk] = useState("");
  const [tipe, setTipe] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [nopol, setNopol] = useState("");
  const [nopolLama, setNopolLama] = useState("");
  const [namaStnk, setNamaStnk] = useState("");
  const [noBpkb, setNoBpkb] = useState("");
  const [alamatStnk, setAlamatStnk] = useState("");
  const [tglStnk, setTglStnk] = useState("");
  const [jenisBeli, setJenisBeli] = useState("");
  const [hargaSatuan, setHargaSatuan] = useState("");
  const [tanggalJual, setTanggalJual] = useState("");
  const [noJual, setNoJual] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [tahun, setTahun] = useState("");
  const [totalBiayaPerawatan, setTotalBiayaPerawatan] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "13px",
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    id ? getDaftarStokById() : setIsDaftarStoksExist(false);
  }, [id]);

  const getDaftarStokById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/daftarStoks/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsDaftarStoksExist(true);
      setNoBeli(response.data.noBeli);
      setTanggalBeli(formatDate(response.data.tanggalBeli));
      setSupplier(response.data.supplier);
      setMerk(response.data.merk);
      setTipe(response.data.tipe);
      setNoRangka(response.data.noRangka);
      setNoMesin(response.data.noMesin);
      setNopol(response.data.nopol);
      setNopolLama(response.data.nopol);
      setNamaWarna(response.data.namaWarna);
      setTahun(response.data.tahun);
      setNamaStnk(response.data.namaStnk);
      setNoBpkb(response.data.noBpkb);
      setAlamatStnk(response.data.alamatStnk);
      setTglStnk(formatDate(response.data.tglStnk));
      setJenisBeli(response.data.jenisBeli);
      setHargaSatuan(response.data.hargaSatuan);
      setTanggalJual(response.data.tanggalJual);
      setNoJual(response.data.noJual);
      setTotalBiayaPerawatan(response.data.totalBiayaPerawatan);
    }
    setLoading(false);
  };

  const updateDaftarStok = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation = nopol.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/updateJual/${id}`, {
          noRangka,
          noMesin,
          nopolLama,
          nopol,
          tglUpdate: current_date,
          jamUpdate: current_time,
          userUpdate: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        // Update Stok
        await axios.post(`${tempUrl}/updateDaftarStokByNopol`, {
          noRangka,
          noMesin,
          nopolLama,
          nopol,
          id: user._id,
          token: user.token,
        });
        // Update Biaya Perawatan
        await axios.post(`${tempUrl}/updateBiayaPerawatanByNoJual`, {
          noRangka,
          noMesin,
          nopolLama,
          nopol,
          id: user._id,
          token: user.token,
        });
        // Update Motor Hilang
        await axios.post(`${tempUrl}/updateMotorHilangByNoJual`, {
          noRangka,
          noMesin,
          nopolLama,
          nopol,
          noJual,
          id: user._id,
          token: user.token,
        });
        // Update Sweeping
        await axios.post(`${tempUrl}/updateSweepingByNoJual`, {
          noRangka,
          noMesin,
          nopolLama,
          nopol,
          noJual,
          id: user._id,
          token: user.token,
        });
        // Update Titip
        await axios.post(`${tempUrl}/updateTitipByNoJual`, {
          noRangka,
          noMesin,
          nopolLama,
          nopol,
          noJual,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate(`/daftarStok/${id}`);
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Stok</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Ubah Daftar Stok
      </Typography>
      <Divider sx={{ marginTop: 1 }} />
      <Form noValidate validated={validated} onSubmit={updateDaftarStok}>
        <Paper sx={contentContainer} elevation={12}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      No. Beli :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={noBeli} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tanggal Beli :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tanggalBeli} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Supplier :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={`${supplier ? supplier.kodeSupplier : ""} - ${
                          supplier ? supplier.namaSupplier : ""
                        }
                      `}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Merk :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={merk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tipe :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tipe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nama Warna :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={namaWarna} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tahun :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tahun} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRightSmall}>
                      Total Biaya Perawatan :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={totalBiayaPerawatan.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      No. Rangka <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={noRangka}
                        required
                        onChange={(e) =>
                          setNoRangka(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      No. Mesin <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={noMesin}
                        required
                        onChange={(e) =>
                          setNoMesin(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nopol <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={nopol}
                        required
                        onChange={(e) => setNopol(e.target.value.toUpperCase())}
                      />
                      <Form.Control.Feedback type="invalid">
                        Nopol harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nama STNK :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={namaStnk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      No. Bpkb :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={noBpkb} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Alamat STNK :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={alamatStnk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tanggal STNK :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tglStnk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Jenis Beli :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={jenisBeli} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Harga Satuan :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={hargaSatuan.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/daftarStok/${id}`)}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Paper>
      </Form>
      <Divider sx={dividerStyle} />
    </Box>
  );
};

export default UbahDaftarStok;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
};
