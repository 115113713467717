import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { ShowTableSp1 } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, usePagination } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  ButtonGroup,
  Button,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const TampilSp1 = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [isSpsExist, setIsSpsExist] = useState(false);
  const [noJual, setNoJual] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [tglAngDate, setTglAngDate] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [tenor, setTenor] = useState("");
  const [bulan, setBulan] = useState("");
  const [sisaBulan, setSisaBulan] = useState("");
  const [angsuranKe, setAngsuranKe] = useState("");
  const [tglSp, setTglSp] = useState("");
  const [spKe, setSpKe] = useState("");

  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [tipe, setTipe] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [nopol, setNopol] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [spsData, setSpsData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const tempPosts = spsData.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (
      val.noJual.toUpperCase().includes(searchTerm.toUpperCase()) ||
      val.idJual.namaRegister
        .toUpperCase()
        .includes(searchTerm.toUpperCase()) ||
      val.idJual.almRegister.toUpperCase().includes(searchTerm.toUpperCase()) ||
      val.idJual.tglAng.toUpperCase().includes(searchTerm.toUpperCase()) ||
      val.tglSp.toUpperCase().includes(searchTerm.toUpperCase()) ||
      val.idJual.nopol.toUpperCase().includes(searchTerm.toUpperCase())
    ) {
      return val;
    }
  });
  const currentPosts = tempPosts.slice(indexOfFirstPost, indexOfLastPost);

  const count = Math.ceil(tempPosts.length / PER_PAGE);
  const _DATA = usePagination(spsData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    id ? getSp1ById() : setIsSpsExist(false);
  }, [id]);

  useEffect(() => {
    getSp1sData();
  }, []);

  const getSp1sData = async () => {
    setLoading(true);
    try {
      const allSp1s = await axios.post(`${tempUrl}/sp1s`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setSpsData(allSp1s.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getSp1ById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/sp1s/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsSpsExist(true);
      setNoJual(response.data.noJual);
      setNamaRegister(response.data.idJual.namaRegister);
      setAlmRegister(response.data.idJual.almRegister);
      setTlpRegister(response.data.idJual.tlpRegister);
      setTglAngDate(response.data.idJual.tglAng);
      setTglAng(formatDate(response.data.idJual.tglAng));
      setTenor(response.data.idJual.tenor);
      setBulan(response.data.idJual.tenor - response.data.idJual.sisaBulan);
      setSisaBulan(response.data.idJual.sisaBulan);
      setAngsuranKe(response.data.angsuranKe);
      setTglSp(formatDate(response.data.tglSp));
      setSpKe(response.data.spKe);

      if (response.data.kodeSurveyor) {
        setKodeSurveyor(
          `${response.data.kodeSurveyor.kodeSurveyor} - ${response.data.kodeSurveyor.namaSurveyor}`
        );
      }
      setTipe(response.data.idJual.tipe);
      setNoRangka(response.data.idJual.noRangka);
      setTahun(response.data.idJual.tahun);
      setNamaWarna(response.data.idJual.namaWarna);
      setNopol(response.data.idJual.nopol);
    }
    setLoading(false);
  };

  const deleteSp1 = async () => {
    try {
      setLoading(true);
      // Find Jual
      const findJualByNoJual = await axios.post(`${tempUrl}/jualByNoJual`, {
        noJual,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      await axios.post(`${tempUrl}/deleteSp1AndUpdateJual`, {
        jualData: {
          idJual: findJualByNoJual.data._id,
          spKe: findJualByNoJual.data.spKe - 1,
          tglSpTerakhir: "",
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        angsuranData: {
          idSp: id,
          noJual,
          idJual: findJualByNoJual.data._id,
          spKe: findJualByNoJual.data.spKe - 1,
          tglSpTerakhir: "",
          angsuranKe,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        spData: {
          idSp: id,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setNoJual("");
      setNamaRegister("");
      setAlmRegister("");
      setTglAng("");
      setTenor("");
      setBulan("");
      setSisaBulan("");
      setAngsuranKe("");
      setTglSp("");
      setSpKe("");

      setKodeSurveyor("");
      setTipe("");
      setNoRangka("");
      setTahun("");
      setNamaWarna("");
      setNopol("");
      setLoading(false);
      navigate("/sp1");
      getSp1sData();
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPdf = async () => {
    let tempY = 52;
    let tempDateName;
    let date = new Date();
    var tempTglAng = new Date(tglAngDate);
    switch (tempTglAng.getMonth() + 1) {
      case 1:
        tempDateName = "JANUARI";
        break;
      case 2:
        tempDateName = "FEBRUARI";
        break;
      case 3:
        tempDateName = "MARET";
        break;
      case 4:
        tempDateName = "APRIL";
        break;
      case 5:
        tempDateName = "MEI";
        break;
      case 6:
        tempDateName = "JUNI";
        break;
      case 7:
        tempDateName = "JULI";
        break;
      case 8:
        tempDateName = "AGUSTUS";
        break;
      case 9:
        tempDateName = "SEPTEMBER";
        break;
      case 10:
        tempDateName = "OKTOBER";
        break;
      case 11:
        tempDateName = "NOVEMBER";
        break;
      case 12:
        tempDateName = "DESEMBER";
        break;
      default:
        break;
    }

    var tempDate;
    const response = await axios.post(`${tempUrl}/angsuransChildTunggak`, {
      tglInput: date,
      noJual,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    const doc = new jsPDF();
    doc.setFontSize(9);
    doc.text(
      `${setting.lokasiSP} / ${tempTglAng.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })} ${tempDateName} ${tempTglAng.getFullYear()}`,
      15,
      tempY
    );
    tempY += 6;
    doc.text(`Kepada Yth.`, 15, tempY);
    doc.setFont(undefined, "bold");
    doc.text(`: Bpk / Ibu ${namaRegister}`, 44, tempY);
    tempY += 6;
    doc.text(`${almRegister}`, 46, tempY);
    doc.setFont(undefined, "normal");
    tempY += 11;
    doc.text(`Perihal`, 15, tempY);
    doc.text(`: SURAT PEMBERITAHUAN TUNGGAKAN ${spKe}`, 44, tempY);
    tempY += 9;
    doc.text(`Dengan Hormat,`, 15, tempY);
    tempY += 6;
    doc.text(
      `Untuk menghindari biaya penarikan sepeda motor No. Polisi ${nopol} dengan ini kami`,
      15,
      tempY
    );
    tempY += 6;
    doc.text(
      `sampaikan bahwa sepeda motor Bapak/Ibu telah menunggak ${response.data.length} bulan, dengan rincian sbb :`,
      15,
      tempY
    );
    tempY += 6;
    for (let i = 0; i < response.data.length; i++) {
      tempDate = new Date(response.data[i].tglJatuhTempo);

      switch (tempDate.getMonth() + 1) {
        case 1:
          tempDateName = "JANUARI";
          break;
        case 2:
          tempDateName = "FEBRUARI";
          break;
        case 3:
          tempDateName = "MARET";
          break;
        case 4:
          tempDateName = "APRIL";
          break;
        case 5:
          tempDateName = "MEI";
          break;
        case 6:
          tempDateName = "JUNI";
          break;
        case 7:
          tempDateName = "JULI";
          break;
        case 8:
          tempDateName = "AGUSTUS";
          break;
        case 9:
          tempDateName = "SEPTEMBER";
          break;
        case 10:
          tempDateName = "OKTOBER";
          break;
        case 11:
          tempDateName = "NOVEMBER";
          break;
        case 12:
          tempDateName = "DESEMBER";
          break;
        default:
          break;
      }

      var dt = new Date(response.data[i].tglJatuhTempo);
      let day = dt.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      let month = (dt.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      let sum = day + "-" + month + "-" + dt.getFullYear();

      doc.text(`${i + 1}.  Angsuran ke ${i + 1}`, 30, tempY);
      doc.text(`Bulan ${tempDateName} ${tempDate.getFullYear()}`, 70, tempY);
      doc.text(`( ${sum} )`, 120, tempY);
      doc.text(`Rp.`, 150, tempY);
      doc.text(
        `${response.data[i].angPerBulan.toLocaleString("en-US")}`,
        180,
        tempY,
        {
          align: "right",
        }
      );
      tempY += 6;
    }
    tempY += 1;
    doc.text(`Jumlah di atas belum termasuk denda tunggakan.`, 15, tempY);
    tempY += 10;
    doc.text(
      `Demikian Surat Pemberitahuan ini kami sampaikan kepada Bapak/Ibu, dan kami menunggu`,
      15,
      tempY
    );
    tempY += 6;
    doc.text(
      `3 hari dari surat ini diterbitkan. Apabila dalam 3 hari Bapak/Ibu tidak datang ke kantor,`,
      15,
      tempY
    );
    tempY += 6;
    doc.text(
      `maka kami akan mengambil kembali sepeda motor tersebut.`,
      15,
      tempY
    );
    tempY += 8;
    doc.text(`Atas kerjasamanya kami ucapkan terimakasih.`, 15, tempY);
    tempY += 6;
    doc.text(`Hormat kami,`, 15, tempY);
    tempY += 24;
    doc.text(`${setting.namaPemilik}`, 15, tempY);
    doc.setFontSize(12);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        SP1
      </Typography>
      {isSpsExist && (
        <Box sx={downloadButtons}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={() => downloadPdf()}>
              CETAK
            </Button>
          </ButtonGroup>
        </Box>
      )}
      <Box sx={buttonModifierContainer}>
        <ButtonGroup variant="contained">
          <Button
            color="success"
            sx={{ bgcolor: "success.light", textTransform: "none" }}
            startIcon={<AddCircleOutlineIcon />}
            size="small"
            onClick={() => {
              navigate(`/sp1/tambahSp1`);
            }}
          >
            Tambah Manual
          </Button>
          <Button
            color="secondary"
            sx={{ bgcolor: "secondary.light", textTransform: "none" }}
            startIcon={<AddCircleOutlineIcon />}
            size="small"
            onClick={() => {
              navigate(`/sp1/tambahSp1Otomatis`);
            }}
          >
            Tambah Otomatis
          </Button>
          {noJual && (
            <Button
              color="error"
              startIcon={<DeleteOutlineIcon />}
              sx={{ textTransform: "none" }}
              onClick={deleteSp1}
            >
              Hapus
            </Button>
          )}
        </ButtonGroup>
      </Box>
      <Divider sx={dividerStyle} />
      {isSpsExist && (
        <>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Angsuran :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglAng} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Angs. / Bulan / Sisa :
                    </Form.Label>
                    <Col sm={3}>
                      <Form.Control value={tenor} disabled />
                    </Col>
                    <Col sm={2}>
                      <Form.Control value={bulan} disabled />
                    </Col>
                    <Col sm={3}>
                      <Form.Control value={sisaBulan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Angsuran Ke :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={angsuranKe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Sp / Ke :
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control value={tglSp} disabled />
                    </Col>
                    <Col sm={4}>
                      <Form.Control value={spKe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Surveyor :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeSurveyor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tipe :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tipe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Rangka :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRangka} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tahun / Warna :
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control value={tahun} disabled />
                    </Col>
                    <Col sm={4}>
                      <Form.Control value={namaWarna} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
      <Box sx={searchBarContainer}>
        <SearchBar setSearchTerm={setSearchTerm} />
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disableElevation
        >
          Cari
        </Button>
      </Box>
      {spsData && (
        <Box sx={tableContainer}>
          <ShowTableSp1 currentPosts={currentPosts} searchTerm={searchTerm} />
        </Box>
      )}
      <Box sx={tableContainer}>
        <Pagination
          count={count}
          page={page}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilSp1;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
