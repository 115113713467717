import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahPemutihan = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  var date = new Date();

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const [noBukti, setNoBukti] = useState(""); // I
  const [inputTglPengajuan, setInputTglPengajuan] = useState(date); // I
  const [kodeCOA, setKodeCOA] = useState("");
  const [openCOA, setOpenCOA] = useState(false);
  const [searchTermCOA, setSearchTermCOA] = useState("");
  const [COAsData, setCOAsData] = useState([]);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClickOpenCOA = () => {
    setOpenCOA(true);
  };

  const handleCloseCOA = () => {
    setOpenCOA(false);
  };

  const tempPostsCOA = COAsData.filter((val) => {
    if (searchTermCOA === "") {
      return val;
    } else if (
      val.kodeCOA.toUpperCase().includes(searchTermCOA.toUpperCase()) ||
      val.namaCOA.toUpperCase().includes(searchTermCOA.toUpperCase())
    ) {
      return val;
    }
  });

  useEffect(() => {
    getCOAsData();
    getPemutihanNextKode(inputTglPengajuan);
  }, []);

  const getCOAsData = async () => {
    setLoading(true);
    const allCOAs = await axios.post(`${tempUrl}/COAs`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setCOAsData(allCOAs.data);
    setLoading(false);
  };

  const getPemutihanNextKode = async (date) => {
    const nextKodePemutihan = await axios.post(
      `${tempUrl}/pemutihansNextKode`,
      {
        tglPengajuan: date,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setNoBukti(nextKodePemutihan.data);
  };

  const savePemutihan = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    let tglPengajuan =
      inputTglPengajuan.getFullYear() +
      "-" +
      (inputTglPengajuan.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      inputTglPengajuan.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });

    let isFailedValidation =
      noBukti.length === 0 ||
      inputTglPengajuan === null ||
      kodeCOA.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        let tempCOA = await axios.post(`${tempUrl}/COAByKode`, {
          kodeCOA,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        // Save Pemutihan
        let savedPemutihan = await axios.post(`${tempUrl}/savePemutihan`, {
          noBukti,
          tglPengajuan,
          COA: tempCOA.data._id,
          status: "BELUMAPPROVE",
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate("/daftarPemutihan");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Entry Pemutihan
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={savePemutihan}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Bukti <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noBukti} disabled readOnly />
                      <Form.Control.Feedback type="invalid">
                        No. Bukti harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Pengajuan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={inputTglPengajuan}
                        onChange={(e) => {
                          setInputTglPengajuan(e);
                          getPemutihanNextKode(e);
                        }}
                        customInput={
                          <TextField
                            error={error && inputTglPengajuan === null && true}
                            helperText={
                              error &&
                              inputTglPengajuan === null &&
                              "Tgl. Pengajuan harus diisi!"
                            }
                            sx={{ width: "100%" }}
                            size="small"
                          />
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      COA <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={kodeCOA}
                        required
                        placeholder="Pilih..."
                        onClick={() => handleClickOpenCOA()}
                      />
                      <Form.Control.Feedback type="invalid">
                        COA harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}></Box>
          </Box>

          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/daftarPemutihan`)}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Dialog
        open={openCOA}
        onClose={handleCloseCOA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data COA`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermCOA} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode COA
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsCOA
                    .filter((val) => {
                      if (searchTermCOA === "") {
                        return val;
                      } else if (
                        val.kodeCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase()) ||
                        val.namaCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setKodeCOA(user.kodeCOA);
                          handleCloseCOA();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.kodeCOA}
                        </TableCell>
                        <TableCell>{user.namaCOA}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default TambahPemutihan;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
